import React from "react";
import { createUseStyles } from "react-jss";

import UtilityRow, {
    CaptureWaveformBigButtonsProps,
} from "features/Capture/Waveform/Big/UtilityRow";
import CaptureWaveform from "features/Capture/Waveform/CaptureWaveform";

const useStyles = createUseStyles({
    captureWaveformBigContainer: {
        position: "relative",
        width: "100%",
        display: "flex",
    },
});

export type CaptureWaveformBigProps = CaptureWaveformBigButtonsProps;
const CaptureWaveformBig = ({ capturedAudioKey }: CaptureWaveformBigProps) => {
    const styles = useStyles();
    return (
        <div className={styles.captureWaveformBigContainer}>
            <UtilityRow capturedAudioKey={capturedAudioKey} />
            <CaptureWaveform
                waveformHeight={67}
                containerHeight={168}
                centerOffset="22px"
            />
        </div>
    );
};

export default CaptureWaveformBig;
