/* eslint-disable no-magic-numbers */

import { withOpacity } from "shared/utils/colorUtil";

export const gray_500 = "rgb(254,254,254)";
export const gray_200 = "rgb(163,163,163)";
export const gray0 = "rgb(1,1,1)";
export const gray8 = "rgb(19,20,21)";
export const gray10 = "rgb(25,25,27)";
export const gray12 = "rgb(29,29,32)";
export const gray15 = "rgb(37, 37, 41)";
export const gray25 = "rgb(61, 61, 67)";
export const gray30 = "rgb(73, 74, 81)";
export const gray45 = "rgb(109,110,120)";
export const gray50 = "rgb(122,123,134)";
export const gray65 = "rgb(161,162,170)";
export const gray100 = "rgb(252,252,252)";
export const gray300 = "rgb(64,64,64)";
export const black0 = "rgb(0,0,0)";
export const black0Tint10 = withOpacity(black0, 0.1);
export const black0Tint25 = withOpacity(black0, 0.25);
export const black0Tint45 = withOpacity(black0, 0.45);
export const white = "rgb(255,255,255)";
export const whiteTint10 = withOpacity(white, 0.1);
export const whiteTint05 = withOpacity(white, 0.05);
export const whiteTint25 = withOpacity(white, 0.25);
export const whiteTint35 = withOpacity(white, 0.35);
export const whiteTint65 = withOpacity(white, 0.65);
export const black = "rgb(0,0,0)";
export const blackTint02 = withOpacity(black, 0.02);
export const blackTint25 = withOpacity(black, 0.25);
export const cyan = "rgb(10,235,194)";
export const cyanTint05 = withOpacity(cyan, 0.05);
export const cyanTint08 = withOpacity(cyan, 0.08);
export const cyanTint12 = withOpacity(cyan, 0.12);
export const cyanTint15 = withOpacity(cyan, 0.15);
export const cyanTint20 = withOpacity(cyan, 0.2);
export const cyanTint25 = withOpacity(cyan, 0.25);
export const cyanTint33 = withOpacity(cyan, 0.33);
export const cyanTint80 = withOpacity(cyan, 0.8);
