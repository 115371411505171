import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { revertToPreviousKey, updatePreviewKey } from "../thunks";
import MusicalKeySelector from "features/Preview/MusicalKeySelector/MusicalKeySelector";
import { previewSlice } from "features/Preview/previewSlice";
import { MusicalKey } from "Protocol/Generated/Messages";
import ToolTip from "shared/components/Tooltip/Tooltip";
import { useHydraDispatch } from "state/hooks";

const useStyles = createUseStyles({
    previewMusicalKeySelector: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
});

type PreviewMusicalKeySelectorProps = {
    readonly width: string;
    readonly isBpmSelectorOpen: boolean;
    readonly onOpen: () => void;
};

const PreviewMusicalKeySelector = ({
    width,
    isBpmSelectorOpen,
    onOpen,
}: PreviewMusicalKeySelectorProps) => {
    const dispatch = useHydraDispatch();
    const activeKey = useSelector(previewSlice.selectors.getMusicalKey);
    const showTooltip = useSelector(
        previewSlice.selectors.getShowMusicalKeyAutoUpdatedModal,
    );

    const onKeyUpdated = (musicalKey: MusicalKey) => {
        void dispatch(updatePreviewKey({ newKey: musicalKey }));
    };

    const onToolTipConfirm = () => {
        dispatch(previewSlice.actions.closeMusicalKeyAutoUpdatedModal());
    };

    const onToolTipBack = () => {
        void dispatch(revertToPreviousKey());
    };

    const styles = useStyles();
    return (
        <div className={styles.previewMusicalKeySelector}>
            {showTooltip ? (
                <ToolTip
                    backButtonLabel="Undo"
                    title="Key Updated"
                    body="We’ve updated the global key based on the key we detected from your audio"
                    confirmButtonLabel="Got it"
                    onBackClick={onToolTipBack}
                    onConfirmClick={onToolTipConfirm}
                />
            ) : null}
            <MusicalKeySelector
                activeKey={activeKey}
                onKeyUpdated={onKeyUpdated}
                noKeyButtonMainText="Original Key"
                noKeyButtonSecondaryText={"Playback in sample's original key"}
                noKeyText="Original"
                width={width}
                forceClose={isBpmSelectorOpen}
                onOpen={onOpen}
            />
        </div>
    );
};

export default PreviewMusicalKeySelector;
