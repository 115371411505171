import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { mainWindowSlice } from "./mainWindowSlice";
import LoginPage from "features/LoginPage/LoginPage";
import { loginPageSlice } from "features/LoginPage/loginPageSlice";
import SearchPage from "features/SearchPage/SearchPage";
import { APP_MARGIN_PX, SIDEPANEL_WIDTH_PX } from "shared/constants/layout";

const useStyles = createUseStyles({
    container: {
        height: "100%",
        display: "inline-flex",
        width: `calc(100% - ${SIDEPANEL_WIDTH_PX} - ${APP_MARGIN_PX})`,
        borderRadius: "12px",
        overflow: "hidden",
    },
});

type MainWindowProps = {
    readonly isLoading: boolean;
};

const MainWindow = ({ isLoading }: MainWindowProps) => {
    const styles = useStyles();
    const authToken = useSelector(loginPageSlice.selectors.getAuthToken);
    const currentRoute = useSelector(mainWindowSlice.selectors.getCurrentRoute);

    const routeToUse = authToken ? currentRoute : "login";
    const currentElement = useMemo(() => {
        switch (routeToUse) {
            case "login":
                return <LoginPage />;
            case "search":
                return <SearchPage isLoading={isLoading} />;
            default:
                return null;
        }
    }, [routeToUse, isLoading]);

    return <div className={styles.container}>{currentElement}</div>;
};

export default MainWindow;
