import React from "react";

import { captureSlice } from "features/Capture/captureSlice";
import { useUtilityButtonStyles } from "features/Capture/Waveform/Big/hooks";
import Button from "shared/components/Buttons/Button";
import Trash from "shared/icons/Trash.svg";
import { useHydraDispatch } from "state/hooks";

const TrashButton = () => {
    const styles = useUtilityButtonStyles();
    const dispatch = useHydraDispatch();
    const onTrashClick = () => {
        dispatch(captureSlice.actions.clearCapture());
    };

    return (
        <Button onClick={onTrashClick} additionalClasses={styles.utilityButton}>
            <Trash />
        </Button>
    );
};

export default TrashButton;
