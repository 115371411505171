import React, { ChangeEvent, useCallback } from "react";

import { loginPageSlice } from "../loginPageSlice";
import { useHydraDispatch } from "state/hooks";

type UserNameInputProps = {
    readonly value: string;
};

const UsernameInput = ({ value }: UserNameInputProps) => {
    const dispatch = useHydraDispatch();
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) =>
            dispatch(
                loginPageSlice.actions.updateUsername(e.currentTarget.value),
            ),
        [],
    );
    return (
        <div>
            <p>Username</p>
            <input
                data-testid="username-input"
                onChange={handleChange}
                value={value}
                type="text"
            />
        </div>
    );
};

export default UsernameInput;
