import React from "react";

import KeyConfirmationInner from "./KeyConfirmationInner";
import CaptureConfirmationBottom from "features/Capture/Confirmation/KeyConfirmationBottom";
import { MusicalKey } from "Protocol/Generated/Messages";

type KeyConfirmationProps = {
    readonly captureKey: MusicalKey;
};

const KeyConfirmation = ({ captureKey }: KeyConfirmationProps) => (
    <>
        <KeyConfirmationInner captureKey={captureKey} />
        <CaptureConfirmationBottom />
    </>
);

export default KeyConfirmation;
