import React from "react";
import { createUseStyles } from "react-jss";

import { TextToggleButtonState } from "./TextToggleButtonState";
import TextToggleButton from "shared/components/Buttons/TextToggle/TextToggleButton";
import { gray10 } from "shared/constants/color";

const useStyles = createUseStyles({
    textToggleBackground: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        padding: "2px",
        backgroundColor: gray10,
        borderRadius: "8px",
    },
});

type TextToggleProps = {
    readonly onToggle: (state: TextToggleButtonState) => void;
    readonly state: TextToggleButtonState;
    readonly leftText: string;
    readonly rightText: string;
};

const TextToggle = ({
    onToggle,
    state,
    leftText,
    rightText,
}: TextToggleProps) => {
    const styles = useStyles();

    const handleClick = (newState: TextToggleButtonState) => {
        onToggle(newState);
    };

    const makeTextToggleButton = (
        text: string,
        toggleState: TextToggleButtonState,
    ) => {
        const isActive = state === toggleState;
        return (
            <TextToggleButton
                isActive={isActive}
                onClick={handleClick}
                text={text}
                state={toggleState}
            />
        );
    };

    return (
        <div className={styles.textToggleBackground}>
            {makeTextToggleButton(leftText, TextToggleButtonState.Left)}
            {makeTextToggleButton(rightText, TextToggleButtonState.Right)}
        </div>
    );
};

export default TextToggle;
