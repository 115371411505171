import React from "react";
import { createUseStyles } from "react-jss";

import Button, { ButtonProps } from "./Button";
import { cyan } from "shared/constants/color";

type CTAButtonProps = Pick<
    ButtonProps,
    | "children"
    | "onClick"
    | "additionalClasses"
    | "onMouseDown"
    | "onMouseEnter"
    | "onMouseLeave"
    | "enabled"
>;

const useStyles = createUseStyles({
    ctaButtonContainer: {
        backgroundColor: cyan,
        minWidth: "60px",
    },
});
const CTAButton = ({
    children,
    additionalClasses,
    onClick,
    onMouseDown,
    onMouseLeave,
    onMouseEnter,
    enabled,
}: CTAButtonProps) => {
    const styles = useStyles();
    const ctaButtonClasses = `${styles.ctaButtonContainer}${additionalClasses ? ` ${additionalClasses}` : ""}`;
    return (
        <Button
            additionalClasses={ctaButtonClasses}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={onMouseDown}
            enabled={enabled}
        >
            {children}
        </Button>
    );
};

export default CTAButton;
