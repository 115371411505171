import React, {
    MutableRefObject,
    UIEventHandler,
    useCallback,
    useEffect,
    useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { searchSlice } from "./searchPageSlice";
import { persistedStateSlice } from "features/PersistedState/persistedStateSlice";
import { debounce } from "shared/utils/interactionUtil";
import { useHydraDispatch } from "state/hooks";

const SEARCH_INPUT_CHANGE_DEBOUNCE_INTERVAL_IN_MS = 10;
export const useSearchInputChangeHandler = () => {
    const dispatch = useDispatch();
    const onInputChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            dispatch(searchSlice.actions.updateSearchQuery(e.target.value));
        },
        [],
    );

    // Using useMemo here so debounce works correctly
    return useMemo(
        () =>
            debounce((e: React.ChangeEvent<HTMLTextAreaElement>) => {
                onInputChange(e);
            }, SEARCH_INPUT_CHANGE_DEBOUNCE_INTERVAL_IN_MS),
        [],
    );
};

const SCROLL_DEBOUNCE_INTERVAL_IN_MS = 100;
export const useScrollPositionTracker = (
    ref: MutableRefObject<HTMLDivElement | null>,
) => {
    const dispatch = useHydraDispatch();

    const onScroll: UIEventHandler<HTMLDivElement> = useCallback(() => {
        const currentRef = ref.current;
        if (currentRef === null) {
            return;
        }
        const { scrollTop } = currentRef;
        dispatch(searchSlice.actions.updateScrollPosition(scrollTop));
    }, []);

    const debouncedOnScroll = debounce(
        onScroll,
        SCROLL_DEBOUNCE_INTERVAL_IN_MS,
    );

    return { onScroll: debouncedOnScroll };
};

export const useScrollFromPersistedState = (
    ref: MutableRefObject<HTMLDivElement | null>,
) => {
    const scrollPosition = useSelector(searchSlice.selectors.getScrollPosition);
    const hasReceivedPersistedState = useSelector(
        persistedStateSlice.selectors.getPersistedStateReceived,
    );
    useEffect(() => {
        if (hasReceivedPersistedState && ref.current !== null) {
            ref.current.scrollTo({ top: scrollPosition });
        }
    }, [hasReceivedPersistedState, ref]);
};
