import React from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";

import { processingStoppedSlice } from "./processingStoppedSlice";

const useStyles = createUseStyles({
    container: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000, // Adjust this value as needed to ensure the warning is on top of everything else
    },
    warning: {
        width: "400px",
        height: "100px",
        background: "#000000",
        border: "1px solid #ffffff",
        padding: "10px",
        borderRadius: "5px",
        color: "#ffffff",
        fontSize: "16px",
    },
});

// Create a popup that warns the user that processing has stopped that they can easily dismiss
const ProcessingStoppedWarning = () => {
    const dispatch = useDispatch();
    const processingStopped = useSelector(
        processingStoppedSlice.selectors.getIsVisible,
    );
    const styles = useStyles();

    if (!processingStopped) {
        return null;
    }

    // Create a dialog that warns the user that processing has stopped
    return (
        <div className={styles.container}>
            <div className={styles.warning}>
                <p>
                    The host has stopped audio processing on the track or bus
                    the plugin is on :(
                </p>
                <button
                    type="button"
                    onClick={() =>
                        dispatch(processingStoppedSlice.actions.warningClosed())
                    }
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ProcessingStoppedWarning;
