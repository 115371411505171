import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { searchCharacterLimit } from "../constants";
import { searchSlice } from "../searchPageSlice";
import { labelStyle } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    searchFooter: {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        alignItems: "center",
    },
    labelStyle: {
        ...labelStyle,
    },
});

const SearchFooter = () => {
    const styles = useStyles();
    const hasReachedCharacterLimit = useSelector(
        searchSlice.selectors.getHasReachedCharacterLimit,
    );
    const searchLength = useSelector(searchSlice.selectors.getSearchLength);

    return (
        <div className={styles.searchFooter}>
            <p className={styles.labelStyle}>
                {`${searchLength}/${searchCharacterLimit}`}
            </p>
            {hasReachedCharacterLimit ? (
                <p className={styles.labelStyle}>Character limit reached</p>
            ) : null}
        </div>
    );
};

export default SearchFooter;
