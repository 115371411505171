import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { previewSlice } from "features/Preview/previewSlice";
import SearchBar from "features/SearchPage/SearchInput/SearchBar/SearchBar";
import SearchFooter from "features/SearchPage/SearchInput/SearchFooter";
import { sidepanelModuleStyles } from "shared/styles/sidepanel.styles";
import { labelStyle } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    searchContainer: {
        ...sidepanelModuleStyles,
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    label: {
        ...labelStyle,
    },
});
const SearchInput = () => {
    const isLocked = useSelector(previewSlice.selectors.getIsSessionLocked);
    const styles = useStyles({ isLocked });

    return (
        <div className={styles.searchContainer}>
            <p className={styles.label}>Text Input*</p>
            <SearchBar />
            <SearchFooter />
        </div>
    );
};

export default SearchInput;
