import React from "react";
import { createUseStyles } from "react-jss";

import CaptureButton from "features/Capture/CaptureButton";
import { labelStyle } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    inner: {
        display: "flex",
        flex: "1 0 0",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "8px",
        alignSelf: "center",
    },
    title: {
        paddingTop: "42px",
        fontSize: "15px",
        lineHeight: "24px",
    },
    label: labelStyle,
});

const CaptureIdle = () => {
    const styles = useStyles();
    return (
        <>
            <p className={styles.label}>Audio Input</p>
            <div className={styles.inner}>
                <p className={styles.title}>Listen to my track</p>
                <CaptureButton />
            </div>
        </>
    );
};

export default CaptureIdle;
