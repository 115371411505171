import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { previewSlice } from "features/Preview/previewSlice";
import { bpmLockSwitched } from "features/Preview/thunks";
import TwoTextLineButton from "shared/components/Buttons/TwoTextLineButton";
import Dropdown from "shared/components/Dropdown/Dropdown";
import { toStringRemovingTrailingZeroes } from "shared/utils/numberUtil";
import { useHydraDispatch } from "state/hooks";

const bpmNumDecimalPlaces = 2;

const useStyles = createUseStyles({
    bpmDisplayContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "16px",
        gap: "16px",
    },
});

type BpmDisplayProps = {
    readonly width: string;
    readonly isKeySelectorOpen: boolean;
    readonly onOpen: () => void;
};

const BpmDisplay = ({ width, isKeySelectorOpen, onOpen }: BpmDisplayProps) => {
    const bpm = useSelector(previewSlice.selectors.getBpm);
    const displayBpm = toStringRemovingTrailingZeroes(bpm, bpmNumDecimalPlaces);
    const bpmLock = useSelector(previewSlice.selectors.getBpmLock);
    const dispatch = useHydraDispatch();
    const onDawBpmClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        void dispatch(bpmLockSwitched(true));
    };

    const onOriginalBpmClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        void dispatch(bpmLockSwitched(false));
    };
    const styles = useStyles();
    return (
        <Dropdown
            title={bpmLock ? displayBpm : "Original"}
            label="BPM"
            width={width}
            forceClose={isKeySelectorOpen}
            onOpen={onOpen}
        >
            <div className={styles.bpmDisplayContainer}>
                <TwoTextLineButton
                    isActive={bpmLock}
                    mainText={`${displayBpm} BPM`}
                    secondaryText="Set by your Daw"
                    onClick={onDawBpmClick}
                />
                <TwoTextLineButton
                    isActive={!bpmLock}
                    mainText="Original BPM"
                    secondaryText="Playback in sample’s original BPM"
                    onClick={onOriginalBpmClick}
                />
            </div>
        </Dropdown>
    );
};
export default BpmDisplay;
