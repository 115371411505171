import { MusicalRootNote, MusicalScale } from "Protocol/Generated/Messages";

export const musicalKeySharpFlatSymbolMap: { [key: string]: string } = {
    Sharp: "#",
    Flat: "b",
};

export const musicalKeySharpFlatSymbolReplacementRegex = new RegExp(
    Object.keys(musicalKeySharpFlatSymbolMap).join("|"),
    "giu",
);

export const musicalScaleAbbreviationMap: { [key: string]: string } = {
    Major: "maj",
    Minor: "min",
};

export const musicalScaleAbbreviationReplacementRegex = new RegExp(
    Object.keys(musicalScaleAbbreviationMap).join("|"),
    "giu",
);

export type RelativePair = {
    [MusicalScale.Major]: MusicalRootNote;
    [MusicalScale.Minor]: MusicalRootNote;
};

// Create typescript record of major keys to minor keys
export const majorToRelativeMinor: Record<MusicalRootNote, MusicalRootNote> = {
    [MusicalRootNote.C]: MusicalRootNote.A,
    [MusicalRootNote.DFlat]: MusicalRootNote.BFlat,
    [MusicalRootNote.D]: MusicalRootNote.B,
    [MusicalRootNote.EFlat]: MusicalRootNote.C,
    [MusicalRootNote.E]: MusicalRootNote.DFlat,
    [MusicalRootNote.F]: MusicalRootNote.D,
    [MusicalRootNote.GFlat]: MusicalRootNote.EFlat,
    [MusicalRootNote.G]: MusicalRootNote.E,
    [MusicalRootNote.AFlat]: MusicalRootNote.F,
    [MusicalRootNote.A]: MusicalRootNote.GFlat,
    [MusicalRootNote.BFlat]: MusicalRootNote.G,
    [MusicalRootNote.B]: MusicalRootNote.AFlat,
    [MusicalRootNote.None]: MusicalRootNote.None,
};

export const minorToRelativeMajor = Object.fromEntries(
    Object.entries(majorToRelativeMinor).map(([major, minor]) => [
        minor,
        major,
    ]),
) as Record<MusicalRootNote, MusicalRootNote>;
