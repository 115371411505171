import { JssStyle } from "jss";

import { gray12 } from "shared/constants/color";

export const SIDE_PANEL_MODULE_BORDER_RADIUS_PX = "12px";
export const sidepanelModuleStyles: JssStyle = {
    borderRadius: SIDE_PANEL_MODULE_BORDER_RADIUS_PX,
    backgroundColor: gray12,
    marginBottom: "12px",
    padding: "16px",
    height: "203px",
};
