import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { gainSlice, sendGainChangedMessage } from "./gainSlice";
import { gray300, gray_200 } from "shared/constants/color";
import Speaker from "shared/icons/Speaker.svg";
import { useHydraDispatch } from "state/hooks";

const TRACK_HEIGHT_PX = "2px";
const THUMB_SIZE_PX = "9px";
const useStyles = createUseStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    slider: {
        "-webkit-appearance": "none",
        background: "transparent",
        cursor: "pointer",
        width: "55px",

        "&::-webkit-slider-runnable-track": {
            height: TRACK_HEIGHT_PX,
            backgroundColor: gray300,
        },
        "&::-webkit-slider-thumb": {
            appearance: "none",
            "-webkit-appearance": "none",
            width: THUMB_SIZE_PX,
            height: THUMB_SIZE_PX,
            borderRadius: "100%",
            marginTop: `calc((${TRACK_HEIGHT_PX} / 2) - (${THUMB_SIZE_PX} / 2))`,
            backgroundColor: gray_200,
        },
    },
});

const GainSlider = () => {
    const gain = useSelector(gainSlice.selectors.getGain);
    const dispatch = useHydraDispatch();
    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newGain = Number(e.currentTarget.value);
        void dispatch(sendGainChangedMessage(newGain));
    };
    const styles = useStyles();

    return (
        <div className={styles.container}>
            <Speaker />
            <input
                className={styles.slider}
                type="range"
                min="0.0"
                max="1.0"
                step="0.01"
                value={gain}
                onChange={onInputChange}
            />
        </div>
    );
};

export default GainSlider;
