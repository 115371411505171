import { createSlice } from "@reduxjs/toolkit";

import { gain } from "Protocol/Generated/MessageHelpers";
import { MessageType } from "Protocol/Generated/Messages";
import { createHydraThunk } from "state/typeUtil";

export type GainState = number;
export const sendGainChangedMessage = createHydraThunk<number>(
    "gain/sendGainChanged",
    (value, { extra: { messageService } }) => {
        messageService.sendMessage({ type: MessageType.Gain, value });
    },
);

export const gainSlice = createSlice({
    name: "gain",
    initialState: 1.0,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(gain, (_, action) => action.payload.value);
    },
    selectors: {
        getGain: (state) => state,
    },
});
