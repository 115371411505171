import React from "react";
import { createUseStyles } from "react-jss";

import TooltipBg from "shared/components/Tooltip/TooltipBg";
import TooltipInner, {
    TooltipInnerProps,
} from "shared/components/Tooltip/TooltipInner";
import { black0Tint25 } from "shared/constants/color";

const tooltipDistanceAbove = "12px";

const tooltipWidth = 250;
const toolTipHeight = 120;

const useStyles = createUseStyles({
    tooltipContainer: {
        position: "absolute",
        transform: `translate(0%, calc(-100% - ${tooltipDistanceAbove}))`,
        boxShadow: `0px 2px 16px 0px ${black0Tint25}`,
        zIndex: 100,
        width: `${tooltipWidth}px`,
        height: `${toolTipHeight}px`,
    },
});

type ToolTipProps = TooltipInnerProps;

const ToolTip = ({
    title,
    confirmButtonLabel,
    backButtonLabel,
    body,
    onBackClick,
    onConfirmClick,
}: ToolTipProps) => {
    const styles = useStyles();

    return (
        <div className={styles.tooltipContainer}>
            <TooltipBg width={tooltipWidth} height={toolTipHeight} />
            <TooltipInner
                title={title}
                backButtonLabel={backButtonLabel}
                confirmButtonLabel={confirmButtonLabel}
                body={body}
                onBackClick={onBackClick}
                onConfirmClick={onConfirmClick}
            />
        </div>
    );
};

export default ToolTip;
