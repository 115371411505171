import React from "react";
import { createUseStyles } from "react-jss";

import { black } from "shared/constants/color";

type ArrowDirection = "up" | "down" | "left" | "right";

type ArrowWithTailProps = {
    readonly width?: number;
    readonly strokeColor?: string;
    readonly direction?: ArrowDirection;
};

/* eslint-disable no-magic-numbers */
const getRotationForArrowDirection = (direction: ArrowDirection) => {
    switch (direction) {
        case "left":
            return 270;
        case "right":
            return 90;
        case "down":
            return 180;
        default:
            return 0;
    }
};
/* eslint-enable no-magic-numbers */
const useStyles = createUseStyles({
    arrowWithTailContainer: ({
        direction = "right",
    }: Pick<ArrowWithTailProps, "direction">) => ({
        transform: `rotate(${getRotationForArrowDirection(direction)}deg)`,
    }),
});

const ORIGINAL_WIDTH = 10;
const ORIGINAL_HEIGHT = 11;
const HEIGHT_TO_WIDTH_RATIO = ORIGINAL_HEIGHT / ORIGINAL_HEIGHT;
const ArrowWithTail = ({
    width = ORIGINAL_WIDTH,
    strokeColor = black,
    direction = "right",
}: ArrowWithTailProps) => {
    const styles = useStyles({ direction });
    return (
        <svg
            className={styles.arrowWithTailContainer}
            width={width}
            height={width * HEIGHT_TO_WIDTH_RATIO}
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.32353 6.20979L4.91176 1.50391L0.5 6.20979"
                stroke={strokeColor}
                strokeWidth="1.25"
            />
            <path
                d="M4.91211 10.4961L4.91211 1.78609"
                stroke={strokeColor}
                strokeWidth="1.25"
            />
        </svg>
    );
};

export default ArrowWithTail;
