import React from "react";
import { useSelector } from "react-redux";

import {
    handleCapturePlayButtonPressed,
    handleCaptureStopButtonPressed,
} from "./thunks";
import { captureSlice } from "features/Capture/captureSlice";
import PlayPauseButton from "shared/components/PlayPauseButton/PlayPauseButton";
import { useHydraDispatch } from "state/hooks";

const CapturePlaybackButton = ({ bgBlur }: { readonly bgBlur: boolean }) => {
    const dispatch = useHydraDispatch();
    const isPlayingPreview = useSelector(
        captureSlice.selectors.getIsPlayingBack,
    );

    const onPlayClick = () => {
        void dispatch(handleCapturePlayButtonPressed());
    };
    const onPauseClick = () => {
        void dispatch(handleCaptureStopButtonPressed());
    };

    return (
        <PlayPauseButton
            onPlayClick={onPlayClick}
            onPauseClick={onPauseClick}
            isPlaying={isPlayingPreview}
            bgBlur={bgBlur}
            size="small"
        />
    );
};

export default CapturePlaybackButton;
