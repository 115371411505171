import React from "react";
import { createUseStyles } from "react-jss";

import { handleCaptureCancelButtonPressed } from "./thunks";
import CancelCapture from "shared/icons/CancelCapture.svg";
import { useHydraDispatch } from "state/hooks";

const useStyles = createUseStyles({
    container: {
        position: "absolute",
        top: "14px",
        right: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        zIndex: 4,
    },
});
const CaptureCancelButton = () => {
    const styles = useStyles();
    const dispatch = useHydraDispatch();

    const handleClick = () => {
        void dispatch(handleCaptureCancelButtonPressed());
    };

    return (
        <div className={styles.container} onClick={handleClick}>
            <CancelCapture />
        </div>
    );
};

export default CaptureCancelButton;
