import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { sendGUIOpenEvent } from "api/eventsServiceApi/events/sessionEvents";
import { loginPageSlice } from "features/LoginPage/loginPageSlice";
import { BOGUS_USER_ID } from "shared/constants/session";
import { useHydraDispatch } from "state/hooks";

export const useTrackGUIOpenEvent = () => {
    const authToken = useSelector(loginPageSlice.selectors.getAuthToken);
    const userId = useSelector(loginPageSlice.selectors.getWpUserId);
    const userHasOpenedGUI = useSelector(
        loginPageSlice.selectors.getUserHasOpenedGUI,
    );
    const [hasLoggedGUIOpen, setHasLoggedGUIOpen] = useState(false);
    const dispatch = useHydraDispatch();

    useEffect(() => {
        const hasValidUserId = userId !== null && userId !== BOGUS_USER_ID;
        // If the auth token refreshes we don't want to count it as an "open" event
        if (authToken && !hasLoggedGUIOpen && hasValidUserId) {
            // Track that the GUI app has been opened
            void dispatch(sendGUIOpenEvent({ firstTime: !userHasOpenedGUI }));
            setHasLoggedGUIOpen(true);
        }
    }, [authToken, hasLoggedGUIOpen, userId]);
};
