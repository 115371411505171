import React from "react";
import { createUseStyles } from "react-jss";

import CaptureWaveformBig, {
    CaptureWaveformBigProps,
} from "../Waveform/Big/CaptureWaveformBig";
import { labelStyle } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    label: {
        ...labelStyle,
        paddingBottom: "13px",
    },
});

type FinalConfirmationProps = CaptureWaveformBigProps;

const FinalConfirmation = ({ capturedAudioKey }: FinalConfirmationProps) => {
    const styles = useStyles();
    return (
        <>
            <p className={styles.label}>Audio Input</p>
            <CaptureWaveformBig capturedAudioKey={capturedAudioKey} />
        </>
    );
};
export default FinalConfirmation;
