import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { captureSlice } from "features/Capture/captureSlice";
import Waveform from "features/SearchPage/LoopRow/Waveform/Waveform";
import { cyanTint05, gray8 } from "shared/constants/color";

type StyleProps = {
    containerHeight: number;
    waveformMarginTop?: string;
};

const useStyles = createUseStyles({
    captureWaveformBg: ({ containerHeight }: StyleProps) => ({
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        border: `1px solid ${cyanTint05}`,
        boxSizing: "border-box",
        width: "100%",
        backgroundColor: gray8,
        background: `linear-gradient(${cyanTint05}, ${cyanTint05})`, // Cyan on top of gray8
        height: `${containerHeight}px`,
    }),
    waveformMarginTop: ({ waveformMarginTop }: StyleProps) => ({
        marginTop: waveformMarginTop,
    }),
});

type CaptureWaveformProps = {
    readonly waveformHeight: number;
    readonly containerHeight: number;
    readonly centerOffset?: string;
};

const CaptureWaveform = ({
    waveformHeight,
    containerHeight,
    centerOffset = "0px",
}: CaptureWaveformProps) => {
    const styles = useStyles({
        containerHeight,
        waveformMarginTop: centerOffset,
    });
    const waveformData = useSelector(captureSlice.selectors.getWaveform);
    const containerRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.offsetWidth);
            }
        };

        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, []);

    return waveformData ? (
        <div ref={containerRef} className={styles.captureWaveformBg}>
            <div className={styles.waveformMarginTop}>
                <Waveform
                    key={width}
                    data={waveformData}
                    width={width}
                    height={waveformHeight}
                />
            </div>
        </div>
    ) : null;
};

export default CaptureWaveform;
