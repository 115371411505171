import React from "react";
import { createUseStyles } from "react-jss";

import { cyan, cyanTint15 } from "shared/constants/color";
import ArrowWithTail from "shared/icons/ArrowWithTail";

type AdjustmentIndicatorProps = {
    readonly value: number;
};

const useStyles = createUseStyles({
    adjustmentIndicatorContainer: {
        display: "flex",
        flexDirection: "row",
        gap: "2px",
        alignItems: "center",
        backgroundColor: cyanTint15,
        color: cyan,
        borderRadius: "100px",
        boxSizing: "border-box",
        padding: "4px 6px 4px 5px",
        fontSize: "12px",
        height: "18px",
    },
});
const AdjustmentIndicator = ({ value }: AdjustmentIndicatorProps) => {
    const styles = useStyles();
    const arrowDirection = value > 0 ? "up" : "down";
    const arrowElement =
        value === 0 ? null : (
            <ArrowWithTail
                strokeColor={cyan}
                direction={arrowDirection}
                width={11}
            />
        );
    return (
        <div className={styles.adjustmentIndicatorContainer}>
            {arrowElement}
            {Math.abs(value)}
        </div>
    );
};

export default AdjustmentIndicator;
