import { JssStyle } from "jss";
import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { handleCaptureButtonPressed } from "./thunks";
import { captureSlice } from "features/Capture/captureSlice";
import { cyan } from "shared/constants/color";
import { useHydraDispatch } from "state/hooks";

const CAPTURE_BUTTON_SIZE = "56px";
const OUTER_CIRCLE_SIZE = "44px";
const INNER_CIRCLE_SIZE = "16px";
const MIDDLE_CIRCLE_SIZE = "34px";

const makeCircleSizeStyle = (size: string): JssStyle => ({
    width: `calc(${size})`,
    height: `calc(${size})`,
});

const makeCircleStyle = (size: string, backgroundColor: string): JssStyle => ({
    ...makeCircleSizeStyle(size),
    backgroundColor,
    position: "absolute",
    borderRadius: "100%",
    transition: [["height 0.2s"], ["width 0.2s"]],
});

const useStyles = createUseStyles({
    container: ({ shouldShow }: { shouldShow: boolean }) => ({
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: shouldShow ? 1 : 0,
        zIndex: shouldShow ? 2 : 1,
        transition: [["opacity 200ms linear"]],
    }),
    button: {
        width: CAPTURE_BUTTON_SIZE,
        height: CAPTURE_BUTTON_SIZE,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
            "& $innerCircle": makeCircleSizeStyle(
                `calc(${INNER_CIRCLE_SIZE} + 7px)`,
            ),
            "& $middleCircle": makeCircleSizeStyle(
                `calc(${MIDDLE_CIRCLE_SIZE} + 9px)`,
            ),

            "& $outerCircle": makeCircleSizeStyle(
                `calc(${OUTER_CIRCLE_SIZE} + 15px)`,
            ),
        },
    },
    innerCircle: makeCircleStyle(INNER_CIRCLE_SIZE, cyan),
    middleCircle: makeCircleStyle(
        MIDDLE_CIRCLE_SIZE,
        "rgba(10, 235, 194, 0.08)",
    ),
    outerCircle: makeCircleStyle(OUTER_CIRCLE_SIZE, "rgba(10, 235, 194, 0.12)"),
});
const CaptureButton = () => {
    const isCapturing = useSelector(captureSlice.selectors.getIsCapturing);
    const captureInitiated = useSelector(
        captureSlice.selectors.getCaptureInitiated,
    );
    const styles = useStyles({
        shouldShow: !(isCapturing || captureInitiated),
    });
    const dispatch = useHydraDispatch();
    const onClick = () => {
        void dispatch(handleCaptureButtonPressed());
    };
    return (
        <div className={styles.container}>
            <div className={styles.button} role="button" onClick={onClick}>
                <div className={styles.outerCircle} />
                <div className={styles.middleCircle} />
                <div className={styles.innerCircle} />
            </div>
        </div>
    );
};
export default CaptureButton;
