/* eslint-disable react/jsx-max-depth */
import React from "react";

const PlayIcon = ({
    iconWidth,
    iconHeight = iconWidth,
}: {
    readonly iconWidth: string;
    readonly iconHeight?: string;
}) => (
    <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.16765 12.8339C2.44528 13.2794 1.5 12.7765 1.5 11.9465V1.05349C1.5 0.223538 2.44528 -0.279441 3.16766 0.166142L11.9975 5.61265C12.6675 6.02595 12.6675 6.97405 11.9975 7.38735L3.16765 12.8339Z"
            fill="white"
        />
    </svg>
);

export default PlayIcon;
