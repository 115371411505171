import React, { MutableRefObject, useRef } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import LoopRow from "./LoopRow/LoopRow";
import SearchResultsTableHeader from "./SearchResultsTableHeader";
import ProcessingStoppedWarning from "features/ProcessingStoppedWarning/ProcessingStoppedWarning";
import {
    useScrollFromPersistedState,
    useScrollPositionTracker,
} from "features/SearchPage/hooks";
import { searchSlice } from "features/SearchPage/searchPageSlice";
import Loader from "shared/components/Loader/Loader";
import { gray8 } from "shared/constants/color";
import {
    flexCenterChildren,
    sharedTableStyles,
} from "shared/styles/layout.styles";

const useStyles = createUseStyles({
    ...sharedTableStyles,
    container: {
        display: "flex",
        flexDirection: "column",
        maxWidth: "850px",
        width: "100%",
        overflowY: "hidden",
        backgroundColor: gray8,
    },
    results: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        transition: "height 1s",
    },
    resultsList: {
        overflowY: "scroll",
    },
    loaderOrEmpty: {
        ...flexCenterChildren,
        flexDirection: "column",
        height: "100%",
    },
});

type SearchPageProps = {
    readonly isLoading: boolean;
};

const SearchPage = ({ isLoading }: SearchPageProps) => {
    const styles = useStyles();
    const loopById = useSelector(searchSlice.selectors.getSearchResultsById);
    const listElmRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

    const { onScroll } = useScrollPositionTracker(listElmRef);
    useScrollFromPersistedState(listElmRef);

    const loopElements = Object.keys(loopById).map((loopId, index) => (
        <LoopRow key={loopId} loop={loopById[loopId]} rowNumber={index} />
    ));

    const shouldDisplayResults = loopElements.length > 0 && !isLoading;

    const loaderOrEmpty = isLoading ? <Loader /> : <span>No results!!</span>;

    return (
        <div className={styles.container}>
            <ProcessingStoppedWarning />
            <div className={styles.results}>
                {shouldDisplayResults ? (
                    <>
                        <SearchResultsTableHeader />
                        <div
                            ref={listElmRef}
                            onScrollCapture={onScroll}
                            className={styles.resultsList}
                        >
                            {loopElements}
                        </div>
                    </>
                ) : (
                    <div className={styles.loaderOrEmpty}>{loaderOrEmpty}</div>
                )}
            </div>
        </div>
    );
};

export default SearchPage;
