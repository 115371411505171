import { AsyncThunkPayloadCreator, createAsyncThunk } from "@reduxjs/toolkit";

import { HydraDispatch } from "./hooks";
import { HydraServices } from "services";

type ThunkExtraArgument = {
    extra: HydraServices;
    dispatch: HydraDispatch;
};

export function createHydraThunk<
    ThunkPayloadType = undefined,
    ThunkReturnType = void,
>(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<
        ThunkReturnType,
        ThunkPayloadType,
        ThunkExtraArgument
    >,
) {
    return createAsyncThunk<
        ThunkReturnType,
        ThunkPayloadType,
        ThunkExtraArgument
    >(typePrefix, payloadCreator);
}
