import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { previewSlice } from "features/Preview/previewSlice";
import { cyan } from "shared/constants/color";

const PLAYBACK_PERCENT_MULTIPLIER = 100;
const useStyles = createUseStyles({
    container: ({ playbackPercent }: { playbackPercent: number }) => ({
        position: "absolute",
        height: "4px",
        width: `${playbackPercent * PLAYBACK_PERCENT_MULTIPLIER}%`,
        bottom: 0,
        left: 0,
        backgroundColor: cyan,
    }),
});

const ProgressBar = () => {
    const playbackPercent = useSelector(
        previewSlice.selectors.getPlayerProgress,
    );
    const styles = useStyles({ playbackPercent });
    return <div className={styles.container} />;
};

export default ProgressBar;
