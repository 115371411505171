import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    getCurrentlyDraggedLoop,
    getHasSearchReachedCharacterLimit,
    getIsOSDragging,
    getIsSearchLoading,
    getSearchId,
    getSearchLength,
    getSearchQuery,
    getSearchResultsById,
    getThumbsUpClicked,
    getThumbsDownClicked,
} from "./searchPageSelectors";
import { SearchState } from "./SearchState";
import { sendSearchExecutedEvent } from "api/eventsServiceApi/events/SearchEvents";
import { hydraServiceApi } from "api/hydraServiceApi/hydraServiceApi";
import { parsedPersistedStateReceived } from "features/PersistedState/persistedStateSlice";
import { searchSlicePrefix } from "features/SearchPage/constants";
import {
    loopDragStarted,
    onLoopDragOutOfWindow,
    ratingClicked,
} from "features/SearchPage/thunks";
import {
    externalFileDragComplete,
    externalFileDragStart,
} from "Protocol/Generated/MessageHelpers";
import { Loop } from "shared/types/Loop";
import { convertAPILoopToLoop } from "shared/utils/loopUtil";

export const searchCharacterLimit = 150;
export const THUMBS_UP_RATING = 1;
export const THUMBS_DOWN_RATING = -1;
export const THUMBS_NO_RATING = 0;

export const searchReducerDefaultState: SearchState = {
    searchQuery: "",
    searchResultById: {},
    isLoading: false,
    searchId: null,
    scrollPosition: 0,
    isOSDragging: false, // When drag has been handed over to the OS
    isDraggingOutsideWindow: false, // When drag has gone outside the window, but not yet handed over to OS
    loopBeingDragged: null,
    thumbsUpClicked: false,
    thumbsDownClicked: false,
};

export const searchSlice = createSlice({
    name: searchSlicePrefix,
    initialState: searchReducerDefaultState,
    reducers: {
        updateScrollPosition: (state, action: PayloadAction<number>) => {
            state.scrollPosition = action.payload;
            return state;
        },
        updateSearchQuery: (state, action: PayloadAction<string>) => {
            state.searchQuery = action.payload;
            return state;
        },
        endDrag: (state) => {
            state.isDraggingOutsideWindow = false;
            state.loopBeingDragged = null;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(parsedPersistedStateReceived, (state, action) => {
            const persistedState = action.payload;

            if (persistedState.searchQuery) {
                state.searchQuery = persistedState.searchQuery;
            }

            if (persistedState.searchId) {
                state.searchId = persistedState.searchId;
            }

            if (persistedState.searchResultById) {
                state.searchResultById = persistedState.searchResultById;
            }

            if (persistedState.scrollPosition) {
                state.scrollPosition = persistedState.scrollPosition;
            }
        });
        builder.addCase(sendSearchExecutedEvent.fulfilled, (state, action) => {
            state.isLoading = true;
            state.searchId = action.meta.arg.searchId;
        });
        builder.addCase(loopDragStarted.pending, (state, action) => {
            state.loopBeingDragged = action.meta.arg.loop;
        });
        builder.addCase(externalFileDragStart, (state) => {
            state.isOSDragging = true;
        });
        builder.addCase(externalFileDragComplete, (state) => {
            state.isOSDragging = false;
        });
        builder.addCase(onLoopDragOutOfWindow.pending, (state) => {
            state.isDraggingOutsideWindow = true;
        });
        builder.addCase(ratingClicked.pending, (state, action) => {
            const rating = action.meta.arg;
            if (rating === THUMBS_UP_RATING) {
                state.thumbsUpClicked = true;
                state.thumbsDownClicked = false;
            } else if (rating === THUMBS_DOWN_RATING) {
                state.thumbsDownClicked = true;
                state.thumbsUpClicked = false;
            } else if (rating === THUMBS_NO_RATING) {
                state.thumbsDownClicked = false;
                state.thumbsUpClicked = false;
            }
        });
        builder.addMatcher(
            hydraServiceApi.endpoints.getLoops.matchPending,
            (state) => {
                state.isLoading = true;
            },
        );
        builder.addMatcher(
            hydraServiceApi.endpoints.getLoops.matchRejected,
            (state) => {
                state.isLoading = false;
            },
        );
        builder.addMatcher(
            hydraServiceApi.endpoints.getLoops.matchFulfilled,
            (state, action) => {
                state.isLoading = false;
                const searchResultsById: { [key: string]: Loop } = {};

                action.payload.loops.forEach((apiLoop) => {
                    const { id } = apiLoop;
                    searchResultsById[id] = convertAPILoopToLoop(apiLoop);
                });

                state.searchResultById = searchResultsById;
                state.thumbsUpClicked = false;
                state.thumbsDownClicked = false;
            },
        );
        builder.addMatcher(
            hydraServiceApi.endpoints.getLoopsWithAudioReference.matchPending,
            (state) => {
                state.isLoading = true;
            },
        );
        builder.addMatcher(
            hydraServiceApi.endpoints.getLoopsWithAudioReference.matchFulfilled,
            (state, action) => {
                state.isLoading = false;
                const searchResultsById: { [key: string]: Loop } = {};

                action.payload.loops.forEach((apiLoop) => {
                    const { id } = apiLoop;
                    searchResultsById[id] = convertAPILoopToLoop(apiLoop);
                });

                state.searchResultById = searchResultsById;
                state.thumbsUpClicked = false;
                state.thumbsDownClicked = false;
            },
        );
    },
    selectors: {
        getSearchQuery,
        getScrollPosition: (state) => state.scrollPosition,
        getSearchResultsById,
        getIsLoading: getIsSearchLoading,
        getSearchId,
        getSearchLength,
        getHasReachedCharacterLimit: getHasSearchReachedCharacterLimit,
        getIsOSDragging,
        getCurrentlyDraggedLoop,
        getThumbsUpClicked,
        getThumbsDownClicked,
    },
});
