import {
    sendAudioReferenceListenCancelEvent,
    sendAudioReferenceListenClickEvent,
    sendAudioReferenceListenDoneEvent,
    sendAudioReferenceListenPreviewEvent,
} from "api/eventsServiceApi/events/captureEvents";
import { sendSearchExecutedEvent } from "api/eventsServiceApi/events/SearchEvents";
import { fetchLoops } from "api/hydraServiceApi/thunks";
import {
    getCapturedAudioKey,
    getCapturePercent,
} from "features/Capture/captureSelectors";
import { captureProgressPercentageToCurrentBarNumber } from "features/Capture/util";
import { getMusicalKey } from "features/Preview/previewSelectors";
import { updatePreviewKey } from "features/Preview/thunks";
import { MessageType } from "Protocol/Generated/Messages";
import {
    keyHasNoScaleOrNoRoot,
    keysAreEqual,
} from "shared/utils/musicalKeyUtil";
import { State } from "state/types";
import { createHydraThunk } from "state/typeUtil";

export const sendCaptureChangeMessage = createHydraThunk<boolean>(
    "capture/sendCaptureChange",
    (isCapturing, { extra: { messageService } }) => {
        messageService.sendMessage({
            type: isCapturing
                ? MessageType.CaptureAudio
                : MessageType.StopAudioCapture,
        });
    },
);

export const handleCaptureButtonPressed = createHydraThunk(
    "capture/captureButtonPressed",
    (_, { dispatch }) => {
        void dispatch(sendAudioReferenceListenClickEvent());
        void dispatch(sendCaptureChangeMessage(true));
    },
);

export const handleCaptureCancelButtonPressed = createHydraThunk(
    "capture/captureCancelButtonPressed",
    (_, { dispatch, getState }) => {
        const state = getState() as State;
        const capturePercent = getCapturePercent(state.capture);
        void dispatch(
            sendAudioReferenceListenCancelEvent({
                barsCaptured:
                    captureProgressPercentageToCurrentBarNumber(capturePercent),
            }),
        );
        void dispatch(sendCaptureChangeMessage(false));
    },
);

export const sendUploadAudioMessage = createHydraThunk(
    "capture/sendUploadAudio",
    (_, { extra: { messageService } }) => {
        messageService.sendMessage({
            type: MessageType.UploadCapturedAudio,
        });
    },
);

export const sendCapturePlayStateMessage = createHydraThunk<boolean>(
    "capture/sendCapturePlayState",
    (isPlaying, { extra: { messageService } }) => {
        messageService.sendMessage({
            type: MessageType.AudioCapturePlayState,
            isPlaying,
        });
    },
);

export const handleCapturePlayButtonPressed = createHydraThunk(
    "capture/capturePlayButtonPressed",
    (_, { dispatch }) => {
        void dispatch(sendAudioReferenceListenPreviewEvent());
        void dispatch(sendCapturePlayStateMessage(true));
    },
);

export const handleCaptureStopButtonPressed = createHydraThunk(
    "capture/captureStopButtonPressed",
    (_, { dispatch }) => {
        void dispatch(sendCapturePlayStateMessage(false));
    },
);

export const audioUploadIdChangedFromCpp = createHydraThunk<{
    uploadId: string | null;
}>("capture/audioUploadSuccess", async ({ uploadId }, { dispatch }) => {
    if (uploadId !== null) {
        const searchId = crypto.randomUUID();
        await dispatch(sendSearchExecutedEvent({ searchId }));
        await dispatch(fetchLoops()).unwrap();
    }
});

export const keyConfirmButtonPressed = createHydraThunk(
    "capture/keyConfirmButtonPressed",
    (_, { getState, dispatch }) => {
        sendAudioReferenceListenDoneEvent(dispatch);

        const state = getState() as State;
        const capturedAudioKey = getCapturedAudioKey(state.capture);
        const sessionKey = getMusicalKey(state.preview);

        if (
            capturedAudioKey !== null &&
            !keyHasNoScaleOrNoRoot(capturedAudioKey) &&
            !keysAreEqual(capturedAudioKey, sessionKey)
        ) {
            void dispatch(
                updatePreviewKey({
                    newKey: capturedAudioKey,
                    showKeyChangeModal: true,
                }),
            );
        }
    },
);
