import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { getCanExecuteSearch } from "../searchPageSelectors";
import { pressSearchButton } from "features/SearchPage/thunks";
import CTAButton from "shared/components/Buttons/CTAButton";
import { cyan } from "shared/constants/color";
import ArrowWithTail from "shared/icons/ArrowWithTail";
import { useHydraDispatch } from "state/hooks";

const useStyles = createUseStyles({
    searchButtonContainer: {
        backgroundColor: cyan,
        height: "40px",
        borderRadius: "8px",
    },
});

const SearchButton = () => {
    const styles = useStyles();
    const dispatch = useHydraDispatch();
    const enabled = useSelector(getCanExecuteSearch);

    const handleClick = () => {
        void dispatch(pressSearchButton());
    };

    return (
        <CTAButton
            additionalClasses={styles.searchButtonContainer}
            onClick={handleClick}
            enabled={enabled}
        >
            <ArrowWithTail width={18} />
        </CTAButton>
    );
};

export default SearchButton;
