/* eslint-disable camelcase */
import { EVENT_PREFIX } from "api/eventsServiceApi/events/constants";
import { sendDownloadDragEvent } from "api/eventsServiceApi/events/loopEvents";
import { sendSearchExecutedEvent } from "api/eventsServiceApi/events/SearchEvents";
import { sendEvent } from "api/eventsServiceApi/thunks";
import { fetchLoops } from "api/hydraServiceApi/thunks";
import { getUploadId } from "features/Capture/captureSelectors";
import { captureSlice } from "features/Capture/captureSlice";
import { sendUploadAudioMessage } from "features/Capture/thunks";
import { initiateDownload } from "features/Download/downloadSlice";
import {
    getCurrentlyDraggedLoop,
    getIsDraggingOutsideWindow,
    getSearchId,
    getSearchQuery,
} from "features/SearchPage/searchPageSelectors";
import {
    LoopDownloadSuccess,
    MessageType,
    RenderComplete,
} from "Protocol/Generated/Messages";
import MessageService from "services/messageService";
import { getLoopKey, Loop } from "shared/types/Loop";
import { State } from "state/types";
import { createHydraThunk } from "state/typeUtil";

const sendRenderMessage = (loop: Loop, messageService: MessageService) => {
    messageService.sendMessage({
        type: MessageType.Render,
        id: loop.id,
        renderKeyAndBpmLock: true,
        sampleBpm: loop.bpm,
        sampleKey: getLoopKey(loop),
        sampleName: loop.name.split("_")[0],
    });
};

const sendDragMessage = (id: string, messageService: MessageService) => {
    messageService.sendMessage({
        type: MessageType.Drag,
        id,
    });
};

export const pressSearchButton = createHydraThunk(
    "hydraServiceApi/handleSearchQueryChange",
    async (_, { getState, dispatch }) => {
        const state = getState() as State;

        if (captureSlice.selectors.getIsUploading(state)) {
            return;
        }

        if (captureSlice.selectors.getNeedsUpload(state)) {
            void dispatch(sendUploadAudioMessage());
            return;
        }

        const searchId = crypto.randomUUID();
        await dispatch(sendSearchExecutedEvent({ searchId }));
        await dispatch(fetchLoops()).unwrap();
    },
);

export const onLoopDragOutOfWindow = createHydraThunk<string>(
    "search/onLoopDragOutOfWindow",
    (id, { extra: { messageService } }) => {
        sendDragMessage(id, messageService);
    },
);

type RenderCompleteThunkArg = Omit<RenderComplete, "type">;
export const dragRenderCompleted = createHydraThunk<RenderCompleteThunkArg>(
    "search/dragRenderCompleted",
    ({ id }, { getState, extra: { messageService } }) => {
        const searchState = (getState() as State).search;
        const draggedId = getCurrentlyDraggedLoop(searchState)?.id;
        const isDraggingOutsideWindow = getIsDraggingOutsideWindow(searchState);

        if (id !== draggedId || !isDraggingOutsideWindow) {
            return;
        }

        sendDragMessage(id, messageService);
    },
);

type LoopDownloadSuccessThunkArg = Omit<LoopDownloadSuccess, "type">;
export const loopDownloadCompleted =
    createHydraThunk<LoopDownloadSuccessThunkArg>(
        "search/loopDownloadCompleted",
        ({ id }, { getState, extra: { messageService } }) => {
            const searchState = (getState() as State).search;
            const draggedLoop = searchState.loopBeingDragged;
            if (draggedLoop === null || id !== draggedLoop.id) {
                return;
            }

            sendRenderMessage(draggedLoop, messageService);
        },
    );

type LoopDragStartedThunkArg = {
    loop: Loop;
    isLoopDownloaded: boolean;
    rowNumber: number;
};
export const loopDragStarted = createHydraThunk<LoopDragStartedThunkArg>(
    "search/loopDragStarted",
    (
        { loop, isLoopDownloaded, rowNumber },
        { dispatch, extra: { messageService } },
    ) => {
        if (isLoopDownloaded) {
            void dispatch(
                sendDownloadDragEvent({
                    loopName: loop.name,
                    loopUuid: loop.id,
                    rowNumber,
                }),
            );
            sendRenderMessage(loop, messageService);
        } else {
            void dispatch(initiateDownload({ [loop.id]: loop.olac_url }));
        }
    },
);

export const ratingClicked = createHydraThunk<number>(
    "search/ratingClicked",
    (rating, { getState, dispatch }) => {
        const state = getState() as State;
        const searchId = getSearchId(state.search);
        const uploadId = getUploadId(state.capture);
        const searchTerm = getSearchQuery(state.search);
        void dispatch(
            sendEvent({
                title: `${EVENT_PREFIX}.Search.Rating`,
                properties: {
                    rating,
                    search_id: searchId,
                    audio_upload_id: uploadId,
                    audio_reference: uploadId !== null,
                    search_term: searchTerm,
                },
            }),
        );
    },
);
