import React from "react";
import { createUseStyles } from "react-jss";

import { useLoadingStatesAnimation } from "./loaderHooks";

const useStyles = createUseStyles({
    loaderContainer: {
        fontSize: "60px",
    },
});

type LoaderProps = {
    readonly emoji?: string;
};

const Loader = ({ emoji }: LoaderProps) => {
    const styles = useStyles();
    const { value } = useLoadingStatesAnimation(emoji || "🎶");

    return (
        <div className={styles.loaderContainer} data-testid="loader">
            {value}
        </div>
    );
};

export default Loader;
