import { createSlice } from "@reduxjs/toolkit";

import {
    activeDownloads,
    downloadedLoops,
    loopDownloadSuccess,
} from "Protocol/Generated/MessageHelpers";
import { ActiveDownload, MessageType } from "Protocol/Generated/Messages";
import { createHydraThunk } from "state/typeUtil";

export type DownloadState = {
    activeDownloads: Array<ActiveDownload>;
    downloadedLoopIds: Array<string>;
};

const initialState: DownloadState = {
    activeDownloads: [],
    downloadedLoopIds: [],
};

export const initiateDownload = createHydraThunk<{
    [id: string]: string;
}>("download/initiateDownload", (urlById, { extra: { messageService } }) => {
    messageService.sendMessage({
        type: MessageType.DownloadLoops,
        urlById,
    });
});

export const sendCancelLoopDownloadMessage = createHydraThunk<string>(
    "download/sendCancelLoopDownloadMessage",
    (id, { extra: { messageService } }) => {
        messageService.sendMessage({
            type: MessageType.CancelLoopDownloads,
            ids: [id],
        });
    },
);

export const downloadSlice = createSlice({
    name: "download",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(loopDownloadSuccess, (state, action) => {
            const { id } = action.payload;

            state.activeDownloads = state.activeDownloads.filter(
                (download) => download.id !== id,
            );

            state.downloadedLoopIds.push(id);

            return state;
        });
        builder.addCase(downloadedLoops, (state, action) => {
            const { ids } = action.payload;
            state.downloadedLoopIds = ids;
            return state;
        });
        builder.addCase(activeDownloads, (state, action) => {
            state.activeDownloads = action.payload.activeDownloads;
            return state;
        });
        builder.addCase(
            sendCancelLoopDownloadMessage.fulfilled,
            (state, action) => {
                state.activeDownloads = state.activeDownloads.filter(
                    (download) => download.id !== action.meta.arg,
                );

                return state;
            },
        );
    },
    selectors: {
        getIsLoopDownloading: (state, id: string) =>
            state.activeDownloads.some((download) => download.id === id),
        getIsLoopDownloaded: (state, id: string) =>
            state.downloadedLoopIds.includes(id),
        getLoopDownloadProgress: (state, id: string) => {
            const download = state.activeDownloads.find(
                (loop) => loop.id === id,
            );

            return download?.progress ?? 0;
        },
    },
});
