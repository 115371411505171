import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    authTokenUpdated,
    refreshFailure,
} from "Protocol/Generated/MessageHelpers";

export type AppRoute = "login" | "search";
export type MainWindowState = {
    currentRoute: AppRoute;
};
const initialState: MainWindowState = {
    currentRoute: "login",
};

export const mainWindowSlice = createSlice({
    name: "mainWindow",
    initialState,
    reducers: {
        updateCurrentRoute: (state, action: PayloadAction<AppRoute>) => {
            state.currentRoute = action.payload;
            return state;
        },
    },
    extraReducers(builder) {
        builder.addCase(authTokenUpdated, (state, action) => {
            if (action.payload.authToken) {
                state.currentRoute = "search";
            } else {
                state.currentRoute = "login";
            }
        });
        builder.addCase(refreshFailure, (state) => {
            state.currentRoute = "login";
        });
    },
    selectors: {
        getCurrentRoute: (state) => state.currentRoute,
    },
});
