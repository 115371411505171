/**
 * Returns a string representation of a number with a specified number of decimal places.
 * IFF all decimals are zero, then the decimal places are removed.
 */
export const toStringRemovingTrailingZeroes = (
    num: number,
    decimalPlaces: number,
): string => {
    const numString = num.toFixed(decimalPlaces);

    if (!numString.includes(".")) {
        return numString;
    }

    const [integerPart, decimalPart] = numString.split(".");
    const allDecimalsAreZero = decimalPart.replace(/0+$/u, "") === "";
    return allDecimalsAreZero ? integerPart : numString;
};
