import {
    getAPIScaleAndTonicFromMusicalKey,
    hydraServiceApi,
    LoopsResponse,
} from "api/hydraServiceApi/hydraServiceApi";
import {
    getCapturedAudioKey,
    getUploadId,
} from "features/Capture/captureSelectors";
import { getBpm, getMusicalKey } from "features/Preview/previewSelectors";
import { getSearchQuery } from "features/SearchPage/searchPageSelectors";
import { Loop } from "shared/types/Loop";
import {
    convertAPILoopToLoop,
    isLoopDownloadUrlExpired,
} from "shared/utils/loopUtil";
import { State } from "state/types";
import { createHydraThunk } from "state/typeUtil";

export const fetchLoops = createHydraThunk<undefined, LoopsResponse>(
    "searchPage/fetchLoops",
    async (_, { getState, dispatch }): Promise<LoopsResponse> => {
        const state = getState() as State;
        const searchQuery = getSearchQuery(state.search);
        const musicalKey = getMusicalKey(state.preview);
        const detectedKey = getCapturedAudioKey(state.capture);
        const bpm = getBpm(state.preview);
        const uploadId = getUploadId(state.capture);
        const hasUploadId = uploadId !== null;

        const { scale, tonic } = getAPIScaleAndTonicFromMusicalKey(musicalKey);
        const { scale: audioRefScale, tonic: audioRefTonic } =
            getAPIScaleAndTonicFromMusicalKey(detectedKey);

        const getLoopsAction = hasUploadId
            ? hydraServiceApi.endpoints.getLoopsWithAudioReference.initiate({
                  bpm,
                  searchQuery,
                  scale,
                  tonic,
                  audioRefScale,
                  audioRefTonic,
                  audioId: uploadId,
              })
            : hydraServiceApi.endpoints.getLoops.initiate({
                  bpm,
                  searchQuery,
                  scale,
                  tonic,
              });

        return await dispatch(getLoopsAction).unwrap();
    },
);

export const getLoopWithValidUrl = createHydraThunk<{ loop: Loop }, Loop>(
    "hydraServiceApi/getLoopWithValidUrl",
    async ({ loop }, { dispatch }) => {
        let loopToUse: Loop | undefined = loop;
        const { id } = loopToUse;
        if (isLoopDownloadUrlExpired(loop)) {
            const data = await dispatch(fetchLoops()).unwrap();
            const loopFromResponse = data.loops.find((l) => l.id === id);
            loopToUse = loopFromResponse
                ? convertAPILoopToLoop(loopFromResponse)
                : undefined;
            if (!loopToUse) {
                throw new Error(
                    `Could not find loop with id ${id} in search results`,
                );
            }
        }

        return loopToUse;
    },
);
