import { LoginPageState } from "features/LoginPage/types";

export const getSessionUuid = (state: LoginPageState) => state.sessionUuid;
export const getInstanceUuid = (state: LoginPageState) => state.instanceUuid;
export const getWpUserId = (state: LoginPageState) => state.wpUserId;
export const getUniqueMachineId = (state: LoginPageState) =>
    state.uniqueMachineId;
export const getProductVersion = (state: LoginPageState) =>
    state.productVersion;
export const getAuthToken = (state: LoginPageState) => state.authToken;

export const getEmail = (state: LoginPageState) => state.email;
