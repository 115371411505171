import React from "react";
import { createUseStyles } from "react-jss";

import { APP_MARGIN_PX } from "shared/constants/layout";
import CoPro from "shared/icons/CoPro.svg";
import { fontSize1 } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    titleContainer: {
        display: "flex",
        alignItems: "center",
    },
    title: {
        ...fontSize1,
        marginLeft: APP_MARGIN_PX,
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        boxSizing: "border-box",
    },
});
const Logo = () => {
    const styles = useStyles();
    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <CoPro />
                <span className={styles.title}>CoPro</span>
            </div>
        </div>
    );
};

export default Logo;
