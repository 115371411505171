import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CaptureState } from "./CaptureState";
import {
    audioUploadIdChangedFromCpp,
    keyConfirmButtonPressed,
    sendCaptureChangeMessage,
    sendUploadAudioMessage,
} from "./thunks";
import {
    getCapturedAudioKey,
    getCapturePercent,
    getHasConfirmedCapture,
    getIsCaptureIdle,
    getIsUploading,
    getNeedsUpload,
    getUploadId,
} from "features/Capture/captureSelectors";
import { parsedPersistedStateReceived } from "features/PersistedState/persistedStateSlice";
import {
    audioCaptureKey,
    audioCapturePlaybackProgress,
    audioCapturePlayState,
    audioCaptureProgress,
    audioCaptureRms,
    audioCaptureWaveform,
    captureAudio,
    stopAudioCapture,
} from "Protocol/Generated/MessageHelpers";
import { MusicalKey } from "Protocol/Generated/Messages";

export const initialCaptureState: CaptureState = {
    isCapturing: false,
    rmsValue: 0,
    waveform: null,
    detectedKey: null,
    userKeyOverride: null,
    hasConfirmed: false,
    isPlayingBack: false,
    playbackProgress: 0,
    capturePercent: 0,
    captureInitiated: false,
    uploadId: null,
    isUploading: false,
};

export const captureSlice = createSlice({
    name: "capture",
    initialState: initialCaptureState,
    reducers: {
        pressEditKeyButton: (state: CaptureState) => {
            state.hasConfirmed = false;
        },
        userSelectKey: (state, action: PayloadAction<MusicalKey>) => {
            state.userKeyOverride = action.payload;
        },
        clearCapture: () => initialCaptureState,
    },
    extraReducers: (builder) => {
        builder.addCase(keyConfirmButtonPressed.pending, (state) => {
            state.hasConfirmed = true;
        });
        builder.addCase(captureAudio, () => ({
            ...initialCaptureState,
            isCapturing: true,
        }));
        builder.addCase(stopAudioCapture, (state) => {
            state.isCapturing = false;
            state.rmsValue = 0;
            state.captureInitiated = false;
        });
        builder.addCase(audioCaptureRms, (state, action) => {
            state.rmsValue = action.payload.rms;
        });
        builder.addCase(
            audioUploadIdChangedFromCpp.pending,
            (state, action) => {
                state.uploadId = action.meta.arg.uploadId;
                state.isUploading = false;
            },
        );
        builder.addCase(sendUploadAudioMessage.pending, (state) => {
            state.isUploading = true;
        });
        builder.addCase(sendCaptureChangeMessage.fulfilled, (state, action) => {
            state.captureInitiated = action.meta.arg;
        });
        builder.addCase(audioCaptureWaveform, (state, action) => {
            state.waveform = action.payload.data;
        });
        builder.addCase(audioCaptureKey, (state, action) => {
            state.detectedKey = action.payload.key;
        });
        builder.addCase(audioCaptureProgress, (state, action) => {
            state.capturePercent = action.payload.percent;
        });
        builder.addCase(audioCapturePlayState, (state, action) => {
            state.isPlayingBack = action.payload.isPlaying;
        });
        builder.addCase(audioCapturePlaybackProgress, (state, action) => {
            state.playbackProgress = action.payload.percent;
        });
        builder.addCase(parsedPersistedStateReceived, (state, action) => {
            const persistedState = action.payload;
            if (persistedState.uploadId) {
                state.uploadId = persistedState.uploadId;
            }
            if (persistedState.captureInitiated) {
                state.captureInitiated = persistedState.captureInitiated;
            }
            if (persistedState.hasConfirmed) {
                state.hasConfirmed = persistedState.hasConfirmed;
            }
            if (persistedState.detectedKey) {
                state.detectedKey = persistedState.detectedKey;
            }
            if (persistedState.userKeyOverride) {
                state.userKeyOverride = persistedState.userKeyOverride;
            }
        });
    },
    selectors: {
        getIsCapturing: (state) => state.isCapturing,
        getRmsValue: (state) => state.rmsValue,
        getUploadId,
        getWaveform: (state) => state.waveform,
        getKey: getCapturedAudioKey,
        getUserOverrodeKey: (state) => state.userKeyOverride !== null,
        getIsConfirming: (state) =>
            state.waveform !== null &&
            state.detectedKey !== null &&
            !state.hasConfirmed,
        getHasConfirmed: getHasConfirmedCapture,
        getIsPlayingBack: (state) => state.isPlayingBack,
        getIsIdle: getIsCaptureIdle,
        getCapturePercent,
        getCaptureInitiated: (state) => state.captureInitiated,
        getDetectedKey: (state: CaptureState) => state.detectedKey,
        getUserKeyOverride: (state: CaptureState) => state.userKeyOverride,
        getNeedsUpload,
        getIsUploading,
    },
});
