import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";

import { handleLoginButtonPressed } from "../loginPageSlice";
import { white } from "shared/constants/color";
import { useHydraDispatch } from "state/hooks";

type LoginButtonProps = {
    readonly password: string;
    readonly username: string;
};

const useStyles = createUseStyles({
    container: {
        backgroundColor: "black",
        color: white,
        border: "none",
        cursor: "pointer",
        padding: "8px 12px",
        borderRadius: "4px  ",
    },
});
const LoginButton = ({ username, password }: LoginButtonProps) => {
    const styles = useStyles();
    const dispatch = useHydraDispatch();

    const handleClick = useCallback(() => {
        void dispatch(handleLoginButtonPressed({ username, password }));
    }, [username, password]);

    return (
        <button
            className={styles.container}
            onClick={handleClick}
            type="button"
        >
            Log in
        </button>
    );
};

export default LoginButton;
