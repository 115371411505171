import React from "react";
import { createUseStyles } from "react-jss";

import { gray100 } from "shared/constants/color";
import { sharedTableStyles } from "shared/styles/layout.styles";
import { labelStyle } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    ...sharedTableStyles,
    tableHeader: {
        display: "flex",
        paddingTop: "16px",
        paddingBottom: "16px",
    },
    tableLabel: {
        ...labelStyle,
        color: gray100,
    },
    cell: labelStyle,
});

const SearchResultsTableHeader = () => {
    const styles = useStyles();

    return (
        <div className={styles.tableHeader}>
            <div className={styles.table}>
                <p className={styles.tableLabel}>Samples</p>
            </div>
        </div>
    );
};

export default SearchResultsTableHeader;
