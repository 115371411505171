import { useEffect } from "react";

export const usePreventScrollOnSpaceBarPress = () => {
    useEffect(() => {
        const preventScrollOnSpacePress = (e: KeyboardEvent) => {
            if (e.code === "Space" && e.target === document.body) {
                e.preventDefault();
            }
        };
        window.addEventListener("keydown", preventScrollOnSpacePress);

        return () => {
            window.removeEventListener("keydown", preventScrollOnSpacePress);
        };
    });
};
