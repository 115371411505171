import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { loginPageSlice } from "features/LoginPage/loginPageSlice";
import MainWindow from "features/MainWindow/MainWindow";
import PreviewBar from "features/Preview/PreviewBar/PreviewBar";
import ProgressBar from "features/Preview/ProgressBar/ProgressBar";
import { searchSlice } from "features/SearchPage/searchPageSlice";
import Sidepanel from "features/Sidepanel/Sidepanel";
import Logo from "shared/components/Logo";
import { black, white } from "shared/constants/color";
import {
    APP_MARGIN_PX,
    FOOTER_HEIGHT_PX,
    HEADER_HEIGHT_PX,
} from "shared/constants/layout";
import { useTrackGUIOpenEvent } from "shared/hooks/eventHooks";
import { usePreventScrollOnSpaceBarPress } from "shared/hooks/keyboardHooks";
import { useDisableOverScroll } from "shared/hooks/overscrollHooks";
import { labelStyle } from "shared/styles/typography.styles";
import { unselectable } from "shared/styles/util.styles";

const MAIN_WINDOW_CONTAINER_HEIGHT = `calc(100vh - (${FOOTER_HEIGHT_PX} + ${HEADER_HEIGHT_PX}))`;
const useStyles = createUseStyles({
    appContainer: {
        ...unselectable,
        height: "100%",
        width: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
        overscrollBehavior: "none",
        overscrollBehaviorX: "none",
        color: white,
        backgroundColor: black,
        fontFamily: "sans-serif",
        position: "relative",

        "& p": {
            margin: "0",
        },
    },
    mainWindowContainer: {
        height: MAIN_WINDOW_CONTAINER_HEIGHT,
        display: "flex",
        flexDirection: "row",
        margin: `0 ${APP_MARGIN_PX}`,
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: HEADER_HEIGHT_PX,
        padding: "12px",
        boxSizing: "border-box",
    },
    footer: {
        height: FOOTER_HEIGHT_PX,
        width: "100%",
        bottom: "0",
        position: "fixed",
        display: "flex",
        alignItems: "center",
        background: "black",
        boxShadow: "0px -1px 6px rgba(0, 0, 0, 0.25)",
        padding: `20px ${APP_MARGIN_PX}`,
        boxSizing: "border-box",
    },
    versionNumber: labelStyle,
});

const App = () => {
    usePreventScrollOnSpaceBarPress();
    useDisableOverScroll();
    useTrackGUIOpenEvent();

    const styles = useStyles();
    const isLoading = useSelector(searchSlice.selectors.getIsLoading);
    const versionNumber = useSelector(
        loginPageSlice.selectors.getProductVersion,
    );

    return (
        <div className={styles.appContainer}>
            <div className={styles.header}>
                <Logo />
                <p className={styles.versionNumber}>{versionNumber}</p>
            </div>
            <div className={styles.mainWindowContainer}>
                <Sidepanel />
                <MainWindow isLoading={isLoading} />
            </div>
            <div className={styles.footer}>
                <PreviewBar />
                <ProgressBar />
            </div>
        </div>
    );
};
export default App;
