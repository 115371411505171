import React from "react";
import { createUseStyles } from "react-jss";

import {
    blackTint25,
    cyanTint08,
    gray100,
    whiteTint65,
} from "shared/constants/color";
import { labelStyle } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    loopRowDragOverlayContainer: {
        display: "flex",
        width: "160px",
        borderRadius: "8px",
        padding: "16px",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid ${cyanTint08}`,
        background: `linear-gradient(0deg, ${cyanTint08} 50%, ${cyanTint08} 100%)`,
        backdropFilter: "blur(14px)",
        WebkitBackdropFilter: "blur(14px)",
        boxShadow: `0px 4px 4px 0px ${blackTint25}`,
    },
    primaryText: {
        ...labelStyle,
        color: gray100,
    },
    secondaryText: {
        ...labelStyle,
        color: whiteTint65,
    },
});

type LoopRowDragOverlayProps = {
    readonly text: string;
    readonly textStyle: "primary" | "secondary";
};
const LoopRowDragOverlay = ({ text, textStyle }: LoopRowDragOverlayProps) => {
    const styles = useStyles();
    return (
        <div className={styles.loopRowDragOverlayContainer}>
            <p
                className={
                    textStyle === "primary"
                        ? styles.primaryText
                        : styles.secondaryText
                }
            >
                {text}
            </p>
        </div>
    );
};

export default LoopRowDragOverlay;
