import { createUseStyles } from "react-jss";

import { whiteTint05 } from "shared/constants/color";

const utilityButtonSize = "22px";
export const useUtilityButtonStyles = createUseStyles({
    utilityButton: {
        backgroundColor: whiteTint05,
        borderRadius: "4px",
        padding: "5px",
        width: utilityButtonSize,
        height: utilityButtonSize,
    },
});
