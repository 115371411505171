/* eslint-disable camelcase */
import {
    ACTION_PREFIX,
    EVENT_PREFIX,
} from "api/eventsServiceApi/events/constants";
import { sendEvent } from "api/eventsServiceApi/thunks";
import { getCapturedAudioKey } from "features/Capture/captureSelectors";
import { getMusicalKey } from "features/Preview/previewSelectors";
import { MusicalKey } from "Protocol/Generated/Messages";
import { HydraDispatch } from "state/hooks";
import { State } from "state/types";
import { createHydraThunk } from "state/typeUtil";

const AUDIO_REFERENCE_EVENT_NAME_FRAGMENT = "AudioReference.Listen";
type SharedAudioReferenceEventArgs = {
    barsCaptured: number;
};
/**
 * User clicks ‘listen to my audio’
 */
export const sendAudioReferenceListenClickEvent = createHydraThunk(
    `${ACTION_PREFIX}/sendAudioReferenceListenClickEvent`,
    (_, { dispatch }) => {
        void dispatch(
            sendEvent({
                title: `${EVENT_PREFIX}.${AUDIO_REFERENCE_EVENT_NAME_FRAGMENT}.Click`,
            }),
        );
    },
);

/**
 * User cancels the audio capture before it finishes
 */
export const sendAudioReferenceListenCancelEvent =
    createHydraThunk<SharedAudioReferenceEventArgs>(
        `${ACTION_PREFIX}/sendAudioReferenceListenCancelEvent`,
        async (args, { dispatch }) => {
            await dispatch(
                sendEvent({
                    title: `${EVENT_PREFIX}.${AUDIO_REFERENCE_EVENT_NAME_FRAGMENT}.Cancel`,
                    properties: {
                        bars_captured: args.barsCaptured,
                    },
                }),
            );
        },
    );

/**
 * Audio is successfully captured
 */
export const sendAudioReferenceListenSuccessEvent = createHydraThunk<object>(
    `${ACTION_PREFIX}/sendAudioReferenceListenSuccessEvent`,
    (_, { dispatch }) => {
        void dispatch(
            sendEvent({
                title: `${EVENT_PREFIX}.${AUDIO_REFERENCE_EVENT_NAME_FRAGMENT}.Success`,
            }),
        );
    },
);

/**
 * User clicks ‘redo’ for audio listen
 */
export const sendAudioReferenceListenRedoEvent = createHydraThunk(
    `${ACTION_PREFIX}/sendAudioReferenceListenRedoEvent`,
    async (_, { dispatch }) => {
        await dispatch(
            sendEvent({
                title: `${EVENT_PREFIX}.${AUDIO_REFERENCE_EVENT_NAME_FRAGMENT}.Redo`,
            }),
        );
    },
);

/**
 * User sets the key of captured audio to something other than the detected key
 */
type SendAudioReferenceListenKeySetEventArgs = {
    musicalKey: MusicalKey;
};
export const sendAudioReferenceListenKeySetEvent =
    createHydraThunk<SendAudioReferenceListenKeySetEventArgs>(
        `${ACTION_PREFIX}/sendAudioReferenceListenKeySetEvent`,
        async (args, { dispatch }) => {
            await dispatch(
                sendEvent({
                    title: `${EVENT_PREFIX}.${AUDIO_REFERENCE_EVENT_NAME_FRAGMENT}.Key.Set`,
                    properties: {
                        audio_reference_key: args.musicalKey,
                    },
                }),
            );
        },
    );

/**
 * User previews captured audio
 */
export const sendAudioReferenceListenPreviewEvent = createHydraThunk(
    `${ACTION_PREFIX}/sendAudioReferenceListenPreviewEvent`,
    async (_, { dispatch }) => {
        await dispatch(
            sendEvent({
                title: `${EVENT_PREFIX}.${AUDIO_REFERENCE_EVENT_NAME_FRAGMENT}.Preview`,
            }),
        );
    },
);

/**
 * Users clicks ‘done’ on audio listen capture
 */
export const sendAudioReferenceListenDoneEvent = (dispatch: HydraDispatch) => {
    void dispatch(
        sendEvent({
            title: `${EVENT_PREFIX}.${AUDIO_REFERENCE_EVENT_NAME_FRAGMENT}.Done`,
        }),
    );
};

/**
 * User clicks 'undo' after session key is auto updated from a detected audio ref key
 */
export const sendAudioReferenceListenSessionKeyUndoEvent = createHydraThunk(
    `${ACTION_PREFIX}/sendAudioReferenceListenSessionKeyUndoEvent`,
    async (_, { dispatch, getState }) => {
        const state = getState() as State;
        const audioReferenceKey = getCapturedAudioKey(state.capture);
        const sessionKey = getMusicalKey(state.preview);

        await dispatch(
            sendEvent({
                title: `${EVENT_PREFIX}.${AUDIO_REFERENCE_EVENT_NAME_FRAGMENT}.SessionKey.Undo`,
                properties: {
                    audio_reference_key: audioReferenceKey
                        ? {
                              root: audioReferenceKey.root,
                              scale: audioReferenceKey.scale,
                          }
                        : null,
                    session_key: {
                        root: sessionKey.root,
                        scale: sessionKey.scale,
                    },
                },
            }),
        );
    },
);
