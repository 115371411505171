import { configureStore } from "@reduxjs/toolkit";

import { rootReducer } from "./rootReducer";
import { hydraServiceApi } from "api/hydraServiceApi/hydraServiceApi";
import { HydraServices } from "services";
import PersistedStateService from "services/PersistedStateService";
import createThunkForAction from "state/thunkFactory";
import { State } from "state/types";

const configureHydraStore = (
    services: HydraServices,
    preloadedState?: Partial<State>,
) => {
    const persistedStateService = new PersistedStateService(services);
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: services,
                },
            })
                .concat(hydraServiceApi.middleware)
                .concat(persistedStateService.middleware),
        preloadedState,
    });

    services.messageService.onMessageReceived = (action) => {
        const thunk = createThunkForAction(action);
        if (thunk !== null) {
            void store.dispatch(thunk);
        }

        store.dispatch(action);
    };

    return store;
};

export type HydraStore = ReturnType<typeof configureHydraStore>;

export default configureHydraStore;
