import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { makeGetIsLoaded, previewSlice } from "features/Preview/previewSlice";
import { PreviewState } from "features/Preview/PreviewState";
import { pressPlayPauseButton } from "features/Preview/thunks";
import PlayPauseButton from "shared/components/PlayPauseButton/PlayPauseButton";
import { Loop } from "shared/types/Loop";
import { useHydraDispatch } from "state/hooks";

const SamplePreviewPlayPauseButton = ({
    activeLoop,
    rowNumber,
    size = "small",
}: {
    readonly activeLoop: Loop | undefined;
    readonly rowNumber?: number;
    readonly size?: "small" | "large";
}) => {
    const getLoaded = useMemo(makeGetIsLoaded, []);
    const dispatch = useHydraDispatch();
    const isLoaded = useSelector<{ preview: PreviewState }, boolean>((state) =>
        getLoaded(state.preview, {
            id: activeLoop ? activeLoop.id : "",
        }),
    );

    const isPlaying =
        useSelector(previewSlice.selectors.getIsPlaying) && isLoaded;
    const clickPlayPause = () => {
        void dispatch(
            pressPlayPauseButton({
                loop: activeLoop,
                isLoaded,
                isPlaying,
                rowNumber,
            }),
        );
    };
    return (
        <PlayPauseButton
            bgOnHoverOnly
            size={size}
            onPlayClick={clickPlayPause}
            onPauseClick={clickPlayPause}
            isPlaying={isPlaying}
        />
    );
};

export default SamplePreviewPlayPauseButton;
