/* eslint-disable camelcase */
import {
    ACTION_PREFIX,
    EVENT_PREFIX,
} from "api/eventsServiceApi/events/constants";
import { sendEvent } from "api/eventsServiceApi/thunks";
import {
    getCapturedAudioKey,
    getUploadId,
} from "features/Capture/captureSelectors";
import { getMusicalKey } from "features/Preview/previewSelectors";
import { getSearchQuery } from "features/SearchPage/searchPageSelectors";
import { State } from "state/types";
import { createHydraThunk } from "state/typeUtil";

/**
 * Search has been executed
 */
type SendSearchExecutedEventArgs = {
    searchId: string;
};
export const sendSearchExecutedEvent =
    createHydraThunk<SendSearchExecutedEventArgs>(
        `${ACTION_PREFIX}/sendSearchExecutedEvent`,
        ({ searchId }, { getState, dispatch }) => {
            const state = getState() as State;
            const uploadId = getUploadId(state.capture);
            const searchTerm = getSearchQuery(state.search);
            const sessionKey = getMusicalKey(state.preview);
            const captureKey = getCapturedAudioKey(state.capture);

            void dispatch(
                sendEvent({
                    title: `${EVENT_PREFIX}.Search.Executed`,
                    properties: {
                        search_id: searchId,
                        audio_upload_id: uploadId,
                        audio_reference: uploadId !== null,
                        search_term: searchTerm,
                        session_key: sessionKey,
                        audio_reference_key: uploadId ? captureKey : null,
                    },
                }),
            );
        },
    );
