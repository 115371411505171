export const debounce = <T extends Array<unknown>>(
    fn: (...args: T) => void,
    delay: number,
) => {
    // Declare a variable called 'timer' to store the timer ID
    let timer: number | undefined;

    // Return an anonymous function that takes in any number of arguments
    return (...args: T) => {
        // Clear the previous timer to prevent the execution of 'fn'
        clearTimeout(timer);

        // Set a new timer that will execute 'fn' after the specified delay
        timer = window.setTimeout(() => {
            fn(...args);
        }, delay);
    };
};
