import * as Messages from "Protocol/Generated/Messages"
import { messageAsAction } from "services/messageService";
export const renderComplete = messageAsAction<Messages.RenderComplete>(Messages.MessageType.RenderComplete);
export const externalFileDragStart = messageAsAction<Messages.ExternalFileDragStart>(Messages.MessageType.ExternalFileDragStart);
export const externalFileDragComplete = messageAsAction<Messages.ExternalFileDragComplete>(Messages.MessageType.ExternalFileDragComplete);
export const activeDownloads = messageAsAction<Messages.ActiveDownloads>(Messages.MessageType.ActiveDownloads);
export const downloadedLoops = messageAsAction<Messages.DownloadedLoops>(Messages.MessageType.DownloadedLoops);
export const loopDownloadSuccess = messageAsAction<Messages.LoopDownloadSuccess>(Messages.MessageType.LoopDownloadSuccess);
export const authTokenUpdated = messageAsAction<Messages.AuthTokenUpdated>(Messages.MessageType.AuthTokenUpdated);
export const loginSuccess = messageAsAction<Messages.LoginSuccess>(Messages.MessageType.LoginSuccess);
export const loginFailure = messageAsAction<Messages.LoginFailure>(Messages.MessageType.LoginFailure);
export const refreshFailure = messageAsAction<Messages.RefreshFailure>(Messages.MessageType.RefreshFailure);
export const bpm = messageAsAction<Messages.Bpm>(Messages.MessageType.Bpm);
export const loadedSample = messageAsAction<Messages.LoadedSample>(Messages.MessageType.LoadedSample);
export const audioCaptureProgress = messageAsAction<Messages.AudioCaptureProgress>(Messages.MessageType.AudioCaptureProgress);
export const audioCaptureRms = messageAsAction<Messages.AudioCaptureRms>(Messages.MessageType.AudioCaptureRms);
export const audioCaptureSilenceWarning = messageAsAction<Messages.AudioCaptureSilenceWarning>(Messages.MessageType.AudioCaptureSilenceWarning);
export const audioCaptureKey = messageAsAction<Messages.AudioCaptureKey>(Messages.MessageType.AudioCaptureKey);
export const audioCaptureWaveform = messageAsAction<Messages.AudioCaptureWaveform>(Messages.MessageType.AudioCaptureWaveform);
export const audioCapturePlaybackProgress = messageAsAction<Messages.AudioCapturePlaybackProgress>(Messages.MessageType.AudioCapturePlaybackProgress);
export const hostStoppedProcessingAudio = messageAsAction<Messages.HostStoppedProcessingAudio>(Messages.MessageType.HostStoppedProcessingAudio);
export const audioUploadIdChanged = messageAsAction<Messages.AudioUploadIdChanged>(Messages.MessageType.AudioUploadIdChanged);
export const networkConfig = messageAsAction<Messages.NetworkConfig>(Messages.MessageType.NetworkConfig);
export const sessionInfo = messageAsAction<Messages.SessionInfo>(Messages.MessageType.SessionInfo);
export const handshake = messageAsAction<Messages.Handshake>(Messages.MessageType.Handshake);
export const playState = messageAsAction<Messages.PlayState>(Messages.MessageType.PlayState);
export const gain = messageAsAction<Messages.Gain>(Messages.MessageType.Gain);
export const playPosition = messageAsAction<Messages.PlayPosition>(Messages.MessageType.PlayPosition);
export const key = messageAsAction<Messages.Key>(Messages.MessageType.Key);
export const bpmAndKeyLock = messageAsAction<Messages.BpmAndKeyLock>(Messages.MessageType.BpmAndKeyLock);
export const captureAudio = messageAsAction<Messages.CaptureAudio>(Messages.MessageType.CaptureAudio);
export const stopAudioCapture = messageAsAction<Messages.StopAudioCapture>(Messages.MessageType.StopAudioCapture);
export const audioCapturePlayState = messageAsAction<Messages.AudioCapturePlayState>(Messages.MessageType.AudioCapturePlayState);
export const persistedState = messageAsAction<Messages.PersistedState>(Messages.MessageType.PersistedState);