export type DownloadState = "notDownloaded" | "downloaded" | "inProgress";

export const getButtonState = (
    isDownloaded: boolean,
    downloading: boolean,
): DownloadState => {
    if (isDownloaded) {
        return "downloaded";
    } else if (downloading) {
        return "inProgress";
    }
    return "notDownloaded";
};
