import { JssStyle, Styles } from "jss";

export const flexCenterChildren: JssStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export const sharedTableStyles: Styles<"table"> = {
    table: {
        display: "grid",
        gridTemplateColumns: "48px 68px 1fr 40px ",
        width: "100%",
        padding: "0 16px",
        overflow: "hidden",
    },
};
