import React, { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import CaptureCancelButton from "features/Capture/CaptureCancelButton";
import CaptureIdle from "features/Capture/CaptureIdle";
import { captureSlice } from "features/Capture/captureSlice";
import CaptureTitle from "features/Capture/CaptureTitle";
import FinalConfirmation from "features/Capture/Confirmation/FinalConfirmation";
import KeyConfirmation from "features/Capture/Confirmation/KeyConfirmation";
import { captureProgressPercentageToCurrentBarNumber } from "features/Capture/util";
import { Visualizer } from "features/Capture/Visualizer/Visualizer";
import {
    SIDE_PANEL_MODULE_BORDER_RADIUS_PX,
    sidepanelModuleStyles,
} from "shared/styles/sidepanel.styles";
import { labelStyle } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    captureContainer: {
        ...sidepanelModuleStyles,
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    captureInner: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "stretch",
        flex: "1 0 0",
    },
    label: labelStyle,
    visualizer: ({
        shouldShowVisualizer,
    }: {
        shouldShowVisualizer: boolean;
    }) => ({
        opacity: shouldShowVisualizer ? 1 : 0,
        zIndex: shouldShowVisualizer ? 2 : 1,
        transition: [["opacity 200ms linear"]],
        position: "absolute",
        top: "0",

        "& canvas": {
            borderRadius: SIDE_PANEL_MODULE_BORDER_RADIUS_PX,
        },
    }),
});
const AUDIO_CAPTURE_WIDTH = 300;
const AUDIO_CAPTURE_HEIGHT = 236;

const getCopy = ({
    captureInitiated,
    isCapturing,
    capturePercent,
    isConfirming,
}: {
    captureInitiated: boolean;
    isCapturing: boolean;
    capturePercent: number;
    isConfirming: boolean;
}) => {
    if (isConfirming) {
        return "Confirm the detected key";
    }

    if (captureInitiated) {
        return "Play track to capture audio";
    }

    if (isCapturing) {
        return `Capturing bar ${captureProgressPercentageToCurrentBarNumber(capturePercent)}...`;
    }

    return "";
};

const AudioCapture = () => {
    const isCapturing = useSelector(captureSlice.selectors.getIsCapturing);
    const captureInitiated = useSelector(
        captureSlice.selectors.getCaptureInitiated,
    );
    const rmsValue = useSelector(captureSlice.selectors.getRmsValue);
    const capturePercent = useSelector(
        captureSlice.selectors.getCapturePercent,
    );
    const shouldShowVisualizer = isCapturing || captureInitiated;
    const styles = useStyles({ shouldShowVisualizer });

    const isConfirming = useSelector(captureSlice.selectors.getIsConfirming);
    const isIdle = useSelector(captureSlice.selectors.getIsIdle);
    const captureKey = useSelector(captureSlice.selectors.getKey);
    const hasConfirmed = useSelector(captureSlice.selectors.getHasConfirmed);

    const visualizer = useRef(
        new Visualizer(AUDIO_CAPTURE_WIDTH, AUDIO_CAPTURE_HEIGHT),
    );

    useEffect(() => {
        visualizer.current.updateRmsValue(rmsValue);
        visualizer.current.updateCaptureProgress(capturePercent);
    }, [rmsValue, capturePercent]);

    useEffect(() => {
        if (shouldShowVisualizer) {
            visualizer.current.start();
        } else {
            visualizer.current.stop();
        }
    }, [shouldShowVisualizer]);

    const titleText = getCopy({
        captureInitiated,
        isConfirming,
        capturePercent,
        isCapturing,
    });

    const shouldShowKeyConfirmationElement = isConfirming && captureKey;
    const shouldShowFinalConfirmationElement = hasConfirmed && captureKey;
    const shouldShowIdleElement =
        isIdle &&
        !(
            shouldShowKeyConfirmationElement ||
            shouldShowFinalConfirmationElement
        );

    const titleElement = titleText ? (
        <CaptureTitle
            text={titleText}
            visualizerActive={shouldShowVisualizer}
        />
    ) : null;

    const keyConfirmationElement = shouldShowKeyConfirmationElement ? (
        <KeyConfirmation captureKey={captureKey} />
    ) : null;

    const finalConfirmationElement = shouldShowFinalConfirmationElement ? (
        <FinalConfirmation capturedAudioKey={captureKey} />
    ) : null;

    const cancelCaptureElement = shouldShowVisualizer ? (
        <CaptureCancelButton />
    ) : null;

    return (
        <div className={styles.captureContainer}>
            {shouldShowIdleElement ? <CaptureIdle /> : null}
            {finalConfirmationElement}
            <div className={styles.captureInner}>
                <div
                    id="visualizer-mount-point"
                    className={styles.visualizer}
                />
                {cancelCaptureElement}
                {titleElement}
                {keyConfirmationElement}
            </div>
        </div>
    );
};
export default AudioCapture;
