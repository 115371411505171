import React, { ChangeEvent, useCallback } from "react";

import { loginPageSlice } from "../loginPageSlice";
import { useHydraDispatch } from "state/hooks";

type PasswordInputProps = {
    readonly value: string;
};

const PasswordInput = ({ value }: PasswordInputProps) => {
    const dispatch = useHydraDispatch();
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) =>
            dispatch(
                loginPageSlice.actions.updatePassword(e.currentTarget.value),
            ),
        [],
    );
    return (
        <div>
            <p>Password</p>
            <input
                data-testid="password-input"
                onChange={handleChange}
                value={value}
                type="password"
            />
        </div>
    );
};

export default PasswordInput;
