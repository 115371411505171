import { createAction, PayloadAction } from "@reduxjs/toolkit";

import { Messages, MessageType } from "Protocol/Generated/Messages";

export type MessagesUnion = Messages[keyof Messages];
type PostMessageFunction = (msg: MessagesUnion) => void;
type ReceiveMessageFunction = (msg: unknown) => void;

type WebkitMessageSender = {
    messageHandlers: {
        sendMessage: {
            postMessage: ReceiveMessageFunction;
        };
    };
};

type ChromeMessageSender = {
    webview: {
        postMessage: ReceiveMessageFunction;
    };
};

export interface HydraWindow extends Window {
    receiveMessage: ReceiveMessageFunction;
    webkit?: WebkitMessageSender;
    chrome?: ChromeMessageSender;
}

export type MessageAction = PayloadAction<MessagesUnion>;
export type MessageHandler = (action: MessageAction) => void;

const messagePrefix = "fromcpp";

export interface MessageServiceInterface {
    sendMessage: PostMessageFunction;
    onMessageReceived: MessageHandler | undefined;
    currWindow: HydraWindow;
    handshakeComplete: boolean;
    handshakeInterval: ReturnType<typeof window.setInterval> | null;
}

export const handshakeMessage = {
    type: MessageType.Handshake,
    // eslint-disable-next-line no-warning-comments
    requiredMajorVersion: 0, // TODO: use actual required version number
};
export const HANDSHAKE_TIMER_DURATION = 100;

export default class MessageService implements MessageServiceInterface {
    sendMessage: PostMessageFunction;
    onMessageReceived: MessageHandler | undefined = undefined;
    handshakeComplete = false;
    handshakeInterval: ReturnType<typeof window.setInterval> | null = null;
    currWindow = window as unknown as HydraWindow;

    constructor() {
        if (this.currWindow.webkit?.messageHandlers.sendMessage.postMessage) {
            this.sendMessage = (msg: MessagesUnion) => {
                this.currWindow.webkit?.messageHandlers.sendMessage.postMessage(
                    JSON.stringify(msg),
                );
            };
        } else if (this.currWindow.chrome?.webview.postMessage) {
            this.sendMessage = (msg: MessagesUnion) => {
                this.currWindow.chrome?.webview.postMessage(
                    JSON.stringify(msg),
                );
            };
        } else {
            // Raise error
            throw new Error("No sendMessage function found");
        }

        this.currWindow.receiveMessage = (msg: unknown) => {
            if (msg) {
                const parsedMsg = msg as MessagesUnion;

                if (parsedMsg.type === MessageType.Handshake) {
                    this.handshakeComplete = true;
                    if (this.handshakeInterval !== null) {
                        clearInterval(this.handshakeInterval);
                    }
                }

                this.onMessageReceived?.({
                    type: `${messagePrefix}/${parsedMsg.type}`,
                    payload: parsedMsg,
                });
            } else {
                /**
                 * @todo report this to sentry
                 */
                // eslint-disable-next-line no-console
                console.error("Unable to parse message", { msg });
            }
        };

        // Start timer interval to attempt handshakes
        this.handshakeInterval = setInterval(() => {
            if (this.handshakeComplete) {
                return;
            }

            this.sendMessage(handshakeMessage);
        }, HANDSHAKE_TIMER_DURATION);
    }
}

export const messageAsAction = <Message extends MessagesUnion>(
    msgType: MessageType,
) => createAction<Message>(`${messagePrefix}/${msgType}`);
