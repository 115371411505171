import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { downloadSlice, DownloadState } from "features/Download/downloadSlice";
import { getButtonState } from "features/Download/util";
import { previewSlice } from "features/Preview/previewSlice";
import DownloadStateComponent from "features/SearchPage/LoopRow/LoopRowInner/DownloadStateComponent";
import Waveform from "features/SearchPage/LoopRow/Waveform/Waveform";
import SamplePreviewPlayPauseButton from "shared/components/PlayPauseButton/SamplePreviewPlayPauseButton";
import {
    flexCenterChildren,
    sharedTableStyles,
} from "shared/styles/layout.styles";
import { labelStyle } from "shared/styles/typography.styles";
import { Loop } from "shared/types/Loop";

const useStyles = createUseStyles({
    ...sharedTableStyles,
    cell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "initial",
    },
    downloadStateCell: flexCenterChildren,
    nameCell: {
        "& p": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontSize: "14px",
            whiteSpace: "nowrap",
        },
    },
    lengthCell: labelStyle,
    keyOrBpmCell: ({ keyBpmLock }: { keyBpmLock: boolean }) => ({
        ...labelStyle,
        color: keyBpmLock ? "#4A4A4A" : "#8F8F8F",
        transition: "color 0.2s",
    }),
});

type LoopRowInnerProps = {
    readonly loop: Loop;
    readonly rowNumber: number;
    readonly isHovered: boolean;
};
const LoopRowInner = ({ loop, rowNumber, isHovered }: LoopRowInnerProps) => {
    const keyBpmLock = useSelector(previewSlice.selectors.getIsSessionLocked);
    const isLoopDownloading = useSelector<{ download: DownloadState }, boolean>(
        (state) => downloadSlice.selectors.getIsLoopDownloading(state, loop.id),
    );
    const isLoopDownloaded = useSelector<{ download: DownloadState }, boolean>(
        (state) => downloadSlice.selectors.getIsLoopDownloaded(state, loop.id),
    );

    const downloadState = getButtonState(isLoopDownloaded, isLoopDownloading);

    const styles = useStyles({ keyBpmLock });

    return (
        <div className={styles.table}>
            <div className={styles.cell}>
                <SamplePreviewPlayPauseButton
                    activeLoop={loop}
                    rowNumber={rowNumber}
                />
            </div>
            <div className={styles.cell}>
                <Waveform data={loop.waveform_data} width={44} height={28} />
            </div>
            <div className={`${styles.nameCell} ${styles.cell}`}>
                <p>{loop.name}</p>
            </div>
            <div className={styles.downloadStateCell}>
                <DownloadStateComponent
                    downloadState={downloadState}
                    loop={loop}
                    rowNumber={rowNumber}
                    isHovered={isHovered}
                />
            </div>
        </div>
    );
};

export default LoopRowInner;
