import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import TonalitySelector from "./TonalitySelector";
import MusicalKeyGrid from "features/Preview/MusicalKeySelector/MusicalKeyGrid";
import {
    MusicalKey,
    MusicalRootNote,
    MusicalScale,
} from "Protocol/Generated/Messages";
import TwoTextLineButton from "shared/components/Buttons/TwoTextLineButton";
import Divider from "shared/components/Divider";
import Dropdown from "shared/components/Dropdown/Dropdown";
import { gray10 } from "shared/constants/color";
import { formatKey, getRelativeKey } from "shared/utils/musicalKeyUtil";

const useStyles = createUseStyles({
    musicalKeySelectorContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "16px",
        gap: "16px",
    },
});

type MusicalKeySelectorProps = {
    readonly activeKey: MusicalKey;
    readonly alignment?: "left" | "right";
    readonly onKeyUpdated: (musicalKey: MusicalKey) => void;
    readonly secondaryLabel?: string;
    readonly noKeyButtonMainText: string;
    readonly noKeyButtonSecondaryText: string;
    readonly noKeyText: string;
    readonly width?: string;
    readonly forceClose?: boolean;
    readonly onOpen?: () => void;
    readonly onClose?: () => void;
};

const MusicalKeySelector = ({
    activeKey,
    onKeyUpdated,
    secondaryLabel,
    alignment,
    noKeyText,
    noKeyButtonMainText,
    noKeyButtonSecondaryText,
    width = "112px",
    forceClose,
    onOpen,
    onClose,
}: MusicalKeySelectorProps) => {
    const isNoKey = activeKey.root === MusicalRootNote.None;
    const styles = useStyles();

    /*
     * We need to keep track of the tonality selector state when the key goes to None.
     * The tonality selector should remember the last selected tonality when the key is None.
     * It also needs to be toggleable while the key is None.
     */
    const [tonalitySelectorNoneState, setTonalitySelectorNoneState] = useState<
        MusicalScale.Major | MusicalScale.Minor
    >(MusicalScale.Major);

    const tonalitySelectorScale =
        activeKey.scale === MusicalScale.None
            ? tonalitySelectorNoneState
            : activeKey.scale;

    const onTonalityChange = (
        newTonality: MusicalScale.Major | MusicalScale.Minor,
    ) => {
        setTonalitySelectorNoneState(newTonality);
        onKeyUpdated(getRelativeKey(activeKey));
    };

    const onBottomButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (activeKey.scale !== MusicalScale.None) {
            setTonalitySelectorNoneState(activeKey.scale);
        }

        onKeyUpdated({
            root: MusicalRootNote.None,
            scale: MusicalScale.None,
        });
    };

    return (
        <Dropdown
            title={isNoKey ? noKeyText : formatKey(activeKey)}
            secondaryLabel={secondaryLabel}
            alignment={alignment}
            label="Key"
            width={width}
            forceClose={forceClose}
            onOpen={onOpen}
            onClose={onClose}
        >
            <div className={styles.musicalKeySelectorContainer}>
                <TonalitySelector
                    onChange={onTonalityChange}
                    scale={tonalitySelectorScale}
                />
                <MusicalKeyGrid
                    selectedKey={activeKey}
                    onMusicalKeyClick={onKeyUpdated}
                    tonality={tonalitySelectorScale}
                />
                <Divider color={gray10} direction="horizontal" />
                <TwoTextLineButton
                    isActive={isNoKey}
                    onClick={onBottomButtonClick}
                    mainText={noKeyButtonMainText}
                    secondaryText={noKeyButtonSecondaryText}
                />
            </div>
        </Dropdown>
    );
};

export default MusicalKeySelector;
