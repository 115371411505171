import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles<
    "container",
    { width: number; offset: number }
>({
    container: ({ width, offset }) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "2em",
        gridColumnStart: offset,
        gridColumnEnd: `span ${width}`,
    }),
});

/**
 * An individual member of a grid layout. Used in on its own or
 * alongside other <Column /> elements in a <Grid /> component.
 *
 * @param children - The content to render in the column
 * @param width - The number of columns to span
 * @param offset - The number of columns to offset the column by
 */
const Column = ({
    children,
    width = 1,
    offset = 0,
}: {
    readonly children?: React.ReactNode;
    readonly width?: number;
    readonly offset?: number;
}) => {
    const styles = useStyles({ width, offset });
    return <div className={styles.container}>{children}</div>;
};

export default Column;
