import { createAction, createSlice } from "@reduxjs/toolkit";

import { LoopById } from "features/SearchPage/SearchState";
import {
    MessageType,
    MusicalKey,
    PersistedState,
} from "Protocol/Generated/Messages";
import { messageAsAction } from "services/messageService";

export type PersistedStateSliceState = {
    persistedStateReceived: boolean;
};

const initialState: PersistedStateSliceState = {
    persistedStateReceived: false,
};

const persistedStateFromCPP = messageAsAction<PersistedState>(
    MessageType.PersistedState,
);

export type ParsedPersistedState = {
    searchQuery?: string;
    searchResultById?: LoopById;
    searchId?: string | null;
    scrollPosition?: number;
    uploadId?: string | null;
    hasConfirmed?: boolean;
    detectedKey?: MusicalKey | null;
    userKeyOverride?: MusicalKey | null;
    captureInitiated?: boolean;
};
export const parsedPersistedStateReceived = createAction<ParsedPersistedState>(
    "persistedState/parsedPersistedStateReceived",
);

export const persistedStateSlice = createSlice({
    name: "persistedState",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(persistedStateFromCPP, (state) => {
            if (!state.persistedStateReceived) {
                state.persistedStateReceived = true;
            }
        });
    },
    selectors: {
        getPersistedStateReceived: (state) => state.persistedStateReceived,
    },
});
