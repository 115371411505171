import React from "react";
import { createUseStyles } from "react-jss";

import Button from "shared/components/Buttons/Button";
import {
    black0Tint45,
    cyan,
    gray0,
    gray10,
    gray100,
    gray45,
} from "shared/constants/color";
import { labelStyle } from "shared/styles/typography.styles";

type StyleProps = {
    readonly isActive: boolean;
};

const useStyles = createUseStyles({
    twoLineTextButtonContainer: ({ isActive }: StyleProps) => ({
        backgroundColor: isActive ? cyan : gray10,
        width: "216px",
        height: "48px",
        borderRadius: "8px",
        flexDirection: "column",
    }),
    noKeyMainText: ({ isActive }: StyleProps) => ({
        ...labelStyle,
        fontWeight: 700,
        color: isActive ? gray0 : gray100,
    }),
    noKeySecondaryText: ({ isActive }: StyleProps) => ({
        ...labelStyle,
        fontSize: "10px",
        lineHeight: "normal",
        color: isActive ? black0Tint45 : gray45,
    }),
});

type NoKeyButtonProps = {
    readonly mainText: string;
    readonly secondaryText: string;
    readonly onClick: (e: React.MouseEvent) => void;
    readonly isActive: boolean;
};

const TwoTextLineButton = ({
    mainText,
    secondaryText,
    onClick,
    isActive,
}: NoKeyButtonProps) => {
    const styles = useStyles({ isActive });
    return (
        <Button
            additionalClasses={styles.twoLineTextButtonContainer}
            onClick={onClick}
        >
            <p className={styles.noKeyMainText}>{mainText}</p>
            <p className={styles.noKeySecondaryText}>{secondaryText}</p>
        </Button>
    );
};

export default TwoTextLineButton;
