import React from "react";
import { createUseStyles } from "react-jss";

import { fontSize3 } from "shared/styles/typography.styles";

type CaptureTitleProps = {
    readonly text: string;
    readonly visualizerActive: boolean;
};

const useStyles = createUseStyles({
    captureTitle: fontSize3,
    title: {
        paddingBottom: "8px",
        fontSize: "15px",
        lineHeight: "24px",
        position: "absolute",
        top: "30px",
    },
});

const CaptureTitle = ({ text, visualizerActive }: CaptureTitleProps) => {
    const styles = useStyles();
    const className = visualizerActive ? styles.title : styles.captureTitle;
    return <p className={className}>{text}</p>;
};

export default CaptureTitle;
