import { sendAudioReferenceListenSuccessEvent } from "api/eventsServiceApi/events/captureEvents";
import { sendSampleDragEvent } from "api/eventsServiceApi/events/loopEvents";
import { audioUploadIdChangedFromCpp } from "features/Capture/thunks";
import { userLoggedIn } from "features/LoginPage/loginPageSlice";
import {
    ParsedPersistedState,
    parsedPersistedStateReceived,
} from "features/PersistedState/persistedStateSlice";
import {
    dragRenderCompleted,
    loopDownloadCompleted,
} from "features/SearchPage/thunks";
import {
    audioCaptureProgress,
    audioUploadIdChanged,
    externalFileDragComplete,
    loginSuccess,
    loopDownloadSuccess,
    persistedState as persistedStateFromCpp,
    renderComplete,
} from "Protocol/Generated/MessageHelpers";
import {
    AudioCaptureProgress,
    AudioUploadIdChanged,
    ExternalFileDragComplete,
    LoginSuccess,
    LoopDownloadSuccess,
    PersistedState,
    RenderComplete,
} from "Protocol/Generated/Messages";
import { MessageAction } from "services/messageService";

const createThunkForAction = (action: MessageAction) => {
    switch (action.type) {
        case audioUploadIdChanged.type:
            return audioUploadIdChangedFromCpp(
                action.payload as AudioUploadIdChanged,
            );
        case loginSuccess.type:
            return userLoggedIn(action.payload as LoginSuccess);

        /**
         * @todo Debounce this event before implementing
         *   case bpm.type:
         *     return sendSessionBPMChangeEvent({});
         */

        case externalFileDragComplete.type:
            return sendSampleDragEvent(
                action.payload as ExternalFileDragComplete,
            );
        case audioCaptureProgress.type: {
            const { percent } = action.payload as AudioCaptureProgress;
            if (percent === 1) {
                return sendAudioReferenceListenSuccessEvent({});
            }
            return null;
        }
        case persistedStateFromCpp.type: {
            const { persistedState } = action.payload as PersistedState;
            if (persistedState.length === 0) {
                return null;
            }

            const parsedPersistedState = JSON.parse(
                persistedState,
            ) as ParsedPersistedState;
            return parsedPersistedStateReceived(parsedPersistedState);
        }
        case renderComplete.type: {
            return dragRenderCompleted(action.payload as RenderComplete);
        }
        case loopDownloadSuccess.type: {
            return loopDownloadCompleted(action.payload as LoopDownloadSuccess);
        }
        default:
            return null;
    }
};

export default createThunkForAction;
