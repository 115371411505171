import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import configureHydraServices from "./services";
import configureHydraStore, { HydraStore } from "./state/store";

const container = document.getElementById("root");
if (container !== null) {
    const root = createRoot(container);
    const services = configureHydraServices();
    const store: HydraStore = configureHydraStore(services);
    root.render(
        <Provider store={store}>
            <App />
        </Provider>,
    );
}
