import { createSelector, Middleware } from "@reduxjs/toolkit";

import { captureSlice } from "features/Capture/captureSlice";
import { persistedStateSlice } from "features/PersistedState/persistedStateSlice";
import { searchSlice } from "features/SearchPage/searchPageSlice";
import { LoopById } from "features/SearchPage/SearchState";
import { MessageType, MusicalKey } from "Protocol/Generated/Messages";
import { HydraServices } from "services/index";
import { State } from "state/types";

export interface PersistedStateServiceInterface {
    middleware: Middleware;
}
type PersistedState = {
    searchQuery: string;
    searchResultById: LoopById;
    searchId: string | null;
    scrollPosition: number;
    captureInitiated: boolean;
    uploadId: string | null;
    hasConfirmed: boolean;
    detectedKey: MusicalKey | null;
    userKeyOverride: MusicalKey | null;
};
const persistedStateSelector = createSelector(
    [
        searchSlice.selectors.getSearchQuery,
        searchSlice.selectors.getSearchResultsById,
        searchSlice.selectors.getSearchId,
        searchSlice.selectors.getScrollPosition,
        captureSlice.selectors.getUploadId,
        captureSlice.selectors.getHasConfirmed,
        captureSlice.selectors.getDetectedKey,
        captureSlice.selectors.getUserKeyOverride,
        captureSlice.selectors.getCaptureInitiated,
    ],
    (
        searchQuery,
        searchResultById,
        searchId,
        scrollPosition,
        uploadId,
        hasConfirmed,
        detectedKey,
        userKeyOverride,
        captureInitiated,
        // eslint-disable-next-line max-params
    ) =>
        ({
            searchQuery,
            searchResultById,
            searchId,
            scrollPosition,
            uploadId,
            hasConfirmed,
            detectedKey,
            userKeyOverride,
            captureInitiated,
        }) as PersistedState,
);
export default class PersistedStateService
    implements PersistedStateServiceInterface
{
    services: HydraServices;
    lastStateUpdateValue: PersistedState | null = null;
    middleware: Middleware;

    constructor(services: HydraServices) {
        this.services = services;
        this.middleware = (store) => (next) => (action) => {
            const nextActionResult = next(action);

            const state = store.getState() as State;
            const stateToPersist = persistedStateSelector(state);
            const persistedStateReceived =
                persistedStateSlice.selectors.getPersistedStateReceived(state);
            if (stateToPersist !== this.lastStateUpdateValue) {
                if (persistedStateReceived) {
                    this.services.messageService.sendMessage({
                        type: MessageType.PersistedState,
                        persistedState: JSON.stringify(stateToPersist),
                    });
                    this.lastStateUpdateValue = stateToPersist;
                }
            }

            return nextActionResult;
        };
    }
}
