import React from "react";
import { createUseStyles } from "react-jss";

import CapturePlaybackButton from "features/Capture/CapturePlaybackButton";
import CaptureWaveform from "features/Capture/Waveform/CaptureWaveform";

const useStyles = createUseStyles({
    captureWaveformSmallContainer: {
        position: "relative",
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    playPauseButton: {
        position: "absolute",
        zIndex: 2,
        marginLeft: "7px",
    },
});

const CaptureWaveformSmall = () => {
    const styles = useStyles();
    return (
        <div className={styles.captureWaveformSmallContainer}>
            <div className={styles.playPauseButton}>
                <CapturePlaybackButton bgBlur />
            </div>
            <CaptureWaveform waveformHeight={13} containerHeight={44} />
        </div>
    );
};

export default CaptureWaveformSmall;
