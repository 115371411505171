/* eslint-disable no-magic-numbers */
import { RGB } from "shared/types/RGB";

type Coordinate = { x: number; y: number };
export const VISUALIZER_COLORS: RGB[] = [
    { r: 10, g: 235, b: 194 },
    { r: 74, g: 62, b: 248 },
    { r: 1, g: 1, b: 1 },
];

export const DEVICE_PIXEL_RATIO = window.devicePixelRatio;

export const addBezierCurveToContext = (
    context: CanvasRenderingContext2D,
    startPoint: Coordinate,
    controlPoints: Coordinate[],
) => {
    context.moveTo(startPoint.x, startPoint.y);

    context.bezierCurveTo(
        controlPoints[0].x,
        controlPoints[0].y,
        controlPoints[1].x,
        controlPoints[1].y,
        controlPoints[2].x,
        controlPoints[2].y,
    );

    context.bezierCurveTo(
        controlPoints[3].x,
        controlPoints[3].y,
        controlPoints[4].x,
        controlPoints[4].y,
        controlPoints[5].x,
        controlPoints[5].y,
    );
};

/**
 * Creates an HTML Canvas element using the provided width and height,
 * scales the Canvas dimensions based on the user's device pixel ratio,
 * and returns both the Canvas, and it's associated Context.
 */
export const createCanvas = (
    width: number,
    height: number,
): { canvas: HTMLCanvasElement; context: CanvasRenderingContext2D } => {
    const canvas = document.createElement("canvas");

    canvas.width = width * DEVICE_PIXEL_RATIO;
    canvas.height = height * DEVICE_PIXEL_RATIO;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    canvas.style.transformOrigin = "top left";

    const context = canvas.getContext("2d");
    if (context === null) {
        throw Error("canvas context is null!");
    }
    context.scale(DEVICE_PIXEL_RATIO, DEVICE_PIXEL_RATIO);

    return { canvas, context };
};

const MIN_DECIBEL = -100.0;
const gainToDecibel = (gain: number): number => {
    if (gain > 0.0) {
        return Math.max(MIN_DECIBEL, 20.0 * Math.log10(gain));
    }
    return MIN_DECIBEL;
};

const CONTROL_POINT_OFFSET_SCALING_VALUE = 13 / DEVICE_PIXEL_RATIO;
export const gainToScaledControlPointOffset = (gain: number): number => {
    const decibelValue = gainToDecibel(gain);
    // Scale to value between 0 and 1
    const constrainedValue = (decibelValue + Math.abs(MIN_DECIBEL)) * 0.1;
    return constrainedValue * CONTROL_POINT_OFFSET_SCALING_VALUE;
};
/* eslint-enable no-magic-numbers */
