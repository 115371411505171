import React from "react";
import { createUseStyles } from "react-jss";

type GridProps = {
    readonly children: React.ReactNode;
};

const useStyles = createUseStyles({
    container: {
        display: "grid",
        columnGap: "16px",
        width: "100%",
        gridTemplateColumns: "repeat(12, 1fr)",
    },
});

/**
 * A 12 column grid layout component
 *
 * @param children - The <Column /> elements to render in the grid
 */
const Grid = ({ children }: GridProps) => {
    const styles = useStyles();
    return <div className={styles.container}>{children}</div>;
};

export default Grid;
