import { createSelector, createSlice } from "@reduxjs/toolkit";

import {
    getBpm,
    getIsOriginalKey,
    getKeyLock,
    getMusicalKey,
} from "./previewSelectors";
import { PreviewState } from "./PreviewState";
import {
    revertToPreviousKey,
    sendPreviewMessage,
    updatePreviewKey,
} from "./thunks";
import {
    bpm,
    bpmAndKeyLock,
    key,
    loadedSample,
    playPosition,
    playState,
} from "Protocol/Generated/MessageHelpers";
import {
    MusicalKey,
    MusicalRootNote,
    MusicalScale,
} from "Protocol/Generated/Messages";
import { getIdFromArgs } from "shared/Selectors";

export const initialBpm = 120;
const initialMusicalKey: MusicalKey = {
    root: MusicalRootNote.C,
    scale: MusicalScale.Major,
};
export const initialPreviewState: PreviewState = {
    activePreviewId: null,
    progress: 0,
    isPlaying: false,
    bpm: initialBpm,
    musicalKey: initialMusicalKey,
    previousMusicalKey: null,
    bpmLock: true,
    musicalKeyAutoUpdatedModalOpen: false,
    keyLock: true,
};

const getActivePreviewId = (state: PreviewState): string | null =>
    state.activePreviewId;

export const makeGetIsLoaded = () =>
    createSelector(
        [getActivePreviewId, getIdFromArgs],
        (activePreviewId, id) => activePreviewId === id,
    );

export const previewSlice = createSlice({
    name: "preview",
    initialState: initialPreviewState,
    reducers: {
        closeMusicalKeyAutoUpdatedModal: (state) => {
            state.musicalKeyAutoUpdatedModalOpen = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updatePreviewKey.fulfilled, (state, action) => {
            if (action.meta.arg.showKeyChangeModal) {
                state.musicalKeyAutoUpdatedModalOpen = true;
            }
        });
        builder.addCase(bpmAndKeyLock, (state, action) => {
            state.bpmLock = action.payload.bpmLock;
            state.keyLock = action.payload.keyLock;
        });
        builder.addCase(key, (state, action) => {
            state.previousMusicalKey = state.musicalKey;
            state.musicalKey = action.payload.value;
        });
        builder.addCase(loadedSample, (state, action) => {
            state.activePreviewId = action.payload.id;
        });
        builder.addCase(sendPreviewMessage.rejected, (state) => {
            state.activePreviewId = null;
        });
        builder.addCase(playState, (state, action) => {
            state.isPlaying = action.payload.shouldPlay;
        });
        builder.addCase(bpm, (state, action) => {
            if (action.payload.value !== null) {
                //TD - be able to handle null bpm state.
                state.bpm = action.payload.value;
            }
        });
        builder.addCase(playPosition, (state, action) => {
            state.progress = action.payload.position;
        });
        builder.addCase(revertToPreviousKey.fulfilled, (state) => {
            state.musicalKeyAutoUpdatedModalOpen = false;
        });
    },
    selectors: {
        getActivePreviewId,
        getIsPlaying: (state): boolean => state.isPlaying,
        getPlayerProgress: (state): number => state.progress,
        getBpm,
        getIsSessionLocked: (state): boolean => state.bpmLock && state.keyLock,
        getBpmLock: (state): boolean => state.bpmLock,
        getKeyLock,
        getIsOriginalKey,
        getMusicalKey,
        getPreviousKey: (state): MusicalKey | null => state.previousMusicalKey,
        getShowMusicalKeyAutoUpdatedModal: (state): boolean =>
            state.musicalKeyAutoUpdatedModalOpen,
    },
});
