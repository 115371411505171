import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import CaptureWaveformSmall from "../Waveform/CaptureWaveformSmall";
import { sendAudioReferenceListenKeySetEvent } from "api/eventsServiceApi/events/captureEvents";
import { captureSlice } from "features/Capture/captureSlice";
import MusicalKeySelector from "features/Preview/MusicalKeySelector/MusicalKeySelector";
import { MusicalKey } from "Protocol/Generated/Messages";
import { useHydraDispatch } from "state/hooks";

const noKeyText = "No Key/ Atonal";

const useStyles = createUseStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        padding: "0px 24px",
        width: "100%",
    },
});

type KeyConfirmationInnerProps = {
    readonly captureKey: MusicalKey;
};

const KeyConfirmationInner = ({ captureKey }: KeyConfirmationInnerProps) => {
    const dispatch = useHydraDispatch();
    const styles = useStyles();
    const keyIsEstimate = !useSelector(
        captureSlice.selectors.getUserOverrodeKey,
    );

    const onMusicalKeyClick = (newKey: MusicalKey) => {
        void dispatch(
            sendAudioReferenceListenKeySetEvent({ musicalKey: newKey }),
        );
        dispatch(captureSlice.actions.userSelectKey(newKey));
    };

    return (
        <div className={styles.container}>
            <CaptureWaveformSmall />
            <MusicalKeySelector
                activeKey={captureKey}
                onKeyUpdated={onMusicalKeyClick}
                secondaryLabel={keyIsEstimate ? "(Estimate)" : undefined}
                noKeyText={noKeyText}
                noKeyButtonMainText={noKeyText}
                noKeyButtonSecondaryText="This audio does not have a key (ie: drums)"
                width="100%"
                alignment="left"
            />
        </div>
    );
};

export default KeyConfirmationInner;
