import MessageService, { MessageServiceInterface } from "./messageService";
import MockMessageService from "services/__mocks__/mockMessageService";

export type HydraServices = {
    messageService: MessageServiceInterface;
};

const configureHydraServices = (): HydraServices => {
    const messageService =
        process.env.NODE_ENV === "test"
            ? new MockMessageService()
            : new MessageService();
    return {
        messageService,
    };
};

export default configureHydraServices;
