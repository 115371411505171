import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

import { AnalyticsEvent, SendEventArgs } from "api/eventsServiceApi/types";
import {
    getAuthToken,
    getProductVersion,
    getUniqueMachineId,
} from "features/LoginPage/loginPageSliceSelectors";
import { LoginPageState } from "features/LoginPage/types";
import { NetworkEnvState } from "features/NetworkEnv/NetworkEnvSlice";
import { Environment } from "Protocol/Generated/Messages";

export const getBaseUrl = (state: { networkEnv: NetworkEnvState }) => {
    const { environment } = state.networkEnv;
    switch (environment) {
        case Environment.Beta:
            return "https://events-dev.output.com";
        case Environment.Production:
            return "https://events.output.com";
        default:
            return null;
    }
};

const getBaseQuery: BaseQueryFn<FetchArgs> = (args, api, extraOptions) => {
    /**
     * @todo Ben Leichter
     * replace typecast w actual type
     */
    const state = api.getState() as {
        login: LoginPageState;
        networkEnv: NetworkEnvState;
    };

    const baseUrl = getBaseUrl(state);

    // There is no staging events environment, so we don't want to try and send events
    if (baseUrl === null) {
        return {
            error: {
                status: "CUSTOM_ERROR",
                data: "No base url present",
            },
        };
    }

    const uniqueMachineId = getUniqueMachineId(state.login);
    const productVersion = getProductVersion(state.login);
    const authToken = getAuthToken(state.login);

    if (uniqueMachineId === null) {
        throw new Error("No machine id present");
    }

    if (productVersion === null) {
        throw new Error("No product version present");
    }

    // Ensures that we don't attempt to send events before our auth token is present
    const headersFromQuery = args.headers as Record<string, string | undefined>;
    const token = headersFromQuery.Authorization;
    if (authToken === null && token === undefined) {
        return {
            error: {
                status: "CUSTOM_ERROR",
                data: "No auth token present",
            },
        };
    }

    const baseQueryFunc = fetchBaseQuery({
        baseUrl: `${baseUrl}/save/v1/`,
        prepareHeaders: (headers) => {
            if (headers.get("Authorization") === null) {
                headers.set("Authorization", `Bearer ${authToken}`);
            }
            headers.set("Content-Type", "application/json");
            headers.set("Output-Product-Name", "hydra");
            headers.set("Unique-Machine-Id", uniqueMachineId);
            headers.set("Output-Product-Version", productVersion);
            return headers;
        },
    });

    return baseQueryFunc(args, api, extraOptions);
};

const generateRequestEventFromArgs = (args: SendEventArgs): AnalyticsEvent => {
    if (args.userId) {
        return { ...args.event, user_id: args.userId };
    }
    return args.event;
};

export const eventsServiceApi = createApi({
    reducerPath: "eventsApi",
    baseQuery: getBaseQuery,
    endpoints: (builder) => ({
        sendEvent: builder.mutation<{ status: number }, SendEventArgs>({
            query: (args) => ({
                url: "/events",
                method: "POST",
                credentials: "include",
                headers: args.authToken
                    ? { Authorization: `Bearer ${args.authToken}` }
                    : {},
                body: {
                    time_stamp: new Date().toISOString(),
                    event: generateRequestEventFromArgs(args),
                },
            }),
        }),
    }),
});
