import {
    MusicalKey,
    MusicalRootNote,
    MusicalScale,
} from "Protocol/Generated/Messages";
import {
    majorToRelativeMinor,
    minorToRelativeMajor,
    musicalKeySharpFlatSymbolMap,
    musicalKeySharpFlatSymbolReplacementRegex,
    musicalScaleAbbreviationMap,
    musicalScaleAbbreviationReplacementRegex,
} from "shared/constants/musicalKey";

export const formatKeyRoot = (val: string) =>
    val.replace(
        musicalKeySharpFlatSymbolReplacementRegex,
        (match) => musicalKeySharpFlatSymbolMap[match],
    );

export const formatKeyScale = (val: string) => {
    const abbreviated = val.replace(
        musicalScaleAbbreviationReplacementRegex,
        (match) => musicalScaleAbbreviationMap[match],
    );

    return abbreviated.charAt(0).toUpperCase() + abbreviated.slice(1);
};

export const formatKey = (key: MusicalKey) =>
    `${formatKeyRoot(key.root)} ${formatKeyScale(key.scale)}`;

type KeyMapFunction<T> = ({
    major,
    minor,
}: {
    major: MusicalKey;
    minor: MusicalKey;
}) => T;

export const mapOverMusicalKeys = <T>(mapFn: KeyMapFunction<T>) =>
    Object.entries(majorToRelativeMinor).map(([majorRoot, minorRoot]) => {
        const major: MusicalKey = {
            root: majorRoot as MusicalRootNote,
            scale: MusicalScale.Major,
        };
        const minor: MusicalKey = {
            root: minorRoot as MusicalRootNote,
            scale: MusicalScale.Minor,
        };
        return mapFn({ major, minor });
    });

export const getRelativeKey = (key: MusicalKey): MusicalKey => {
    if (key.scale === MusicalScale.None) {
        return key;
    }

    if (key.scale === MusicalScale.Major) {
        return {
            root: majorToRelativeMinor[key.root],
            scale: MusicalScale.Minor,
        };
    }

    return { root: minorToRelativeMajor[key.root], scale: MusicalScale.Major };
};

export const pitchClassByTonic: {
    [tonic in keyof typeof MusicalRootNote]: number | null;
} = {
    [MusicalRootNote.None]: null,
    [MusicalRootNote.C]: 0,
    [MusicalRootNote.DFlat]: 1,
    [MusicalRootNote.D]: 2,
    [MusicalRootNote.EFlat]: 3,
    [MusicalRootNote.E]: 4,
    [MusicalRootNote.F]: 5,
    [MusicalRootNote.GFlat]: 6,
    [MusicalRootNote.G]: 7,
    [MusicalRootNote.AFlat]: 8,
    [MusicalRootNote.A]: 9,
    [MusicalRootNote.BFlat]: 10,
    [MusicalRootNote.B]: 11,
};

const NUMBER_OF_VALID_PITCHES = 12;
const MAX_TRANSPOSITION_UP = 5;
export const getDistanceFromLoopAndSessionPitchClass = (
    loopPitchClass: number,
    sessionPitchClass: number,
) => {
    const clockwiseDistance =
        (sessionPitchClass - loopPitchClass + NUMBER_OF_VALID_PITCHES) %
        NUMBER_OF_VALID_PITCHES;

    const counterclockwiseDistance =
        (loopPitchClass - sessionPitchClass + NUMBER_OF_VALID_PITCHES) %
        NUMBER_OF_VALID_PITCHES;

    const shouldTransposeUp =
        clockwiseDistance <= counterclockwiseDistance &&
        clockwiseDistance <= MAX_TRANSPOSITION_UP;

    return shouldTransposeUp ? clockwiseDistance : -counterclockwiseDistance;
};

export const getLoopPitchClass = (
    loopKey: MusicalKey,
    sessionKey: MusicalKey,
) => {
    // If the loop and session key scales differ and neither scale is None, we want the loop's relative key
    const shouldGetRelativeLoopKey =
        loopKey.scale !== sessionKey.scale &&
        !(
            loopKey.scale === MusicalScale.None ||
            sessionKey.scale === MusicalScale.None
        );

    return shouldGetRelativeLoopKey
        ? pitchClassByTonic[getRelativeKey(loopKey).root]
        : pitchClassByTonic[loopKey.root];
};

export const getSemitoneDistance = (
    loopKey: MusicalKey,
    sessionKey: MusicalKey,
): number => {
    const loopPitchClass = getLoopPitchClass(loopKey, sessionKey);
    const sessionPitchClass = pitchClassByTonic[sessionKey.root];

    if (loopPitchClass === null || sessionPitchClass === null) {
        return 0;
    }

    return getDistanceFromLoopAndSessionPitchClass(
        loopPitchClass,
        sessionPitchClass,
    );
};
export const keyHasNoScaleOrNoRoot = (key: MusicalKey) =>
    key.root === MusicalRootNote.None || key.scale === MusicalScale.None;
export const keysAreEqual = (key1: MusicalKey, key2: MusicalKey) =>
    key1.root === key2.root && key1.scale === key2.scale;
