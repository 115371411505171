import { eventsServiceApi } from "api/eventsServiceApi/eventsServiceApi";
import { AnalyticsEvent, SendEventArgs } from "api/eventsServiceApi/types";
import {
    getEmail,
    getInstanceUuid,
    getSessionUuid,
    getWpUserId,
} from "features/LoginPage/loginPageSliceSelectors";
import { State } from "state/types";
import { createHydraThunk } from "state/typeUtil";

type SendEventThunkArgs = Pick<AnalyticsEvent, "title" | "properties"> & {
    auth_token?: string;
    user_id?: string;
    email?: string;
};
export const sendEvent = createHydraThunk<SendEventThunkArgs>(
    "eventsServiceApi/sendEvent",
    async (args, { dispatch, getState }) => {
        const state = getState() as State;

        const sessionUuid = getSessionUuid(state.login);
        const instanceUuid = getInstanceUuid(state.login);
        const wpUserId = args.user_id || getWpUserId(state.login);
        const email = args.email || getEmail(state.login);

        if (!sessionUuid) {
            throw new Error("Missing session uuid");
        }
        if (!instanceUuid) {
            throw new Error("Missing instance uuid");
        }
        if (!wpUserId) {
            throw new Error("Missing session wpUserId");
        }
        if (!email) {
            throw new Error("Missing email");
        }

        const payload: SendEventArgs = {
            event: {
                title: args.title,
                type: "hydra_client",
                properties: args.properties,
                session_uuid: sessionUuid,
                instance_uuid: instanceUuid,
                user_id: wpUserId,
                email,
            },
            authToken: args.auth_token,
        };

        await dispatch(eventsServiceApi.endpoints.sendEvent.initiate(payload));
    },
);
