import React from "react";

import { MusicalScale } from "Protocol/Generated/Messages";
import TextToggle from "shared/components/Buttons/TextToggle/TextToggle";
import { TextToggleButtonState } from "shared/components/Buttons/TextToggle/TextToggleButtonState";

type TonalitySelectorProps = {
    readonly scale: MusicalScale.Major | MusicalScale.Minor;
    readonly onChange: (scale: MusicalScale.Major | MusicalScale.Minor) => void;
};

const stateToScale = (state: TextToggleButtonState) =>
    state === TextToggleButtonState.Left
        ? MusicalScale.Major
        : MusicalScale.Minor;

const scaleToState = (scale: MusicalScale) =>
    scale === MusicalScale.Major
        ? TextToggleButtonState.Left
        : TextToggleButtonState.Right;

const TonalitySelector = ({ scale, onChange }: TonalitySelectorProps) => {
    const state = scaleToState(scale);
    const handleTonalityClick = (newState: TextToggleButtonState) => {
        if (newState === state) {
            return;
        }

        onChange(stateToScale(newState));
    };

    return (
        <TextToggle
            leftText="Major"
            rightText="Minor"
            state={state}
            onToggle={handleTonalityClick}
        />
    );
};

export default TonalitySelector;
