import { CaptureState } from "features/Capture/CaptureState";

export const getUploadId = (state: CaptureState) => state.uploadId;
export const getCapturedAudioKey = (state: CaptureState) =>
    state.userKeyOverride ? state.userKeyOverride : state.detectedKey;

export const getCapturePercent = (state: CaptureState) => state.capturePercent;

export const getIsCaptureIdle = (state: CaptureState) =>
    !state.isCapturing &&
    !state.captureInitiated &&
    state.capturePercent !== 1.0;

export const getHasConfirmedCapture = (state: CaptureState) =>
    state.hasConfirmed;
export const getIsUploading = (state: CaptureState) => state.isUploading;
export const getNeedsUpload = (state: CaptureState) =>
    state.uploadId === null && state.hasConfirmed;
