import React from "react";

import { captureSlice } from "features/Capture/captureSlice";
import { useUtilityButtonStyles } from "features/Capture/Waveform/Big/hooks";
import Button from "shared/components/Buttons/Button";
import Edit from "shared/icons/Edit.svg";
import { useHydraDispatch } from "state/hooks";

const EditButton = () => {
    const styles = useUtilityButtonStyles();
    const dispatch = useHydraDispatch();
    const onEditClick = () => {
        dispatch(captureSlice.actions.pressEditKeyButton());
    };
    return (
        <Button onClick={onEditClick} additionalClasses={styles.utilityButton}>
            <Edit />
        </Button>
    );
};

export default EditButton;
