import { createSlice } from "@reduxjs/toolkit";

import { networkConfig } from "Protocol/Generated/MessageHelpers";
import { Environment } from "Protocol/Generated/Messages";

export type NetworkEnvState = {
    environment: Environment | null;
};

const initialState: NetworkEnvState = {
    environment: null,
};

export const networkEnvSlice = createSlice({
    name: "networkEnv",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(networkConfig, (_, action) => ({
            environment: action.payload.environment,
        }));
    },
});
