import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import LoginButton from "./LoginButton/LoginButton";
import { loginPageSlice } from "./loginPageSlice";
import PasswordInput from "./PasswordInput/PasswordInput";
import UsernameInput from "./UsernameInput/UsernameInput";
import Loader from "shared/components/Loader/Loader";

const useStyles = createUseStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    inner: {
        border: "solid 2px #000",
        padding: "36px",
        paddingTop: "26px",
        borderRadius: "8px",
    },
});

const LoginPage = () => {
    const styles = useStyles();
    const { getUsername, getPassword, getIsLoggingIn, getLoginErrorMessage } =
        loginPageSlice.selectors;
    const username = useSelector(getUsername);
    const password = useSelector(getPassword);
    const isLoggingIn = useSelector(getIsLoggingIn);
    const errorMessage = useSelector(getLoginErrorMessage);
    return (
        <div className={styles.container}>
            {isLoggingIn ? (
                <Loader emoji="🔐" />
            ) : (
                <div className={styles.inner}>
                    <UsernameInput value={username} />
                    <PasswordInput value={password} />
                    <LoginButton username={username} password={password} />
                </div>
            )}
            {errorMessage ? (
                <>
                    <p>There was a problem logging in:</p>
                    <p data-testid="error-message">{errorMessage}</p>
                </>
            ) : null}
        </div>
    );
};

export default LoginPage;
