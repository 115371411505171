import { RGB } from "shared/types/RGB";

export const rgbToRgbaString = (rgb: RGB, opacity: number) =>
    `rgba(${Object.values(rgb).join(", ")}, ${opacity})`;

/**
 * Converts an RGB color string to an RGBA color string with the specified opacity.
 * @param {string} rgbColorString - The RGB color string (e.g., "rgb(255,255,255)").
 * @param {number} opacity - The opacity value (0-1).
 * @returns {string} The RGBA color string with the specified opacity.
 */

export const withOpacity = (
    rgbColorString: string,
    opacity: number,
): string => {
    // Extract the RGB values from the input string
    const rgbValues = rgbColorString.match(/\d+/gu);
    const numRgbValues = 3;
    if (!rgbValues || rgbValues.length !== numRgbValues) {
        throw new Error("Invalid RGB color string");
    }
    // Convert the RGB values and the opacity into an RGBA string
    return `rgba(${rgbValues.join(",")},${opacity})`;
};
