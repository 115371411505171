import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sendLoginEvent } from "api/eventsServiceApi/events/sessionEvents";
import {
    getAuthToken,
    getEmail,
    getInstanceUuid,
    getProductVersion,
    getUniqueMachineId,
    getWpUserId,
} from "features/LoginPage/loginPageSliceSelectors";
import { LoginPageState } from "features/LoginPage/types";
import * as fromCpp from "Protocol/Generated/MessageHelpers";
import { LoginSuccess, MessageType } from "Protocol/Generated/Messages";
import { createHydraThunk } from "state/typeUtil";

export const initialLoginState: LoginPageState = {
    username: "",
    password: "",
    isLoggingIn: false,
    authToken: null,
    errorMessage: null,
    sessionUuid: null,
    instanceUuid: null,
    productVersion: null,
    uniqueMachineId: null,
    wpUserId: null,
    userHasOpenedGUI: false,
    email: null,
};

export const handleLoginButtonPressed = createHydraThunk<
    Pick<LoginPageState, "username" | "password">
>(
    "login/handleLoginButtonPressed",
    ({ username, password }, { extra: { messageService } }) => {
        if (username && password) {
            messageService.sendMessage({
                type: MessageType.Login,
                username,
                password,
            });
        }
    },
);

export const userLoggedIn = createHydraThunk<LoginSuccess>(
    "loginSlice/userLoggedIn",
    (args, { dispatch }) => {
        if (args.authToken === null) {
            // eslint-disable-next-line no-console
            console.error("Login event fired with null auth token");
            return;
        }

        void dispatch(
            sendLoginEvent({
                wrapperType: "plugin",
                authToken: args.authToken,
                wpUserId: args.wpUserId,
                email: args.email,
            }),
        );
    },
);

export const loginPageSlice = createSlice({
    name: "login",
    initialState: initialLoginState,
    reducers: {
        updateUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
            return state;
        },
        updatePassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
            return state;
        },
        updateErrorMessage: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            return state;
        },
    },
    extraReducers(builder) {
        builder.addCase(handleLoginButtonPressed.fulfilled, (state, action) => {
            const { username, password } = action.meta.arg;
            const allCredsProvided = Boolean(username && password);
            state.errorMessage = allCredsProvided
                ? null
                : "Both username and password must be provided";
            state.isLoggingIn = allCredsProvided;
            state.password = "";
        });
        builder.addCase(userLoggedIn.fulfilled, (state, action) => {
            state.wpUserId = action.meta.arg.wpUserId;
            state.email = action.meta.arg.email;
            state.isLoggingIn = false;
        });
        builder.addCase(fromCpp.authTokenUpdated, (state, action) => {
            const { authToken } = action.payload;
            state.authToken = authToken;
        });
        builder.addCase(fromCpp.loginFailure, (state, action) => {
            const { errorMessage } = action.payload;
            state.isLoggingIn = false;
            state.errorMessage = errorMessage;
        });
        builder.addCase(fromCpp.refreshFailure, (state) => {
            state.isLoggingIn = initialLoginState.isLoggingIn;
            state.username = initialLoginState.username;
            state.password = initialLoginState.password;
            state.authToken = initialLoginState.authToken;
            state.errorMessage = initialLoginState.errorMessage;
            state.wpUserId = initialLoginState.wpUserId;
            state.email = initialLoginState.email;
        });
        builder.addCase(fromCpp.sessionInfo, (state, action) => {
            const {
                sessionUuid,
                instanceUuid,
                uniqueMachineId,
                productVersion,
                wpUserId,
                userHasOpenedGUI,
                email,
            } = action.payload;
            state.sessionUuid = sessionUuid;
            state.instanceUuid = instanceUuid;
            state.uniqueMachineId = uniqueMachineId;
            state.productVersion = productVersion;
            state.wpUserId = wpUserId;
            state.userHasOpenedGUI = userHasOpenedGUI;
            state.email = email;
        });
    },
    selectors: {
        getAuthToken,
        getInstanceUuid,
        getWpUserId,
        getUniqueMachineId,
        getProductVersion,
        getEmail,
        getUsername: (state) => state.username,
        getPassword: (state) => state.password,
        getIsLoggingIn: (state) => state.isLoggingIn,
        getLoginErrorMessage: (state) => state.errorMessage,
        getSessionUuid: (state) => state.sessionUuid,
        getUserHasOpenedGUI: (state) => state.userHasOpenedGUI,
    },
});
