import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { downloadSlice } from "features/Download/downloadSlice";
import { gray100 } from "shared/constants/color";
import { flexCenterChildren } from "shared/styles/layout.styles";
import { Loop } from "shared/types/Loop";
import { State } from "state/types";

const downloadProgresButtonSize = 16;
const PROGRESS_PERCENT_MULTIPLIER = 100;

type StylesProps = {
    readonly downloadPercent: number;
};

const useStyles = createUseStyles({
    downloadProgressButtonContainer: {
        ...flexCenterChildren,
        width: `${downloadProgresButtonSize}px`,
        height: `${downloadProgresButtonSize}px`,
        borderRadius: "50%",
        border: `1px solid ${gray100}`,
    },
    downloadProgressButtonInner: ({ downloadPercent }: StylesProps) => ({
        width: `${downloadProgresButtonSize / 2}px`,
        height: `${downloadProgresButtonSize / 2}px`,
        borderRadius: "50%",
        background: `conic-gradient(${gray100} 0% ${downloadPercent}%, transparent ${downloadPercent}% 100%)`,
    }),
});

type DownloadProgressButtonProps = {
    readonly loop: Loop;
};

const DownloadProgressButton = ({ loop }: DownloadProgressButtonProps) => {
    const downloadPercent =
        PROGRESS_PERCENT_MULTIPLIER *
        useSelector((state: State) =>
            downloadSlice.selectors.getLoopDownloadProgress(state, loop.id),
        );

    const styles = useStyles({ downloadPercent });
    return (
        <span className={styles.downloadProgressButtonContainer}>
            <span className={styles.downloadProgressButtonInner} />
        </span>
    );
};

export default DownloadProgressButton;
