import React from "react";
import { createUseStyles } from "react-jss";

import { black0Tint25, gray15, gray25 } from "shared/constants/color";

type StyleProps = {
    alignment: "left" | "right";
};

const useStyles = createUseStyles({
    popupBackground: ({ alignment }: StyleProps) => ({
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        backgroundColor: gray15,
        border: `1px solid ${gray25}`,
        bottom: "40px",
        right: alignment === "right" ? "0px" : "auto",
        left: alignment === "left" ? "0px" : "auto",
        boxShadow: `0px 2px 16px 0px ${black0Tint25}`,
        borderRadius: "12px",
        zIndex: 3,
    }),
});

type PopupProps = {
    readonly alignment?: "left" | "right";
    readonly children: React.ReactNode;
};

const Popup = ({ alignment = "left", children }: PopupProps) => {
    const styles = useStyles({ alignment });
    return <div className={styles.popupBackground}>{children}</div>;
};

export default Popup;
