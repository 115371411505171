import React from "react";
import { createUseStyles } from "react-jss";

import { TextToggleButtonState } from "./TextToggleButtonState";
import Button from "shared/components/Buttons/Button";
import { gray15 } from "shared/constants/color";
import { labelStyle } from "shared/styles/typography.styles";

type TextToggleButtonProps = {
    readonly state: TextToggleButtonState;
    readonly isActive: boolean;
    readonly text: string;
    readonly onClick: (state: TextToggleButtonState) => void;
};

const ustStyles = createUseStyles({
    textToggleButtonContainer: ({ isActive }: { isActive: boolean }) => ({
        height: "28px",
        width: "100%",
        borderRadius: "6px",
        backgroundColor: isActive ? gray15 : "transparent",
    }),
    buttonText: {
        ...labelStyle,
        fontWeight: "700",
        color: "white",
    },
});

const TextToggleButton = ({
    state,
    onClick,
    isActive,
    text,
}: TextToggleButtonProps) => {
    const clickHandler = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClick(state);
    };
    const styles = ustStyles({ isActive });
    return (
        <Button
            onClick={clickHandler}
            additionalClasses={styles.textToggleButtonContainer}
        >
            <p className={styles.buttonText}> {text} </p>
        </Button>
    );
};

export default TextToggleButton;
