import React from "react";
import { createUseStyles } from "react-jss";

import AdjustmentIndicator from "features/Preview/PreviewBar/AdjustmentIndicator";
import { fontSize2 } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    previewBarTextCellContainer: {
        ...fontSize2,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "6px",
        whiteSpace: "nowrap",
        maxWidth: "100%",
    },
    textWrapper: {
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
});

type PreviewBarTextCellProps = {
    readonly text: string;
    readonly adjustmentValue?: number;
};

const PreviewBarTextCell = ({
    text,
    adjustmentValue,
}: PreviewBarTextCellProps) => {
    const styles = useStyles();
    const showIndicator = adjustmentValue && adjustmentValue !== 0;
    return (
        <span className={styles.previewBarTextCellContainer}>
            <span className={styles.textWrapper}>{text}</span>
            {showIndicator ? (
                <AdjustmentIndicator value={adjustmentValue} />
            ) : null}
        </span>
    );
};

export default PreviewBarTextCell;
