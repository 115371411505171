import React from "react";
import { createUseStyles } from "react-jss";

import PlayIcon from "../../icons/PlayIcon";
import Button from "shared/components/Buttons/Button";
import { whiteTint10 } from "shared/constants/color";
import { stopPropagation } from "shared/eventHandlers/sharedEventHandlers";
import PauseIcon from "shared/icons/PauseIcon";

const defaultIconSizePx = "10px";

type PlayPauseButtonProps = {
    readonly onPlayClick: (e: React.MouseEvent) => void;
    readonly onPauseClick: (e: React.MouseEvent) => void;
    readonly size: "small" | "large";
    readonly isPlaying: boolean;
    readonly bgBlur?: boolean;
    readonly bgOnHoverOnly?: boolean;
};

type PlayPauseButtonStyleProps = {
    padding: string;
    bgOnHoverOnly?: boolean;
    bgBlur?: boolean;
};
const useStyles = createUseStyles({
    playPauseButtonContainer: ({
        padding,
        bgOnHoverOnly,
        bgBlur,
    }: PlayPauseButtonStyleProps) => ({
        padding,
        backgroundColor: bgOnHoverOnly ? "transparent" : whiteTint10,
        borderRadius: "50%",
        WebkitBackdropFilter: bgBlur ? "blur(5px)" : undefined, // Needed for older versions of safari
        backdropFilter: bgBlur ? "blur(5px)" : undefined,
        "&:hover": {
            backgroundColor: bgOnHoverOnly ? whiteTint10 : undefined,
        },
    }),
});

const PlayPauseButton = ({
    onPlayClick,
    onPauseClick,
    isPlaying,
    bgBlur = false,
    size = "small",
    bgOnHoverOnly,
}: PlayPauseButtonProps) => {
    const iconWidth = size === "small" ? defaultIconSizePx : "14px";
    const styles = useStyles({
        padding: iconWidth,
        bgOnHoverOnly,
        bgBlur,
    });
    const icon = isPlaying ? (
        <PauseIcon iconWidth={iconWidth} />
    ) : (
        <PlayIcon iconWidth={iconWidth} />
    );

    return (
        <Button
            additionalClasses={styles.playPauseButtonContainer}
            onClick={isPlaying ? onPauseClick : onPlayClick}
            onMouseDown={stopPropagation}
        >
            {icon}
        </Button>
    );
};

export default PlayPauseButton;
