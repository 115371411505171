import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import BpmDisplay from "features/Preview/BpmDisplay";
import PreviewMusicalKeySelector from "features/Preview/KeyAndBpmControl/PreviewMusicalKeySelector";
import Divider from "shared/components/Divider";
import { gray15 } from "shared/constants/color";

const useStyles = createUseStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "15px",
        width: "100%",
    },
});

const dropdownWidth = "112px";

// eslint-disable-next-line no-shadow
enum OpenMenu {
    None,
    Key,
    Bpm,
}

const KeyAndBpmControl = () => {
    const [openMenu, setOpenMenu] = useState(OpenMenu.None);

    const onKeyMenuOpen = () => {
        setOpenMenu(OpenMenu.Key);
    };

    const onBpmMenuOpen = () => {
        setOpenMenu(OpenMenu.Bpm);
    };

    const styles = useStyles();
    return (
        <div className={styles.container}>
            <Divider color={gray15} direction="vertical" />
            <PreviewMusicalKeySelector
                width={dropdownWidth}
                isBpmSelectorOpen={openMenu === OpenMenu.Bpm}
                onOpen={onKeyMenuOpen}
            />
            <BpmDisplay
                width={dropdownWidth}
                isKeySelectorOpen={openMenu === OpenMenu.Key}
                onOpen={onBpmMenuOpen}
            />
        </div>
    );
};

export default KeyAndBpmControl;
