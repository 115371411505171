import { MusicalRootNote, MusicalScale } from "Protocol/Generated/Messages";
import {
    APILoop,
    apiMusicalKeyScaleByMusicalScale,
    apiMusicalKeyTonicByMusicalRootNote,
    Loop,
} from "shared/types/Loop";
import { formatKey } from "shared/utils/musicalKeyUtil";

export const MS_IN_SECONDS = 1000;
export const isLoopDownloadUrlExpired = (loop: Loop): boolean => {
    const url = new URL(loop.olac_url);
    const unixExpirationTime = url.searchParams.get("Expires");

    // If the url doesn't expire, it must be valid
    if (unixExpirationTime === null) {
        return false;
    }

    // Multiply by 1000 bc unix timestamp is seconds since epoch and js is milliseconds
    const expirationTimeAsDate = new Date(
        Number(unixExpirationTime) * MS_IN_SECONDS,
    );

    return new Date().getTime() >= expirationTimeAsDate.getTime();
};

export const convertAPILoopToLoop = (apiLoop: APILoop): Loop => {
    /**
     * Reverse lookup to translate the API key and tonic to Client key and tonic.
     */
    const root = Object.entries(apiMusicalKeyTonicByMusicalRootNote).find(
        (entry) => entry[1] === apiLoop.tonic,
    );
    const scale = Object.entries(apiMusicalKeyScaleByMusicalScale).find(
        (entry) => entry[1] === apiLoop.scale,
    );
    return {
        ...apiLoop,
        tonic: root ? (root[0] as MusicalRootNote) : MusicalRootNote.None,
        scale: scale ? (scale[0] as MusicalScale) : MusicalScale.None,
    };
};

export const getMusicalKeyDisplayValueFromLoop = (loop: Loop) => {
    const tonicIsNone =
        loop.tonic === MusicalRootNote.None || loop.tonic === null;
    if (tonicIsNone) {
        return "Any Key";
    }

    const scaleIsNone = loop.scale === MusicalScale.None || loop.scale === null;

    if (scaleIsNone) {
        return apiMusicalKeyTonicByMusicalRootNote[
            loop.tonic as MusicalRootNote
        ];
    }

    return formatKey({
        root: loop.tonic as MusicalRootNote,
        scale: loop.scale as MusicalScale,
    });
};
