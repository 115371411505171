import { sendSessionKeyChangeEvent } from "api/eventsServiceApi/events/keyAndBpmEvents";
import { sendPreviewEvent } from "api/eventsServiceApi/events/loopEvents";
import { getLoopWithValidUrl } from "api/hydraServiceApi/thunks";
import {
    getKeyLock,
    getPreviousMusicalKey,
} from "features/Preview/previewSelectors";
import { MessageType, MusicalKey } from "Protocol/Generated/Messages";
import { getLoopKey, Loop } from "shared/types/Loop";
import { State } from "state/types";
import { createHydraThunk } from "state/typeUtil";

type PlayPauseButtonPressedArgs = {
    loop: Loop | undefined;
    isLoaded: boolean;
    isPlaying: boolean;
    rowNumber?: number;
};

type UpdatePreviewKeyArgs = {
    newKey: MusicalKey;
    showKeyChangeModal?: boolean;
};
export const updatePreviewKey = createHydraThunk<UpdatePreviewKeyArgs>(
    "preview/updatePreviewKey",
    ({ newKey }, { extra: { messageService }, dispatch }) => {
        void dispatch(sendSessionKeyChangeEvent({ sessionKey: newKey }));
        messageService.sendMessage({
            type: MessageType.Key,
            value: newKey,
        });
    },
);

export const revertToPreviousKey = createHydraThunk(
    "preview/revertToPreviousKey",
    (_, { dispatch, getState }) => {
        const state = getState() as State;
        const previousMusicalKey = getPreviousMusicalKey(state.preview);

        if (!previousMusicalKey) {
            return;
        }

        void dispatch(updatePreviewKey({ newKey: previousMusicalKey }));
    },
);

export const sendPreviewMessage = createHydraThunk<Loop>(
    "search/sendPreviewMessage",
    async (loop, { extra: { messageService } }) => {
        // Get url by id
        const url = loop.olac_url;

        messageService.sendMessage({
            type: MessageType.Preview,
            id: loop.id,
            sampleBpm: loop.bpm,
            sampleKey: getLoopKey(loop),
            url,
        });
        return Promise.resolve();
    },
);

export const sendPlayStateChangedMessage = createHydraThunk<boolean>(
    "preview/sendPauseMessage",
    (shouldPlay, { extra: { messageService } }) => {
        messageService.sendMessage({
            type: MessageType.PlayState,
            shouldPlay,
        });
    },
);

type BpmAndKeyLockArgs = {
    bpmLock: boolean;
    keyLock: boolean;
};
export const sendBpmAndKeyLockMessage = createHydraThunk<BpmAndKeyLockArgs>(
    "preview/sendBpmAndKeyLockMessage",
    ({ bpmLock, keyLock }, { extra: { messageService } }) => {
        messageService.sendMessage({
            type: MessageType.BpmAndKeyLock,
            bpmLock,
            keyLock,
        });
    },
);

export const bpmLockSwitched = createHydraThunk<boolean>(
    "preview/bpmLockSwitched",
    (bpmLock: boolean, { getState, dispatch }) => {
        const state = getState() as State;
        const keyLock = getKeyLock(state.preview);
        void dispatch(sendBpmAndKeyLockMessage({ bpmLock, keyLock }));
    },
);

export const pressPlayPauseButton = createHydraThunk(
    "preview/pressPlayPauseButton",
    async (
        { loop, isPlaying, isLoaded, rowNumber }: PlayPauseButtonPressedArgs,
        { dispatch },
    ) => {
        if (!loop) {
            return;
        }

        const loopToPreview: Loop = await dispatch(
            getLoopWithValidUrl({
                loop,
            }),
        ).unwrap();

        if (!isPlaying) {
            void dispatch(
                sendPreviewEvent({
                    loopUuid: loop.id,
                    loopName: loop.name,
                    rowNumber,
                }),
            );
        }

        if (!isLoaded) {
            void dispatch(sendPreviewMessage(loopToPreview));
            return;
        }

        void dispatch(sendPlayStateChangedMessage(!isPlaying));
    },
);
