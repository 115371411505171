import {
    MusicalKey,
    MusicalRootNote,
    MusicalScale,
} from "Protocol/Generated/Messages";

export type APIMusicalKeyTonic =
    | "C"
    | "Db"
    | "D"
    | "Eb"
    | "E"
    | "F"
    | "Gb"
    | "G"
    | "Ab"
    | "A"
    | "Bb"
    | "B"
    | "";
export type APIMusicalKeyScale = "major" | "minor" | "";

export const apiMusicalKeyTonicByMusicalRootNote: {
    [rootNote in MusicalRootNote]: APIMusicalKeyTonic | "";
} = {
    [MusicalRootNote.AFlat]: "Ab",
    [MusicalRootNote.A]: "A",
    [MusicalRootNote.BFlat]: "Bb",
    [MusicalRootNote.B]: "B",
    [MusicalRootNote.C]: "C",
    [MusicalRootNote.DFlat]: "Db",
    [MusicalRootNote.D]: "D",
    [MusicalRootNote.EFlat]: "Eb",
    [MusicalRootNote.E]: "E",
    [MusicalRootNote.F]: "F",
    [MusicalRootNote.GFlat]: "Gb",
    [MusicalRootNote.G]: "G",
    [MusicalRootNote.None]: "",
};
export const apiMusicalKeyScaleByMusicalScale: {
    [scale in MusicalScale]: APIMusicalKeyScale;
} = {
    [MusicalScale.Major]: "major",
    [MusicalScale.Minor]: "minor",
    [MusicalScale.None]: "",
};

export type Loop = {
    id: string;
    name: string;
    released: boolean;
    tonic: MusicalRootNote | null;
    scale: MusicalScale | null;
    bpm: number;
    waveform_data: Array<number>;
    hires_waveform_data: Array<number>;
    liked: boolean;
    olac_url: string;
};

export type APILoop = Omit<Loop, "tonic" | "scale"> & {
    tonic: APIMusicalKeyTonic | null;
    scale: APIMusicalKeyScale | null;
};

export const getLoopKey = (loop: Loop): MusicalKey => ({
    root: loop.tonic || MusicalRootNote.None,
    scale: loop.scale || MusicalScale.None,
});
