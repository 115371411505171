import { JssStyle } from "jss";
import React from "react";
import { createUseStyles } from "react-jss";

import { flexCenterChildren } from "shared/styles/layout.styles";

type StyleProps = { enabled: boolean };

const useStyles = createUseStyles({
    buttonContainer: ({ enabled }: StyleProps): JssStyle => ({
        ...flexCenterChildren,
        cursor: enabled ? "pointer" : "",
        border: "none",
        opacity: enabled ? 1 : 0.5,
        background: "transparent",
    }),
});

export type ButtonProps = {
    readonly children: React.ReactNode;
    readonly onClick: (e: React.MouseEvent) => void;
    readonly additionalClasses?: string;
    readonly onMouseDown?: (e: React.MouseEvent) => void;
    readonly onMouseEnter?: () => void;
    readonly onMouseLeave?: () => void;
    readonly enabled?: boolean;
};

const Button = ({
    children,
    onClick,
    onMouseEnter = undefined,
    onMouseLeave = undefined,
    onMouseDown = undefined,
    enabled = true,
    additionalClasses,
}: ButtonProps) => {
    const styles = useStyles({
        enabled,
    });
    const buttonClassName = `${styles.buttonContainer}${additionalClasses ? ` ${additionalClasses}` : ""}`;
    return (
        <button
            type="button"
            className={buttonClassName}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={onMouseDown}
            disabled={!enabled}
        >
            {children}
        </button>
    );
};

export default Button;
