import React from "react";
import { createUseStyles } from "react-jss";

import CapturePlaybackButton from "features/Capture/CapturePlaybackButton";
import EditButton from "features/Capture/Waveform/Big/EditButton";
import TrashButton from "features/Capture/Waveform/Big/TrashButton";
import { MusicalKey } from "Protocol/Generated/Messages";
import { whiteTint35 } from "shared/constants/color";
import { labelStyle } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    buttonsRow: {
        position: "absolute",
        zIndex: 2,
        padding: "12px",
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-between",
    },
    buttonsRight: {
        display: "flex",
        alignItems: "center",
    },
    label: {
        ...labelStyle,
        marginRight: "8px",
        color: whiteTint35,
    },
    buttonWrapper: {
        gap: "4px",
        display: "flex",
    },
});

export type CaptureWaveformBigButtonsProps = {
    readonly capturedAudioKey: MusicalKey;
};

const UtilityRow = ({ capturedAudioKey }: CaptureWaveformBigButtonsProps) => {
    const styles = useStyles();

    return (
        <div className={styles.buttonsRow}>
            <CapturePlaybackButton bgBlur={false} />
            <div className={styles.buttonsRight}>
                <div className={styles.label}>
                    {`${capturedAudioKey.root} ${capturedAudioKey.scale}`}
                </div>
                <span className={styles.buttonWrapper}>
                    <EditButton />
                    <TrashButton />
                </span>
            </div>
        </div>
    );
};

export default UtilityRow;
