import React from "react";
import { createUseStyles } from "react-jss";

import MusicalKeyButton from "features/Preview/MusicalKeySelector/MusicalKeyButton";
import {
    MusicalKey,
    MusicalRootNote,
    MusicalScale,
} from "Protocol/Generated/Messages";
import { mapOverMusicalKeys } from "shared/utils/musicalKeyUtil";

const useStyles = createUseStyles({
    keyGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "8px",
        position: "relative",
    },
});

type MusicalKeyGridProps = {
    readonly selectedKey: MusicalKey;
    readonly onMusicalKeyClick: (musicalKey: MusicalKey) => void;
    readonly tonality: MusicalScale;
};

const MusicalKeyGrid = ({
    selectedKey,
    onMusicalKeyClick,
    tonality,
}: MusicalKeyGridProps) => {
    const styles = useStyles();
    return (
        <div className={styles.keyGrid}>
            {mapOverMusicalKeys(({ major, minor }) => {
                if (major.root === MusicalRootNote.None) {
                    return null;
                }

                const scaleKey = tonality === major.scale ? major : minor;
                const relativeKey = tonality === major.scale ? minor : major;

                return (
                    <MusicalKeyButton
                        activeKey={scaleKey}
                        relativeKey={relativeKey}
                        key={scaleKey.root}
                        onClick={onMusicalKeyClick}
                        isActive={
                            selectedKey.root === scaleKey.root &&
                            selectedKey.scale === scaleKey.scale
                        }
                    />
                );
            })}
        </div>
    );
};

export default MusicalKeyGrid;
