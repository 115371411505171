import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import KeyAndBpmControl from "features/Preview/KeyAndBpmControl/KeyAndBpmControl";
import PlaybackControls from "features/Preview/PreviewBar/PlaybackControls";
import PreviewBarTextCell from "features/Preview/PreviewBar/PreviewBarTextCell";
import { previewSlice } from "features/Preview/previewSlice";
import { makeGetLoopFromSearchResults } from "features/SearchPage/searchPageSelectors";
import Column from "shared/components/Grid/Column";
import Grid from "shared/components/Grid/Grid";
import { getLoopKey, Loop } from "shared/types/Loop";
import { getMusicalKeyDisplayValueFromLoop } from "shared/utils/loopUtil";
import { getSemitoneDistance } from "shared/utils/musicalKeyUtil";
import { State } from "state/types";

const PreviewControls = () => {
    const getLoopFromSearchResults = useMemo(makeGetLoopFromSearchResults, []);

    const activePreviewId =
        useSelector(previewSlice.selectors.getActivePreviewId) || "";
    const activeLoop = useSelector<State, Loop | undefined>((state) =>
        getLoopFromSearchResults(state.search, {
            id: activePreviewId,
        }),
    );
    const sessionKey = useSelector(previewSlice.selectors.getMusicalKey);
    const bpm = useSelector(previewSlice.selectors.getBpm);
    const bpmLock = useSelector(previewSlice.selectors.getBpmLock);

    return (
        <Grid>
            <Column width={1}>
                <PlaybackControls activeLoop={activeLoop} />
            </Column>
            {activeLoop ? (
                <>
                    <Column width={3}>
                        <PreviewBarTextCell text={activeLoop.name} />
                    </Column>
                    <Column width={2}>
                        <PreviewBarTextCell
                            text={getMusicalKeyDisplayValueFromLoop(activeLoop)}
                            adjustmentValue={getSemitoneDistance(
                                getLoopKey(activeLoop),
                                sessionKey,
                            )}
                        />
                    </Column>
                    <Column width={2}>
                        <PreviewBarTextCell
                            text={`${activeLoop.bpm} BPM`}
                            adjustmentValue={
                                bpmLock ? bpm - activeLoop.bpm : undefined
                            }
                        />
                    </Column>
                </>
            ) : (
                <Column width={7} />
            )}
            <Column width={4}>
                <KeyAndBpmControl />
            </Column>
        </Grid>
    );
};

export default PreviewControls;
