import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";

import { MusicalKey } from "Protocol/Generated/Messages";
import Button from "shared/components/Buttons/Button";
import { cyan, gray0, gray10, gray100, gray45 } from "shared/constants/color";
import { formatKeyRoot, formatKeyScale } from "shared/utils/musicalKeyUtil";

type MusicalKeyListItemProps = {
    readonly isActive: boolean;
    readonly activeKey: MusicalKey;
    readonly relativeKey: MusicalKey;
    readonly onClick: (musicalKey: MusicalKey) => void;
};

const buttonSize = "48px";
const useStyles = createUseStyles({
    musicalKeyButtonContainer: ({ isActive }: { isActive: boolean }) => ({
        backgroundColor: isActive ? cyan : gray10,
        color: isActive ? gray0 : gray100,
        flexDirection: "column",
        borderRadius: "8px",
        width: buttonSize,
        height: buttonSize,
    }),
    keyRootText: {
        fontSize: "14px",
        fontWeight: 700,
    },
    keyTonalityText: {
        fontSize: "9px",
        fontWeight: 600,
    },
    relativeKeyText: { fontSize: "9px", fontWeight: 600, color: gray45 },
});

const MusicalKeyButton = ({
    isActive,
    activeKey,
    relativeKey,
    onClick,
}: MusicalKeyListItemProps) => {
    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            onClick(activeKey);
        },
        [activeKey],
    );

    const styles = useStyles({ isActive });

    const activeKeyRootStr = formatKeyRoot(activeKey.root);
    const activeKeyScaleStr = formatKeyScale(activeKey.scale);
    const relativeKeyStr = `${formatKeyRoot(relativeKey.root)} ${formatKeyScale(
        relativeKey.scale,
    )}`;

    return (
        <Button
            additionalClasses={styles.musicalKeyButtonContainer}
            onClick={handleClick}
        >
            <p className={styles.keyRootText}>{activeKeyRootStr}</p>
            <p className={styles.keyTonalityText}>{activeKeyScaleStr}</p>
            <p className={styles.relativeKeyText}>{relativeKeyStr}</p>
        </Button>
    );
};

export default MusicalKeyButton;
