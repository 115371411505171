import React from "react";
import { createUseStyles } from "react-jss";

import DownloadButton from "features/Download/DownloadButton";
import DownloadProgressButton from "features/Download/DownloadProgressButton";
import { DownloadState } from "features/Download/util";
import CheckWithCircle from "shared/icons/CheckWithCircle.svg";
import { flexCenterChildren } from "shared/styles/layout.styles";
import { Loop } from "shared/types/Loop";

const useStyles = createUseStyles({
    downloadState: flexCenterChildren,
});

type DownloadStateProps = {
    readonly downloadState: DownloadState;
    readonly loop: Loop;
    readonly rowNumber: number;
    readonly isHovered: boolean;
};

const DownloadStateComponent = ({
    downloadState,
    loop,
    rowNumber,
    isHovered,
}: DownloadStateProps) => {
    const styles = useStyles();

    return (
        <div className={styles.downloadState}>
            {downloadState === "downloaded" ? <CheckWithCircle /> : null}
            {downloadState === "inProgress" ? (
                <DownloadProgressButton loop={loop} />
            ) : null}
            {downloadState === "notDownloaded" && isHovered ? (
                <DownloadButton loop={loop} rowNumber={rowNumber} />
            ) : null}
        </div>
    );
};

export default DownloadStateComponent;
