import { useEffect, useRef } from "react";

import { cyanTint20, cyanTint80 } from "shared/constants/color";

const scale = (
    inputY: number,
    yRange: Array<number>,
    xRange: Array<number>,
): number => {
    const [xMin, xMax] = xRange;
    const [yMin, yMax] = yRange;

    const percent = (inputY - yMin) / (yMax - yMin);
    const outputX = percent * (xMax - xMin) + xMin;

    return outputX;
};

const DEFAULT_LINE_WIDTH = 1;
const drawLine = (
    context: CanvasRenderingContext2D | null,
    info: {
        startX: number;
        startY: number;
        height: number;
        width?: number;
    },
) => {
    const { startX, startY, height, width = DEFAULT_LINE_WIDTH } = info;

    if (context) {
        context.beginPath();

        context.rect(startX, startY - height / 2, width, height);
        context.fill();
        context.closePath();
    }
};

const INPUT_LOWER_BOUND = 0;
const INPUT_UPPER_BOUND = 1;
const OUTPUT_LOWER_BOUND = 0;
const scaleAndDrawLine = ({
    input,
    width,
    height,
    index,
    verticalMidPoint,
    context,
}: {
    input: number;
    width: number;
    height: number;
    index: number;
    verticalMidPoint: number;
    context: CanvasRenderingContext2D;
}) => {
    const scaledHeight = scale(
        input,
        [INPUT_LOWER_BOUND, INPUT_UPPER_BOUND],
        [OUTPUT_LOWER_BOUND, height],
    );
    const startX = index * width;
    const info = {
        startX,
        startY: verticalMidPoint,
        height: scaledHeight,
        width,
    };

    drawLine(context, info);
};

const useDrawCanvasWaveform = (
    ref: React.MutableRefObject<HTMLCanvasElement | null>,
    data: Array<number>,
) => {
    useEffect(() => {
        if (ref.current) {
            const width = ref.current.width / data.length;
            const { height } = ref.current;
            const verticalMidPoint = height / 2;
            const context = ref.current.getContext("2d");

            if (!context) {
                throw new Error("Unable to obtain Canvas 2d context.");
            }

            // Setup gradient
            const gradient = context.createLinearGradient(
                0,
                0,
                ref.current.width,
                0,
            );

            gradient.addColorStop(0, cyanTint20);
            gradient.addColorStop(0.5, cyanTint80);
            gradient.addColorStop(1, cyanTint20);
            context.fillStyle = gradient;

            let index = 0;
            for (const wd of data) {
                scaleAndDrawLine({
                    input: wd,
                    width,
                    height,
                    index,
                    verticalMidPoint,
                    context,
                });
                index += 1;
            }
        }
    }, []);
};

export const useWaveformCanvasRef = (
    width: number,
    height: number,
    data: Array<number>,
) => {
    const canvasRef: React.MutableRefObject<HTMLCanvasElement | null> =
        useRef(null);

    useEffect(() => {
        const canvasEle = canvasRef.current;
        if (canvasEle) {
            canvasEle.width = width;
            canvasEle.height = height;
        }
    }, []);

    useDrawCanvasWaveform(canvasRef, data);

    return { ref: canvasRef };
};
