/* eslint-disable react/jsx-max-depth */
import React from "react";

const PauseIcon = ({
    iconWidth,
    iconHeight = iconWidth,
}: {
    readonly iconWidth: string;
    readonly iconHeight?: string;
}) => (
    <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="1.5" width="3" height="13" fill="white" />
        <rect x="9.5" width="3" height="13" fill="white" />
    </svg>
);

export default PauseIcon;
