import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import LoopRowInner from "./LoopRowInner/LoopRowInner";
import { loopDragStarted, onLoopDragOutOfWindow } from "../thunks";
import { downloadSlice, DownloadState } from "features/Download/downloadSlice";
import LoopRowDragOverlay from "features/SearchPage/LoopRow/LoopRowDragOverlay";
import { searchSlice } from "features/SearchPage/searchPageSlice";
import Draggable from "shared/components/Draggable";
import {
    black0Tint10,
    cyanTint05,
    cyanTint25,
    gray10,
    gray8,
    whiteTint10,
} from "shared/constants/color";
import { Loop } from "shared/types/Loop";
import { useHydraDispatch } from "state/hooks";

type StyleProps = {
    rowNumber: number;
};

const useStyles = createUseStyles({
    container: ({ rowNumber }: StyleProps) => ({
        height: "70px",
        display: "flex",
        alignItems: "center",
        backgroundColor: rowNumber % 2 === 0 ? gray10 : gray8,
        border: "solid 1px transparent",
        cursor: "grab",
        "&:active": {
            cursor: "grabbing",
        },
        "&:hover": {
            backgroundColor: gray8,
            background: `linear-gradient(0deg, ${cyanTint05} 0%, ${cyanTint05} 100%), ${whiteTint10}`,
            boxShadow: `0px 0px 10px 0px ${cyanTint25} inset, 0px 2px 4px 0px ${black0Tint10}`,
            borderRadius: "8px",
            border: `solid 1px ${whiteTint10}`,
        },
    }),
});

type LoopRowProps = {
    readonly loop: Loop;
    readonly rowNumber: number;
};

const LoopRow = ({ loop, rowNumber }: LoopRowProps) => {
    const dispatch = useHydraDispatch();
    const [isHovered, setIsHovered] = useState(false);
    const isOSDragging = useSelector(searchSlice.selectors.getIsOSDragging);
    const isLoopDownloaded = useSelector<{ download: DownloadState }, boolean>(
        (state) => downloadSlice.selectors.getIsLoopDownloaded(state, loop.id),
    );

    const handleDragStart = () => {
        void dispatch(loopDragStarted({ loop, isLoopDownloaded, rowNumber }));
    };

    const handleDragEnd = () => {
        dispatch(searchSlice.actions.endDrag());
    };

    const onMouseOut = () => {
        void dispatch(onLoopDragOutOfWindow(loop.id));
    };

    const onMouseEnter = () => {
        setIsHovered(true);
    };
    const onMouseLeave = () => {
        setIsHovered(false);
    };

    const styles = useStyles({ rowNumber });
    return (
        <Draggable
            overlay={
                <LoopRowDragOverlay
                    text={isLoopDownloaded ? loop.name : "Downloading..."}
                    textStyle={isLoopDownloaded ? "primary" : "secondary"}
                />
            }
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            cancelDrag={isOSDragging}
            onMouseOut={onMouseOut}
        >
            <div
                className={styles.container}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <LoopRowInner
                    isHovered={isHovered}
                    loop={loop}
                    rowNumber={rowNumber}
                />
            </div>
        </Draggable>
    );
};

export default LoopRow;
