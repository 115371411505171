import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import AudioCapture from "features/Capture/AudioCapture";
import { loginPageSlice } from "features/LoginPage/loginPageSlice";
import SearchButton from "features/SearchPage/SearchInput/SearchButton";
import SearchInput from "features/SearchPage/SearchInput/SearchInput";
import { gray100, gray8 } from "shared/constants/color";
import { APP_MARGIN_PX, SIDEPANEL_WIDTH_PX } from "shared/constants/layout";
import { labelStyle } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    container: {
        width: SIDEPANEL_WIDTH_PX,
        marginRight: APP_MARGIN_PX,
        borderRadius: "12px",
        backgroundColor: gray8,
        display: "flex",
        flexDirection: "column",
    },
    inner: {
        padding: "0 12px",
    },
    title: {
        ...labelStyle,
        padding: "12px 4px",
        color: gray100,
    },
});

const Sidepanel = () => {
    const styles = useStyles();
    const authToken = useSelector(loginPageSlice.selectors.getAuthToken);
    return authToken ? (
        <div className={styles.container}>
            <div className={styles.inner}>
                <p className={styles.title}>Find Samples</p>
                <SearchInput />
                <AudioCapture />
            </div>
            <SearchButton />
        </div>
    ) : null;
};

export default Sidepanel;
