import { createSlice } from "@reduxjs/toolkit";

import { hostStoppedProcessingAudio } from "Protocol/Generated/MessageHelpers";

export type ProcessingStoppedState = {
    warningVisible: boolean;
};

const initialState: ProcessingStoppedState = {
    warningVisible: false,
};

export const processingStoppedSlice = createSlice({
    name: "processingStopped",
    initialState,
    reducers: {
        warningClosed: (state) => {
            state.warningVisible = false;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(hostStoppedProcessingAudio, (state) => {
            state.warningVisible = true;
            return state;
        });
    },
    selectors: {
        getIsVisible: (state): boolean => state.warningVisible,
    },
});
