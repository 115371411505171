/* eslint-disable camelcase */
import {
    ACTION_PREFIX,
    EVENT_PREFIX,
} from "api/eventsServiceApi/events/constants";
import { sendEvent } from "api/eventsServiceApi/thunks";
import { getBpm } from "features/Preview/previewSelectors";
import { MusicalKey } from "Protocol/Generated/Messages";
import { State } from "state/types";
import { createHydraThunk } from "state/typeUtil";

/**
 * User changes session key
 */
type SendSessionKeyChangeArgs = {
    sessionKey: MusicalKey;
};
export const sendSessionKeyChangeEvent =
    createHydraThunk<SendSessionKeyChangeArgs>(
        `${ACTION_PREFIX}/sendSessionKeyChangeEvent`,
        ({ sessionKey }, { dispatch }) => {
            void dispatch(
                sendEvent({
                    title: `${EVENT_PREFIX}.SessionKey.Change`,
                    properties: {
                        session_key: sessionKey,
                    },
                }),
            );
        },
    );

/**
 * DAW bpm is changed
 */
export const sendSessionBPMChangeEvent = createHydraThunk<object>(
    `${ACTION_PREFIX}/sendSessionBPMChangeEvent`,
    (_, { dispatch, getState }) => {
        const state = getState() as State;
        const sessionBpm = getBpm(state.preview);

        void dispatch(
            sendEvent({
                title: `${EVENT_PREFIX}.SessionBpm.Change`,
                properties: {
                    session_bpm: sessionBpm,
                },
            }),
        );
    },
);
