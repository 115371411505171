import { useEffect, useMemo, useState } from "react";

const getLoadingStatesArray = (emoji: string) => [
    `${emoji}...`,
    `.${emoji}..`,
    `..${emoji}.`,
    `...${emoji}`,
    `..${emoji}.`,
    `.${emoji}..`,
];

const DEFAULT_INDEX = 0;
const TIMER_ANIMATION_INTERVAL_DURATION_IN_MS = 500;

export const useLoadingStatesAnimation = (emoji: string) => {
    const [index, setIndex] = useState(DEFAULT_INDEX);
    let timerId: number | undefined;

    const loadingStatesArray = useMemo(
        () => getLoadingStatesArray(emoji),
        [emoji],
    );

    useEffect(() => {
        timerId = window.setInterval(() => {
            setIndex((index + 1) % loadingStatesArray.length);
        }, TIMER_ANIMATION_INTERVAL_DURATION_IN_MS);
        return () => {
            clearInterval(timerId);
        };
    });

    return { value: loadingStatesArray[index] };
};
