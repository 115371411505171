import { combineReducers } from "@reduxjs/toolkit";

import { eventsServiceApi } from "api/eventsServiceApi/eventsServiceApi";
import { hydraServiceApi } from "api/hydraServiceApi/hydraServiceApi";
import { captureSlice } from "features/Capture/captureSlice";
import { downloadSlice } from "features/Download/downloadSlice";
import { gainSlice } from "features/Gain/gainSlice";
import { loginPageSlice } from "features/LoginPage/loginPageSlice";
import { mainWindowSlice } from "features/MainWindow/mainWindowSlice";
import { networkEnvSlice } from "features/NetworkEnv/NetworkEnvSlice";
import { persistedStateSlice } from "features/PersistedState/persistedStateSlice";
import { previewSlice } from "features/Preview/previewSlice";
import { processingStoppedSlice } from "features/ProcessingStoppedWarning/processingStoppedSlice";
import { searchSlice } from "features/SearchPage/searchPageSlice";

export const rootReducer = combineReducers({
    download: downloadSlice.reducer,
    gain: gainSlice.reducer,
    login: loginPageSlice.reducer,
    mainWindow: mainWindowSlice.reducer,
    preview: previewSlice.reducer,
    search: searchSlice.reducer,
    processingStopped: processingStoppedSlice.reducer,
    capture: captureSlice.reducer,
    networkEnv: networkEnvSlice.reducer,
    persistedState: persistedStateSlice.reducer,
    [hydraServiceApi.reducerPath]: hydraServiceApi.reducer,
    [eventsServiceApi.reducerPath]: eventsServiceApi.reducer,
});
