import { PreviewState } from "./PreviewState";
import { MusicalRootNote } from "Protocol/Generated/Messages";

export const getKeyLock = (state: PreviewState) => state.keyLock;
export const getMusicalKey = (state: PreviewState) => state.musicalKey;
export const getIsOriginalKey = (state: PreviewState) =>
    getMusicalKey(state).root === MusicalRootNote.None;

export const getPreviousMusicalKey = (state: PreviewState) =>
    state.previousMusicalKey;

export const getBpm = (state: PreviewState) => state.bpm;
