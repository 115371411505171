/* eslint-disable max-lines */
import {
    addBezierCurveToContext,
    createCanvas,
    DEVICE_PIXEL_RATIO,
    gainToScaledControlPointOffset,
    VISUALIZER_COLORS,
} from "features/Capture/Visualizer/util";
import { cyan } from "shared/constants/color";
import { RGB } from "shared/types/RGB";
import { rgbToRgbaString } from "shared/utils/colorUtil";

const VISUALIZER_MAX_RADIUS = 300;
const VISUALIZER_MIN_RADIUS = 250;
const TOTAL_PARTICLES = 5;
const PARTICLE_PHASE_INCREMENT_PER_SECOND = 0.6;
const RADIUS_SCALING_VALUE = 0.1;
const PI2 = Math.PI * 2;
const PARTICLE_DIRECTION_MULTIPLIER = 4;
const MILLISECONDS_PER_SECOND = 1000;
const PROGRESS_BAR_HORIZONTAL_PADDING = 38;
const PROGRESS_BAR_HEIGHT = 6;
const PROGRESS_SEGMENT_SPACING = 5;
const PROGRESS_BAR_BOTTOM_PADDING = 21;
const TWENTY_FIVE_PERCENT = 0.25;
const FIFTY_PERCENT = 0.5;
const SEVENTY_FIVE_PERCENT = 0.75;
const PROGRESS_MILESTONES = [
    0,
    TWENTY_FIVE_PERCENT,
    FIFTY_PERCENT,
    SEVENTY_FIVE_PERCENT,
];
const PROGRESS_SEGMENT_BORDER_RADIUS = 8;

const getVisualizerMountPoint = () => {
    const mountPoint = document.getElementById("visualizer-mount-point");
    if (mountPoint === null) {
        throw new Error("Mount point is null");
    }

    return mountPoint;
};

type Particle = {
    x: number;
    y: number;
    radius: number;
    rgb: RGB;
    vx: number;
    vy: number;
    phaseValue: number;
};

export class Visualizer {
    protected totalParticles = TOTAL_PARTICLES;
    protected particles: Particle[] = [];
    protected maxRadius = VISUALIZER_MAX_RADIUS;
    protected minRadius = VISUALIZER_MIN_RADIUS;
    protected stageWidth: number;
    protected stageHeight: number;
    protected mainContext: CanvasRenderingContext2D;
    protected gradientContext: CanvasRenderingContext2D;
    protected bezierContext: CanvasRenderingContext2D;
    protected rmsValue: number = 0;
    protected animationFrameId: number | null = null;
    protected canvas: HTMLCanvasElement;
    protected isAnimating: boolean;
    protected prevFrameMS: number;
    protected captureProgress: number;
    protected progressSegmentWidth: number;
    protected progressBarFullWidth: number;
    protected progressBarY: number;
    protected bezierLineWidth: number;

    /**
     * When scaling the canvas, the underlying coordinate system remains
     * unscaled. These "coordinate" values are derived from a width and height
     * that are unaffected by the DEVICE_PIXEL_RATIO.
     */
    protected coordinateWidth: number;
    protected halfCoordinateWidth: number;
    protected threeQuartersCoordinateWidth: number;
    protected quarterCoordinateWidth: number;
    protected coordinateHeight: number;
    protected halfCoordinateHeight: number;

    constructor(width: number, height: number) {
        /* eslint-disable no-magic-numbers */
        this.stageWidth = width;
        this.progressBarFullWidth =
            this.stageWidth -
            PROGRESS_BAR_HORIZONTAL_PADDING * 2 -
            PROGRESS_SEGMENT_SPACING * 3;
        this.progressSegmentWidth = this.progressBarFullWidth / 4;
        this.coordinateWidth = this.stageWidth / DEVICE_PIXEL_RATIO;
        this.halfCoordinateWidth = this.coordinateWidth / 2;
        this.quarterCoordinateWidth = this.coordinateWidth / 4;
        this.threeQuartersCoordinateWidth = this.coordinateWidth * 0.75;
        this.stageHeight = height;
        this.coordinateHeight = this.stageHeight / DEVICE_PIXEL_RATIO;
        this.halfCoordinateHeight = this.coordinateHeight / 2;
        this.rmsValue = 0;
        this.isAnimating = false;
        this.prevFrameMS = 0;
        this.captureProgress = 0;
        this.progressBarY =
            this.stageHeight -
            PROGRESS_BAR_HEIGHT -
            PROGRESS_BAR_BOTTOM_PADDING;
        this.bezierLineWidth = 5 / DEVICE_PIXEL_RATIO;
        /* eslint-enable no-magic-numbers */

        const { canvas, context: mainContext } = createCanvas(width, height);
        const { context: bezierContext } = createCanvas(width, height);
        const { context: gradientContext } = createCanvas(width, height);

        this.canvas = canvas;
        this.mainContext = mainContext;
        this.gradientContext = gradientContext;
        this.bezierContext = bezierContext;

        let curColor = 0;

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.totalParticles; i++) {
            const item: Particle = {
                x: Math.random() * this.stageWidth,
                y: Math.random() * this.stageHeight,
                radius:
                    Math.random() * (this.maxRadius - this.minRadius) +
                    this.minRadius,
                rgb: VISUALIZER_COLORS[i % VISUALIZER_COLORS.length],
                vx: Math.random() * PARTICLE_DIRECTION_MULTIPLIER,
                vy: Math.random() * PARTICLE_DIRECTION_MULTIPLIER,
                phaseValue: Math.random(),
            };

            if (curColor + 1 >= VISUALIZER_COLORS.length) {
                curColor = 0;
            } else {
                curColor += 1;
            }

            this.particles[i] = item;
        }
    }

    updateRmsValue = (rmsValue: number) => {
        this.rmsValue = rmsValue;
    };

    updateCaptureProgress = (captureProgress: number) => {
        this.captureProgress = captureProgress;
    };

    drawBezierCurves = () => {
        const controlPointOffset = gainToScaledControlPointOffset(
            this.rmsValue,
        );
        const halfHeightPlusControlPointOffset =
            this.halfCoordinateHeight + controlPointOffset;
        const halfHeightMinusControlPointOffset =
            this.halfCoordinateHeight - controlPointOffset;

        // Draw the Bézier curve on the temporary canvas with transparency
        this.bezierContext.clearRect(0, 0, this.stageWidth, this.stageHeight);
        this.bezierContext.fillStyle = "rgba(0, 0, 0, 0.1)";
        this.bezierContext.fillRect(0, 0, this.stageWidth, this.stageHeight);
        this.bezierContext.lineWidth = this.bezierLineWidth;

        // Draw the Bézier curve
        this.bezierContext.beginPath();

        const startPoint = { x: 0, y: this.halfCoordinateHeight };
        const controlPoints = [
            {
                x: this.quarterCoordinateWidth,
                y: halfHeightMinusControlPointOffset,
            },
            {
                x: this.quarterCoordinateWidth,
                y: halfHeightPlusControlPointOffset,
            },
            { x: this.halfCoordinateWidth, y: this.halfCoordinateHeight },
            {
                x: this.threeQuartersCoordinateWidth,
                y: halfHeightPlusControlPointOffset,
            },
            {
                x: this.threeQuartersCoordinateWidth,
                y: halfHeightMinusControlPointOffset,
            },
            { x: this.coordinateWidth, y: this.halfCoordinateHeight },
        ];
        addBezierCurveToContext(this.bezierContext, startPoint, controlPoints);

        const mirroredControlPoints = [
            {
                x: this.quarterCoordinateWidth,
                y: halfHeightPlusControlPointOffset,
            },
            {
                x: this.quarterCoordinateWidth,
                y: halfHeightMinusControlPointOffset,
            },
            { x: this.halfCoordinateWidth, y: this.halfCoordinateHeight },
            {
                x: this.threeQuartersCoordinateWidth,
                y: halfHeightMinusControlPointOffset,
            },
            {
                x: this.threeQuartersCoordinateWidth,
                y: halfHeightPlusControlPointOffset,
            },
            { x: this.coordinateWidth, y: this.halfCoordinateHeight },
        ];
        addBezierCurveToContext(
            this.bezierContext,
            startPoint,
            mirroredControlPoints,
        );

        this.bezierContext.strokeStyle = "black";
        this.bezierContext.stroke();
        this.bezierContext.fillStyle = "black";
        this.bezierContext.fill();

        // Draw the Bézier curve mask on the main canvas
        this.mainContext.clearRect(0, 0, this.stageWidth, this.stageHeight);
        this.mainContext.drawImage(this.bezierContext.canvas, 0, 0);
    };

    /**
     * Takes in a Particle and elapsed time in MS. Updates the Particle's
     * attributes, normalizing for differences in user frame rate.
     */
    updateParticle = (particle: Particle, deltaTimeMS: number) => {
        const deltaNorm = deltaTimeMS / MILLISECONDS_PER_SECOND;
        const phaseIncrement = deltaNorm * PARTICLE_PHASE_INCREMENT_PER_SECOND;
        particle.phaseValue += phaseIncrement;
        particle.radius += Math.sin(particle.phaseValue);

        particle.x += particle.vx;
        particle.y += particle.vy;

        if (particle.x < 0) {
            particle.vx *= -1;
            particle.x += 10;
        } else if (particle.x > this.stageWidth) {
            particle.vx *= -1;
            particle.x -= 10;
        }

        if (particle.y < 0) {
            particle.vy *= -1;
            particle.y += 10;
        } else if (particle.y > this.stageHeight) {
            particle.vy *= -1;
            particle.y -= 10;
        }
    };

    drawParticles = (deltaTimeMS: number) => {
        this.gradientContext.clearRect(0, 0, this.stageWidth, this.stageHeight);
        for (const particle of this.particles) {
            this.updateParticle(particle, deltaTimeMS);
            const g = this.gradientContext.createRadialGradient(
                particle.x,
                particle.y,
                particle.radius * RADIUS_SCALING_VALUE,
                particle.x,
                particle.y,
                particle.radius,
            );
            g.addColorStop(0, rgbToRgbaString(particle.rgb, 1));
            g.addColorStop(1, rgbToRgbaString(particle.rgb, 0));

            this.gradientContext.beginPath();
            this.gradientContext.fillStyle = g;
            this.gradientContext.arc(
                particle.x,
                particle.y,
                particle.radius,
                0,
                PI2,
                false,
            );
            this.gradientContext.fill();
        }
    };

    drawProgressSegment = (index: number) => {
        const segmentSpacing = index * PROGRESS_SEGMENT_SPACING;
        const segmentMaximumProgressValue = (index + 1) * TWENTY_FIVE_PERCENT;
        const width =
            this.captureProgress < segmentMaximumProgressValue
                ? this.progressBarFullWidth *
                  (this.captureProgress % TWENTY_FIVE_PERCENT)
                : this.progressSegmentWidth;

        const left =
            PROGRESS_BAR_HORIZONTAL_PADDING +
            segmentSpacing +
            this.progressSegmentWidth * index;

        this.mainContext.roundRect(
            left,
            this.progressBarY,
            width,
            PROGRESS_BAR_HEIGHT,
            PROGRESS_SEGMENT_BORDER_RADIUS,
        );
    };

    drawProgress = () => {
        this.mainContext.beginPath();

        PROGRESS_MILESTONES.forEach((progressMilestone, index) => {
            if (this.captureProgress > progressMilestone) {
                this.drawProgressSegment(index);
            }
        });

        this.mainContext.fillStyle = cyan;
        this.mainContext.fill();
    };

    animate = (deltaTimeMS: number) => {
        this.mainContext.clearRect(0, 0, this.stageWidth, this.stageHeight);

        this.drawBezierCurves();

        this.drawParticles(deltaTimeMS);

        // Set the composite operation to 'source-in' to mask the gradient
        this.mainContext.globalCompositeOperation = "source-in";
        this.mainContext.drawImage(this.gradientContext.canvas, 0, 0);
        this.mainContext.globalCompositeOperation = "source-over";

        this.drawProgress();
    };

    protected tick() {
        if (!this.isAnimating) {
            return;
        }

        const nowMS = performance.now();
        const deltaTimeMS = nowMS - this.prevFrameMS;

        window.requestAnimationFrame(() => {
            this.animate(deltaTimeMS);
            this.tick();
        });
    }

    public start() {
        if (this.isAnimating) {
            return;
        }

        const mountPoint = getVisualizerMountPoint();
        mountPoint.appendChild(this.canvas);

        this.prevFrameMS = performance.now();
        this.isAnimating = true;
        this.tick();
    }

    public stop() {
        if (!this.isAnimating) {
            return;
        }

        this.isAnimating = false;
        this.captureProgress = 0;

        const mountPoint = getVisualizerMountPoint();
        mountPoint.removeChild(this.canvas);
    }
}
