import React from "react";
import { createUseStyles } from "react-jss";

import GainSlider from "features/Gain/GainSlider";
import PreviewControls from "features/Preview/PreviewBar/PreviewControls";
import { APP_MARGIN_PX } from "shared/constants/layout";

const useStyles = createUseStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        marginLeft: APP_MARGIN_PX,
        marginRight: APP_MARGIN_PX,
    },
    playPauseContainer: {
        display: "flex",
        width: "19px",
        height: "19px",
    },
});

const PreviewBar = () => {
    const styles = useStyles();

    return (
        <div className={styles.container}>
            <PreviewControls />
            <GainSlider />
        </div>
    );
};

export default PreviewBar;
