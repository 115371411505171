/* eslint-disable camelcase */
import {
    ACTION_PREFIX,
    EVENT_PREFIX,
} from "api/eventsServiceApi/events/constants";
import { sendEvent } from "api/eventsServiceApi/thunks";
import { getIsOriginalKey } from "features/Preview/previewSelectors";
import {
    getSearchId,
    getSearchResultsById,
} from "features/SearchPage/searchPageSelectors";
import { ExternalFileDragComplete } from "Protocol/Generated/Messages";
import { State } from "state/types";
import { createHydraThunk } from "state/typeUtil";

type SharedLoopEventArgs = {
    loopName: string;
    loopUuid: string;
    rowNumber?: number;
};
const createLoopEvent = (actionName: string, eventTitle: string) =>
    createHydraThunk<SharedLoopEventArgs>(
        `${ACTION_PREFIX}/${actionName}`,
        (args, { getState, dispatch }) => {
            const { loopName, loopUuid, rowNumber } = args;

            const state = getState() as State;
            const searchId = getSearchId(state.search);
            if (searchId === null) {
                // @todo Log error
            }

            const originalKey = getIsOriginalKey(state.preview);

            // This will always be false until we implement "original bpm" ui item
            const originalBpm = false;

            void dispatch(
                sendEvent({
                    title: eventTitle,
                    properties: {
                        loop_name: loopName,
                        loop_uuid: loopUuid,
                        original_key: originalKey,
                        original_bpm: originalBpm,
                        search_id: searchId,
                        row_number: rowNumber,
                    },
                }),
            );
        },
    );

/**
 * User clicks preview on a sample
 *
 */
export const sendPreviewEvent = createLoopEvent(
    "sendPreviewEvent",
    `${EVENT_PREFIX}.Sample.Preview`,
);

/**
 * User downloads a sample using the download button
 */
export const sendDownloadEvent = createLoopEvent(
    "sendDownloadEvent",
    `${EVENT_PREFIX}.Sample.Download.Click`,
);

/**
 * User downloads a sample by click and drag
 */
export const sendDownloadDragEvent = createLoopEvent(
    "sendDownloadDragEvent",
    `${EVENT_PREFIX}.Sample.Download.Drag`,
);

/**
 * User drags a sample out of Co Producer
 */
export const sendSampleDragEvent = createHydraThunk<ExternalFileDragComplete>(
    `${ACTION_PREFIX}/sendSampleDragEvent`,
    ({ id }, { dispatch, getState }) => {
        const state = getState() as State;
        const loopsById = getSearchResultsById(state.search);
        const loop = loopsById[id];
        const rowNumber = Object.keys(loopsById).indexOf(id);

        const searchId = getSearchId(state.search);
        if (searchId === null) {
            // @todo Log error
        }

        const originalKey = getIsOriginalKey(state.preview);

        // This will always be false until we implement "original bpm" ui item
        const originalBpm = false;

        void dispatch(
            sendEvent({
                title: `${EVENT_PREFIX}.Sample.Drag`,
                properties: {
                    loop_name: loop.name,
                    loop_uuid: id,
                    original_key: originalKey,
                    original_bpm: originalBpm,
                    search_id: searchId,
                    row_number: rowNumber,
                },
            }),
        );
    },
);
