import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { searchCharacterLimit } from "features/SearchPage/constants";
import { useSearchInputChangeHandler } from "features/SearchPage/hooks";
import { searchSlice } from "features/SearchPage/searchPageSlice";
import { white } from "shared/constants/color";
import { fontSizeSearchArea } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    container: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        height: "148px",
    },
    searchArea: {
        ...fontSizeSearchArea,
        height: "100%",
        width: "100%",
        backgroundColor: "transparent",
        border: "none",
        boxSizing: "border-box",
        color: white,
        resize: "none",
        padding: 0,
        "&:focus": {
            outline: "transparent",
        },
    },
});

const preventInteraction = (e: React.DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
};

const SearchBar = () => {
    const styles = useStyles();
    const handleTextInputChange = useSearchInputChangeHandler();
    const searchQuery = useSelector(searchSlice.selectors.getSearchQuery);

    return (
        <div className={styles.container}>
            <textarea
                onDrop={preventInteraction}
                onDragOver={preventInteraction}
                className={styles.searchArea}
                defaultValue={searchQuery}
                onChange={handleTextInputChange}
                placeholder="Describe the type of sample you want"
                maxLength={searchCharacterLimit}
            />
        </div>
    );
};

export default SearchBar;
