/* eslint-disable camelcase */
import {
    ACTION_PREFIX,
    EVENT_PREFIX,
} from "api/eventsServiceApi/events/constants";
import { sendEvent } from "api/eventsServiceApi/thunks";
import { createHydraThunk } from "state/typeUtil";

type SendGUIOpenEventArgs = {
    firstTime: boolean;
};
/**
 * User loads an instance of Co Producer
 */

export const sendGUIOpenEvent = createHydraThunk<SendGUIOpenEventArgs>(
    `${ACTION_PREFIX}/sendGUIOpenEvent`,
    ({ firstTime }, { dispatch }) => {
        void dispatch(
            sendEvent({
                title: `${EVENT_PREFIX}.GUI.Open`,
                properties: {
                    first_time: firstTime,
                },
            }),
        );
    },
);

type SendLoginEventArgs = {
    wrapperType: string;
    authToken: string;
    wpUserId: string;
    email: string;
};
/**
 * User successfully logs into Co Producer
 */
export const sendLoginEvent = createHydraThunk<SendLoginEventArgs>(
    `${ACTION_PREFIX}/sendLoginEvent`,
    (args, { dispatch }) => {
        void dispatch(
            sendEvent({
                title: `${EVENT_PREFIX}.Login`,
                properties: {
                    wrapper_type: args.wrapperType,
                },
                auth_token: args.authToken,
                user_id: args.wpUserId,
                email: args.email,
            }),
        );
    },
);
