import React from "react";
import { createUseStyles } from "react-jss";

import Popup from "../Popup/Popup";
import { useMenuExpansion } from "features/Preview/MusicalKeySelector/hooks";
import { cyan, gray15, gray30, gray50, white } from "shared/constants/color";
import ArrowDropwdownDown from "shared/icons/ArrowDropdownDown.svg";
import { fontSize1 } from "shared/styles/typography.styles";

const derivePointerEventsValue = (isDisabled?: boolean) => {
    if (isDisabled === undefined) {
        return "all";
    }
    return isDisabled ? "all" : "none";
};

type StyleProps = {
    isDisabled?: boolean;
    isExpanded: boolean;
    alignment: "left" | "right";
    width?: string;
    secondaryLabelColor: string;
};

const useStyles = createUseStyles({
    container: ({ isDisabled, isExpanded, width }: StyleProps) => ({
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        padding: "8px 10px",
        borderRadius: "8px",
        backgroundColor: gray15,
        boxSizing: "border-box",
        pointerEvents: derivePointerEventsValue(isDisabled),
        border: `1px solid ${isExpanded ? gray30 : "transparent"}`,
        width,
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
    }),
    titleDisplay: {
        ...fontSize1,
        color: white,
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "column",
        gap: "8px",
    },
    label: {
        ...fontSize1,
        color: gray50,
    },
    secondaryLabel: ({ secondaryLabelColor }: StyleProps) => ({
        ...fontSize1,
        color: secondaryLabelColor,
    }),
});
type DropdownProps = {
    readonly children: React.ReactNode;
    readonly title: string;
    readonly label?: string;
    readonly secondaryLabel?: string;
    readonly secondaryLabelColor?: string;
    readonly isDisabled?: boolean;
    readonly alignment?: "left" | "right";
    readonly width?: string;
    readonly forceClose?: boolean;
    readonly onOpen?: () => void;
    readonly onClose?: () => void;
};

const Dropdown = ({
    isDisabled,
    alignment = "right",
    children,
    title,
    width,
    label,
    secondaryLabel,
    secondaryLabelColor = cyan,
    forceClose = false,
    onOpen,
    onClose,
}: DropdownProps) => {
    const { isExpanded, openMenu, closeMenu } = useMenuExpansion(forceClose);

    const handleClose = () => {
        closeMenu();
        if (onClose !== undefined) {
            onClose();
        }
    };

    const handleOpen = (e: React.MouseEvent) => {
        openMenu(e);
        if (onOpen !== undefined) {
            onOpen();
        }
    };

    const styles = useStyles({
        isDisabled,
        isExpanded,
        alignment,
        width,
        secondaryLabelColor,
    });
    return (
        <div
            className={styles.container}
            onClick={isExpanded ? handleClose : handleOpen}
            role="button"
        >
            <div className={styles.titleContainer}>
                <div className={styles.label}>{label}</div>
                <div className={styles.titleDisplay}>{title}</div>
                <div className={styles.secondaryLabel}>{secondaryLabel}</div>
            </div>
            <ArrowDropwdownDown />
            {isExpanded ? (
                <Popup alignment={alignment}>{children}</Popup>
            ) : null}
        </div>
    );
};

export default Dropdown;
