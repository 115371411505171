import { createSelector } from "@reduxjs/toolkit";

import { searchCharacterLimit } from "./constants";
import { SearchState } from "./SearchState";
import {
    getHasConfirmedCapture,
    getIsCaptureIdle,
} from "features/Capture/captureSelectors";
import { getIdFromArgs } from "shared/Selectors";
import { State } from "state/types";

export const getSearchQuery = (state: SearchState) => state.searchQuery;

export const getIsOSDragging = (state: SearchState) => state.isOSDragging;
export const getIsDraggingOutsideWindow = (state: SearchState) =>
    state.isDraggingOutsideWindow;
export const getCurrentlyDraggedLoop = (state: SearchState) =>
    state.loopBeingDragged;

export const getSearchResultsById = (state: SearchState) =>
    state.searchResultById;

export const getSearchResultIndex = (
    state: SearchState,
    id: string,
): number => {
    const loopIds = Object.keys(state.searchResultById);
    return loopIds.indexOf(id);
};

export const getIsSearchLoading = (state: SearchState) => state.isLoading;
export const getSearchId = (state: SearchState) => state.searchId;
export const getSearchLength = (state: SearchState) => state.searchQuery.length;
export const getHasSearchReachedCharacterLimit = (state: SearchState) =>
    getSearchLength(state) >= searchCharacterLimit;

export const makeGetAdjacentLoops = () =>
    createSelector(
        [getSearchResultIndex, getSearchResultsById],
        (activeLoopIndex, searchResultsById) => {
            const loopIds = Object.keys(searchResultsById);
            return {
                previousLoop: searchResultsById[loopIds[activeLoopIndex - 1]],
                nextLoop: searchResultsById[loopIds[activeLoopIndex + 1]],
            };
        },
    );
export const makeGetLoopFromSearchResults = () =>
    createSelector(
        [getSearchResultsById, getIdFromArgs],
        (searchResultsById, id) => searchResultsById[id],
    );

export const getCanExecuteSearch = (state: State) =>
    Boolean(getSearchQuery(state.search)) &&
    (getHasConfirmedCapture(state.capture) || getIsCaptureIdle(state.capture));

export const getThumbsDownClicked = (state: SearchState) =>
    state.thumbsDownClicked;
export const getThumbsUpClicked = (state: SearchState) => state.thumbsUpClicked;
