import React from "react";

import { useWaveformCanvasRef } from "./hooks";

const WAVEFORM_WIDTH = 72;
const WAVEFORM_HEIGHT = 24;

type WaveformProps = {
    readonly data: Array<number>;
    readonly width?: number;
    readonly height?: number;
};

const Waveform = ({
    data,
    width = WAVEFORM_WIDTH,
    height = WAVEFORM_HEIGHT,
}: WaveformProps) => {
    const { ref } = useWaveformCanvasRef(width, height, data);
    return <canvas ref={ref} />;
};

export default Waveform;
