import React from "react";
import { createUseStyles } from "react-jss";

import { gray15, gray25 } from "shared/constants/color";

const useStyles = createUseStyles({
    tooltipBg: {
        position: "absolute",
        zIndex: 1,
    },
});

type TooltipBgProps = {
    readonly width: number;
    readonly height: number;
};

const TooltipBg = ({ width, height }: TooltipBgProps) => {
    const styles = useStyles();
    return (
        <svg
            className={styles.tooltipBg}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M131.828 114C131.298 114 130.789 114.211 130.414 114.586L126.414 118.586C125.633 119.367 124.367 119.367 123.586 118.586L119.586 114.586C119.211 114.211 118.702 114 118.172 114L7 114C3.68629 114 1 111.314 1 108V7.0002C1 3.68649 3.68629 1.0002 6.99999 1.0002H243C246.314 1.0002 249 3.68649 249 7.0002V108C249 111.314 246.314 114 243 114L131.828 114Z"
                fill={gray15}
            />
            <path
                d="M7 114V114.5V114ZM243 114V114.5V114ZM119.586 114.586L119.232 114.94L119.586 114.586ZM130.414 114.586L130.768 114.94L130.414 114.586ZM131.828 114V114.5V114ZM123.586 118.586L123.232 118.94L123.586 118.586ZM126.061 118.232L130.061 114.232L130.768 114.94L126.768 118.94L126.061 118.232ZM119.939 114.232L123.939 118.232L123.232 118.94L119.232 114.94L119.939 114.232ZM7 113.5L118.172 113.5V114.5L7 114.5V113.5ZM1.5 108C1.5 111.038 3.96243 113.5 7 113.5V114.5C3.41015 114.5 0.5 111.59 0.5 108H1.5ZM1.5 7.0002V108H0.5V7.0002H1.5ZM6.99999 1.5002C3.96243 1.5002 1.5 3.96263 1.5 7.0002H0.5C0.5 3.41035 3.41014 0.500198 6.99999 0.500198V1.5002ZM243 1.5002H6.99999V0.500198H243V1.5002ZM248.5 7.0002C248.5 3.96264 246.038 1.5002 243 1.5002V0.500198C246.59 0.500198 249.5 3.41035 249.5 7.0002H248.5ZM248.5 108V7.0002H249.5V108H248.5ZM243 113.5C246.038 113.5 248.5 111.038 248.5 108H249.5C249.5 111.59 246.59 114.5 243 114.5V113.5ZM131.828 113.5L243 113.5V114.5L131.828 114.5V113.5ZM119.232 114.94C118.951 114.658 118.569 114.5 118.172 114.5V113.5C118.835 113.5 119.47 113.764 119.939 114.232L119.232 114.94ZM130.061 114.232C130.529 113.764 131.165 113.5 131.828 113.5V114.5C131.431 114.5 131.049 114.658 130.768 114.94L130.061 114.232ZM126.768 118.94C125.791 119.916 124.209 119.916 123.232 118.94L123.939 118.232C124.525 118.818 125.475 118.818 126.061 118.232L126.768 118.94Z"
                fill={gray25}
            />
        </svg>
    );
};

export default TooltipBg;
