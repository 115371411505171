import React from "react";
import { createUseStyles } from "react-jss";

import Button from "shared/components/Buttons/Button";
import { cyan, gray100, whiteTint65 } from "shared/constants/color";
import { fontSize3, labelStyle } from "shared/styles/typography.styles";

const useStyles = createUseStyles({
    content: {
        position: "relative",
        zIndex: 2,
        display: "flex",
        padding: "12px",
        alignItems: "center",
        flexDirection: "column",
        gap: "7px",
    },
    title: {
        ...fontSize3,
        position: "relative",
        lineHeight: "20px",
        color: cyan,
    },
    paragraph: {
        ...labelStyle,
        position: "relative",
        color: whiteTint65,
        textAlign: "center",
    },
    bottomButtonsContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "stretch",
        marginTop: "9px",
    },
    undoButton: {
        ...labelStyle,
        color: gray100,
    },
    gotItButton: {
        ...labelStyle,
        color: cyan,
    },
});

export type TooltipInnerProps = {
    readonly title: string;
    readonly body: string;
    readonly backButtonLabel: string;
    readonly confirmButtonLabel: string;
    readonly onBackClick: () => void;
    readonly onConfirmClick: () => void;
};

const TooltipInner = ({
    title,
    body,
    backButtonLabel,
    confirmButtonLabel,
    onBackClick,
    onConfirmClick,
}: TooltipInnerProps) => {
    const styles = useStyles();
    return (
        <div className={styles.content}>
            <p className={styles.title}>{title}</p>
            <p className={styles.paragraph}>{body}</p>
            <div className={styles.bottomButtonsContainer}>
                <Button onClick={onBackClick}>
                    <p className={styles.undoButton}>{backButtonLabel}</p>
                </Button>
                <Button onClick={onConfirmClick}>
                    <p className={styles.gotItButton}>{confirmButtonLabel}</p>
                </Button>
            </div>
        </div>
    );
};

export default TooltipInner;
