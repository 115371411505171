import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

import {
    getAuthToken,
    getUniqueMachineId,
} from "features/LoginPage/loginPageSliceSelectors";
import { LoginPageState } from "features/LoginPage/types";
import { NetworkEnvState } from "features/NetworkEnv/NetworkEnvSlice";
import { Environment, MusicalKey } from "Protocol/Generated/Messages";
import {
    APIMusicalKeyTonic,
    APIMusicalKeyScale,
    APILoop,
    apiMusicalKeyTonicByMusicalRootNote,
    apiMusicalKeyScaleByMusicalScale,
} from "shared/types/Loop";

/**
 * Query Types
 */
export type LoopsResponse = { loops: Array<APILoop> };

export type GetLoopsArgs = {
    searchQuery: string;
    tonic: APIMusicalKeyTonic;
    scale: APIMusicalKeyScale;
    bpm: number;
};

export type GetLoopsWithAudioReferenceArgs = GetLoopsArgs & {
    audioRefTonic: APIMusicalKeyTonic;
    audioRefScale: APIMusicalKeyScale;
    audioId: string;
};

export const getAPIScaleAndTonicFromMusicalKey = (
    musicalKey: MusicalKey | null,
): { scale: APIMusicalKeyScale; tonic: APIMusicalKeyTonic } => {
    if (!musicalKey) {
        return { scale: "", tonic: "" };
    }
    const tonic = apiMusicalKeyTonicByMusicalRootNote[musicalKey.root];
    const scale = apiMusicalKeyScaleByMusicalScale[musicalKey.scale];

    return { scale, tonic };
};

export const getBaseUrl = (state: { networkEnv: NetworkEnvState }) => {
    const { environment } = state.networkEnv;
    switch (environment) {
        case Environment.Staging:
            return "https://hydra-service-staging.output.com";
        case Environment.Beta:
            return "https://hydra-service-dev.output.com";
        default:
            return "https://hydra-service.output.com";
    }
};

const getBaseQuery: BaseQueryFn<string | FetchArgs> = (
    args,
    api,
    extraOptions,
) => {
    /**
     * @todo Ben Leichter
     * replace typecast w actual type
     */
    const state = api.getState() as {
        login: LoginPageState;
        networkEnv: NetworkEnvState;
    };

    const baseUrl = getBaseUrl(state);

    const authToken = getAuthToken(state.login);
    const uniqueMachineId = getUniqueMachineId(state.login);

    if (uniqueMachineId === null) {
        throw new Error("No machine id present");
    }

    // Ensures that we don't attempt to send events before our auth token is present
    if (authToken === null) {
        return {
            error: {
                status: "CUSTOM_ERROR",
                data: "No auth token present",
            },
        };
    }

    const baseQueryFunc = fetchBaseQuery({
        baseUrl: `${baseUrl}/api/v1/`,
        prepareHeaders: (headers) => {
            headers.set("authorization", `Bearer ${authToken}`);
            headers.set("output-product-name", "hydra");
            headers.set("unique-machine-id", uniqueMachineId);
            return headers;
        },
    });
    return baseQueryFunc(args, api, extraOptions);
};

export const hydraServiceApi = createApi({
    reducerPath: "hydraServiceApi",
    baseQuery: getBaseQuery,
    endpoints: (builder) => ({
        getLoops: builder.query<LoopsResponse, GetLoopsArgs>({
            query: (args: GetLoopsArgs) => ({
                url: "/loops",
                params: {
                    q: args.searchQuery,
                    first: 100,
                    bpm: args.bpm,
                    tonic: args.tonic,
                    scale: args.scale,
                },
            }),
            /**
             * Ben Leichter - June 11, 2024
             * We want to skip the cache because this endpoint
             * generates and returns presigned urls with expiration
             * timestamps for previewing audio. This ensures that
             * all results have a valid preview URL.
             */
            forceRefetch: () => true,
        }),
        getLoopsWithAudioReference: builder.query<
            LoopsResponse,
            GetLoopsWithAudioReferenceArgs
        >({
            query: (args: GetLoopsWithAudioReferenceArgs) => ({
                url: "/loops/audioref",
                params: {
                    q: args.searchQuery,
                    first: 100,
                    bpm: args.bpm,
                    tonic: args.tonic,
                    scale: args.scale,
                    audio_ref_tonic: args.audioRefTonic,
                    audio_ref_scale: args.audioRefScale,
                    audio_id: args.audioId,
                },
            }),
            forceRefetch: () => true,
        }),
    }),
});
