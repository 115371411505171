import React, { useCallback, useMemo } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { sendPreviewMessage } from "../thunks";
import { makeGetAdjacentLoops } from "features/SearchPage/searchPageSelectors";
import Button from "shared/components/Buttons/Button";
import SamplePreviewPlayPauseButton from "shared/components/PlayPauseButton/SamplePreviewPlayPauseButton";
import NextLoopIcon from "shared/icons/NextLoop.svg";
import PreviousLoopIcon from "shared/icons/PreviousLoop.svg";
import { Loop } from "shared/types/Loop";
import { useHydraDispatch } from "state/hooks";
import { State } from "state/types";

const useStyles = createUseStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "6px",
    },
});

type PlaybackControlsProps = {
    readonly activeLoop: Loop | undefined;
};

const PlaybackControls = ({ activeLoop }: PlaybackControlsProps) => {
    const styles = useStyles();
    const dispatch = useHydraDispatch();
    const getAdjacentLoops = useMemo(makeGetAdjacentLoops, []);
    const { previousLoop, nextLoop } = useSelector<
        State,
        {
            previousLoop?: Loop;
            nextLoop?: Loop;
        }
    >((state) =>
        getAdjacentLoops(state.search, activeLoop ? activeLoop.id : ""),
    );

    const handlePreviousClick = useCallback(() => {
        if (previousLoop) {
            void dispatch(sendPreviewMessage(previousLoop));
        }
    }, [dispatch, previousLoop]);

    const handleNextClick = useCallback(() => {
        if (nextLoop) {
            void dispatch(sendPreviewMessage(nextLoop));
        }
    }, [dispatch, nextLoop]);
    return (
        <div className={styles.container}>
            <Button onClick={handlePreviousClick}>
                <PreviousLoopIcon />
            </Button>
            <SamplePreviewPlayPauseButton activeLoop={activeLoop} />
            <Button onClick={handleNextClick}>
                <NextLoopIcon />
            </Button>
        </div>
    );
};

export default PlaybackControls;
