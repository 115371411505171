import React from "react";
import { createUseStyles } from "react-jss";

type DividerProps = {
    readonly color: string;
    readonly direction?: "horizontal" | "vertical";
    readonly thickness?: string;
};

const useStyles = createUseStyles({
    divider: ({ color, direction, thickness }: DividerProps) => ({
        height: direction === "horizontal" ? thickness : "100%",
        width: direction === "vertical" ? thickness : "100%",
        backgroundColor: color,
    }),
});

const Divider = ({ color, direction, thickness = "1px" }: DividerProps) => {
    const styles = useStyles({ color, direction, thickness });
    return <div className={styles.divider} />;
};

export default Divider;
