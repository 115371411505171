import { useEffect } from "react";

export const useDisableOverScroll = () => {
    useEffect(() => {
        document.documentElement.style.overscrollBehavior = "none";
        document.body.style.overscrollBehavior = "none";
        document.body.style.overflow = "hidden";

        return () => {
            document.documentElement.style.overscrollBehavior = "";
            document.body.style.overscrollBehavior = "";
            document.body.style.overflow = "";
        };
    }, []);
};
