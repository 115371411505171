import { JssStyle } from "jss";

import { gray65 } from "shared/constants/color";

export const fontSize1: JssStyle = {
    fontSize: "12px",
    fontWeight: 400,
};

export const fontSize2: JssStyle = {
    fontSize: "14px",
    fontWeight: 400,
};

export const fontSize3: JssStyle = {
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "24px",
};

export const fontSizeSearchArea: JssStyle = {
    fontSize: "22px",
    lineHeight: "28px",
    fontWeight: 600,
};

export const labelStyle: JssStyle = {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 600,
    color: gray65,
};
