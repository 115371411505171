import React from "react";
import { createUseStyles } from "react-jss";

import { pressDownloadButton } from "features/Download/thunks";
import Button from "shared/components/Buttons/Button";
import { whiteTint10 } from "shared/constants/color";
import { stopPropagation } from "shared/eventHandlers/sharedEventHandlers";
import DownloadIcon from "shared/icons/Download.svg";
import { Loop } from "shared/types/Loop";
import { useHydraDispatch } from "state/hooks";

type DownloadButtonProps = {
    readonly loop: Loop;
    readonly rowNumber: number;
};

const useStyles = createUseStyles({
    downloadButton: {
        "&:hover": {
            backgroundColor: whiteTint10,
            borderRadius: "8px",
        },
        padding: "6px",
    },
});

const DownloadButton = ({ loop, rowNumber }: DownloadButtonProps) => {
    const dispatch = useHydraDispatch();
    const onClick = () => {
        void dispatch(
            pressDownloadButton({
                loop,
                rowNumber,
            }),
        );
    };

    const styles = useStyles();
    return (
        <Button
            onClick={onClick}
            onMouseDown={stopPropagation}
            additionalClasses={styles.downloadButton}
        >
            <DownloadIcon />
        </Button>
    );
};

export default DownloadButton;
