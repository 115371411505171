import { sendDownloadEvent } from "api/eventsServiceApi/events/loopEvents";
import { getLoopWithValidUrl } from "api/hydraServiceApi/thunks";
import { initiateDownload } from "features/Download/downloadSlice";
import { Loop } from "shared/types/Loop";
import { createHydraThunk } from "state/typeUtil";

type PressDownloadButtonArgs = {
    loop: Loop;
    rowNumber: number;
};
export const pressDownloadButton = createHydraThunk<PressDownloadButtonArgs>(
    "download/pressDownloadButton",
    async ({ loop, rowNumber }, { dispatch }) => {
        const loopToDownload: Loop = await dispatch(
            getLoopWithValidUrl({ loop }),
        ).unwrap();

        const { id } = loopToDownload;

        void dispatch(
            sendDownloadEvent({
                loopName: loop.name,
                loopUuid: loop.id,
                rowNumber,
            }),
        );
        void dispatch(initiateDownload({ [id]: loopToDownload.olac_url }));
    },
);
