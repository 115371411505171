// In `gui/shared/components/Draggable.tsx`
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

type Position = {
    x: number;
    y: number;
};

type DraggableProps = {
    readonly children: React.ReactNode;
    readonly overlay?: React.ReactNode;
    readonly onDragStart?: () => void;
    readonly onDragEnd?: () => void;
    readonly cancelDrag?: boolean;
    readonly onMouseOut?: () => void;
};

const useStyles = createUseStyles({
    overlay: ({ x, y }: Position) => ({
        position: "absolute",
        pointerEvents: "none",
        left: x,
        top: y,
        zIndex: 10,
    }),
});

type EventListenerProps = {
    readonly handleMouseMove: (e: MouseEvent) => void;
    readonly handleMouseUp: () => void;
    readonly handleMouseLeave: () => void;
};

const addEventListeners = ({
    handleMouseMove,
    handleMouseUp,
    handleMouseLeave,
}: EventListenerProps) => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.body.addEventListener("mouseleave", handleMouseLeave);
};

const removeEventListeners = ({
    handleMouseMove,
    handleMouseUp,
    handleMouseLeave,
}: EventListenerProps) => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    document.body.removeEventListener("mouseleave", handleMouseLeave);
};

const Draggable = ({
    children,
    overlay,
    onDragStart,
    onDragEnd,
    cancelDrag,
    onMouseOut,
}: DraggableProps) => {
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
    const styles = useStyles(position);

    const setPositionToMouse = (e: React.MouseEvent | MouseEvent) => {
        setPosition({
            x: e.clientX,
            y: e.clientY,
        });
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setPositionToMouse(e);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (isDragging) {
            setPositionToMouse(e);
        }
    };

    const handleMouseLeave = () => {
        if (isDragging && onMouseOut) {
            onMouseOut();
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        const remListeners = () => {
            removeEventListeners({
                handleMouseMove,
                handleMouseUp,
                handleMouseLeave,
            });
        };

        if (isDragging) {
            addEventListeners({
                handleMouseMove,
                handleMouseUp,
                handleMouseLeave,
            });
            if (onDragStart) {
                onDragStart();
            }
        } else {
            remListeners();
            if (onDragEnd) {
                onDragEnd();
            }
        }

        return () => {
            remListeners();
        };
    }, [isDragging]);

    useEffect(() => {
        if (cancelDrag) {
            setIsDragging(false);
        }
    }, [cancelDrag]);

    return (
        <>
            <div
                className={isDragging && !overlay ? styles.overlay : ""}
                onMouseDown={handleMouseDown}
            >
                {children}
            </div>
            {isDragging && overlay ? (
                <div className={styles.overlay}>{overlay}</div>
            ) : null}
        </>
    );
};

export default Draggable;
