import React, { useCallback, useEffect, useState } from "react";

export const useMenuExpansion = (forceClose: boolean) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const openMenu = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        setIsExpanded(true);
    }, []);

    const closeMenu = useCallback(() => {
        setIsExpanded(false);
    }, []);

    /**
     * Handles closing menu w escape key
     */
    const handleKeyPress = useCallback((e: KeyboardEvent) => {
        if (e.key === "Escape") {
            closeMenu();
        }
    }, []);

    useEffect(() => {
        window.addEventListener("click", closeMenu);
        window.addEventListener("keypress", handleKeyPress);

        return () => {
            window.removeEventListener("click", closeMenu);
            window.removeEventListener("keypress", handleKeyPress);
        };
    });

    useEffect(() => {
        if (forceClose && isExpanded) {
            closeMenu();
        }
    }, [forceClose, isExpanded]);

    return { isExpanded, openMenu, closeMenu };
};
