import React from "react";
import { createUseStyles } from "react-jss";

import { sendAudioReferenceListenRedoEvent } from "api/eventsServiceApi/events/captureEvents";
import { captureSlice } from "features/Capture/captureSlice";
import { keyConfirmButtonPressed } from "features/Capture/thunks";
import Button from "shared/components/Buttons/Button";
import CTAButton from "shared/components/Buttons/CTAButton";
import { black, cyan } from "shared/constants/color";
import Redo from "shared/icons/Redo.svg";
import { labelStyle } from "shared/styles/typography.styles";
import { useHydraDispatch } from "state/hooks";

const useStyles = createUseStyles({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "stretch",
    },
    redoButton: {
        gap: "8px",
    },
    redoLabel: { ...labelStyle, color: cyan },
    doneButton: {
        borderRadius: "6px",
        padding: "8px 12px",
    },
    doneLabel: { ...labelStyle, color: black },
});

const CaptureConfirmation = () => {
    const styles = useStyles();
    const dispatch = useHydraDispatch();
    const onRedoClick = () => {
        void dispatch(sendAudioReferenceListenRedoEvent());
        dispatch(captureSlice.actions.clearCapture());
    };
    const onDoneClick = () => {
        void dispatch(keyConfirmButtonPressed());
    };

    return (
        <div className={styles.container}>
            <Button additionalClasses={styles.redoButton} onClick={onRedoClick}>
                <Redo />
                <p className={styles.redoLabel}>Redo</p>
            </Button>
            <CTAButton
                additionalClasses={styles.doneButton}
                onClick={onDoneClick}
            >
                <p className={styles.doneLabel}>Done</p>
            </CTAButton>
        </div>
    );
};

export default CaptureConfirmation;
